import { TradeModel } from "api/models/company/trade-model";
import notificationHelper from "helpers/notificationHelper";
import { I18N } from "aurelia-i18n";
import { autoinject, computedFrom } from "aurelia-framework";
import dateHelper from "helpers/dateHelper";
import queryStringHelper from "helpers/queryStringHelper";
import requisitionService from "services/requisitionService";
import settingRepository from "repositories/settingRepository";
import equipmentTypeService from "services/equipmentTypeService";
import { TradeService } from "services/trade-service";
import { SkillService } from "services/skill-service";
import _ from "underscore";
import routerHelper from "helpers/routerHelper";

@autoinject
export class DailyEntryLaborRequisitionEdit {

    public LABOR_TYPE: string = "Trade";
    public EQUIPMENT_TYPE: string = "EquipmentType";

    public dispatchDate: any;
    public dispatchProjectCode: any;
    public id: any;
    public isProjectResponsible: boolean = false;
    public type: string = this.LABOR_TYPE;

    public trade: any;
    public skill: any;
    public equipmentType: any;
    public quantity: number = 1;
    public requiredDate: any = dateHelper.addHour(dateHelper.getNow(), 24);
    public comment: string = "";
    public templateCode: string | null = "";
    public isEdit: boolean = false;

    @computedFrom("type", "isEdit")
    public get equipmentTypeClass(): string {
        let cls = this.type === this.EQUIPMENT_TYPE ? "active" : "";

        cls += this.isEdit ? " disabled" : "";

        return cls;
    }

    @computedFrom("type", "isEdit")
    public get laborTypeClass(): string {
        let cls = this.type === this.LABOR_TYPE ? "active" : "";

        cls += this.isEdit ? " disabled" : "";

        return cls;
    }

    constructor(private readonly i18n: I18N, private readonly skillService: SkillService, private readonly tradesService: TradeService) {

    }

    public async activate(params: any): Promise<any> {
        this.bindViewModel(params.dispatchProjectCode, params.dailyEntryDate, params.id, params.q);
        await this.loadData();
    }

    public bindViewModel(dispatchProjectCode: string, dispatchDate: Date, id: number, queryString: string): any {
        this.isProjectResponsible = queryStringHelper.parseIsProjectResponsible(queryString);

        this.id = id;
        this.dispatchDate = dispatchDate;
        this.dispatchProjectCode = dispatchProjectCode;

        this.isEdit = this.id !== null && this.id !== undefined;
    }

    public async loadData(): Promise<any> {
        if (this.isEdit) {
            this.loadRequisition(await requisitionService.getRequisition(this.dispatchProjectCode, this.id));
        }

        this.templateCode = settingRepository.getDispatchTemplate();
    }

    public loadRequisition(data: any): void {
        this.trade = null;
        this.equipmentType = null;
        this.type = data.ResourceType;

        if (data.ResourceType === this.LABOR_TYPE) {
            this.trade = { id: data.ResourceCode, text: data.ResourceCode + " - " + data.ResourceName };
            if (data.SkillCode) {
                this.skill = { id: data.SkillCode, text: data.SkillCode + " - " + data.SkillDescription };
            } else {
                this.skill = null;
            }
        } else if (data.ResourceType === this.EQUIPMENT_TYPE) {
            this.equipmentType = { id: data.ResourceCode, text: data.ResourceCode + " - " + data.ResourceName };
        }

        this.quantity = data.Quantity;
        this.requiredDate = data.RequestDate;
        this.comment = data.Comment;
    }

    public buildRequisitionDto(): any {
        const dto: any = {};

        if (this.isEdit) {
            dto.Id = this.id;
        }

        dto.ResourceType = this.type;
        if (this.type === this.LABOR_TYPE) {
            dto.ResourceCode = this.trade.id;
            if (this.skill) {
                dto.SkillCode = this.skill.id;
            }
        } else if (this.type === this.EQUIPMENT_TYPE) {
            dto.ResourceCode = this.equipmentType.id;
        }

        dto.Quantity = this.quantity;
        dto.RequestDate = this.requiredDate;
        dto.Comment = this.comment;
        dto.TemplateCode = this.templateCode;

        return dto;
    }

    public isValid(): boolean {
        if (!this.isTradeValid() || !this.isEquipmentTypeValid() || !this.isQuantityValid() || !this.isRequiredDateValid()) {
            return false;
        }
        return true;
    }

    public isTradeValid(): boolean {
        if (this.type === this.LABOR_TYPE && !this.trade) {
            notificationHelper.showError(this.i18n.tr("err_TradeRequired"));
            return false;
        }

        return true;
    }

    public isEquipmentTypeValid(): boolean {
        if (this.type === this.EQUIPMENT_TYPE && !this.equipmentType) {
            notificationHelper.showError(this.i18n.tr("err_EquipmentTypeRequired"));
            return false;
        }

        return true;
    }

    public isQuantityValid(): boolean {
        if (!this.quantity) {
            notificationHelper.showError(this.i18n.tr("err_QuantityRequired"));
            return false;
        }
        if (this.quantity < 1) {
            notificationHelper.showError(this.i18n.tr("err_MinQuantity"));
            return false;
        }

        return true;
    }

    public isRequiredDateValid(): boolean {
        if (!this.requiredDate) {
            notificationHelper.showError(this.i18n.tr("err_RequiredDateRequired"));
            return false;
        }

        return true;
    }

    public get getEquipmentTypes(): any {
        return {
            transport: (params: any, success: any): any => {
                equipmentTypeService.getEquipmentTypeForCompany(params.data.filter, params.data.page || 1)
                .then((data: any) => {
                    return success(data);
                });
            }
        };
    }

    public get getSkills(): any {
        return {
            transport: (params: any, success: any): any => {
                this.skillService.getSkills(params.data.filter, params.data.page || 1)
                .then((data: any) => {
                    return success(data);
                });
            }
        };
    }

    public get getTrades(): any {
        return {
            transport: (params: any, success: any): any => {
                this.tradesService.GetTrades(params.data.filter, params.data.page || 1)
                .then((data: TradeModel[]) => {
                    return success(data);
                });
            },
            mapResults: (item: TradeModel): any => {
                return { id: item.Code, text: item.Code + " - " + item.Description, data: item };
            }
        };
    }

    public show(tab: string): void {
        this.type = tab;
    }

    public async save(): Promise<any> {
        if (this.isValid()) {
            await requisitionService.saveRequisition(this.dispatchProjectCode, this.dispatchDate, this.buildRequisitionDto());
            routerHelper.navigateBack();
        }
    }

}
