import { autoinject } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import _ from "underscore";
import notificationHelper from "helpers/notificationHelper";
import dateHelper from "helpers/dateHelper";
import routerHelper from "helpers/routerHelper";
import dailyEntryService from "services/dailyEntryService";
import attendanceService from "services/attendanceService";
import evaluationService from "services/evaluationService";

@autoinject
export class DailyEntryEmployeeEvaluation {

    public dispatchProjectCode: string = "";
    public dispatchDate: Date = new Date();
    public loadedCriteriasValues: any;
    public employees: any[] = [];
    public criteria: any[] = [];
    public employeeId: number = 0;
    public data: any;
    public item: any;
    public emp: any;
    public validatedForm: any;
    public value: any;
    public isProjectResponsible: boolean = false;

    constructor(private readonly i18n: I18N)  {

    }

    public async loadData(): Promise<any> {
        const dfdEvalCriterias = evaluationService.getAllCriterias();
        const dfdEmpList = attendanceService.listForDateOrderByName(this.dispatchProjectCode, this.dispatchDate);
        const dfdCriteriasValues = evaluationService.getEmployeeEvaluation(this.dispatchDate);

        await Promise.all([dfdEvalCriterias, dfdEmpList, dfdCriteriasValues]).then(([criterias, employees, values]: any): any => {
            this.loadedCriteriasValues = values;
            employees = _.filter(employees, (emp: any) => {
                return (emp.Presence === 2 || emp.Presence === 3) && emp.EmployeeNumber > 0;
            });

            employees = _.map(employees, (emp: any) => {
                return this.mapEmployee(emp, criterias);
            });
            this.employees = employees;
        });
    }

    public mapEmployee(employee: any, criterias: any): any {
        const mappedEmployee =  {
            criterias: this.loadCriterias(criterias, employee.EmployeeNumber),
            employee: employee,
            isAllCriteriaHasValue: false
        };

        mappedEmployee.isAllCriteriaHasValue = this.checkAllCriteriaHasValue(mappedEmployee);

        return mappedEmployee;
    }

    public isCriteriasValid(): boolean {

        let criteriasValid = true;

        this.employees.forEach((emp: any) => {
                const criterias = emp.criterias;

                criterias.forEach((criteria: any): any => {
                    if (criteria.note && !criteria.value) {
                        notificationHelper.showError(this.i18n.tr("err_CriteriaRequiredForNote"), "", { timeOut: 0 });
                        criteriasValid = false;
                    }
                    const criteriaInUse = _.find(emp.criterias, (item: any) => {
                        return item.value > 0;
                    });

                    const criteriaIsNotInUse = _.find(emp.criterias, (item: any) => {
                        return item.value === 0;
                    });
                    if (criteriaInUse && criteriaIsNotInUse) {
                        notificationHelper.showError(this.i18n.tr("err_AllCriteriaByEmployeeRequired"), "", { timeOut: 0 });
                        criteriasValid = false;
                    }
                });
        });

        return criteriasValid;
    }

    public loadCriterias(data: any, employeeId: number): any [] {
        return _.map(data, (item: any) => {
            return {
                id: item.Id, text: item.Description, value: this.getValueNote(employeeId, item.Id, true), note: this.getValueNote(employeeId, item.Id, false)
            };
        });
    }

    public getValueNote(employeeId: number, criteriaId: string, isValue: boolean): any {
        //getCriteria value for employee
        const empCriteriasvalue = this.getCriteriasDetails(employeeId);
        if (empCriteriasvalue.length > 0) {
            const result = _.find(empCriteriasvalue,
                (criteriaData: any) => {
                    return criteriaData.CriteriaId === criteriaId;
                });
            if (result) {
                return (isValue) ? result.Value || 0 : result.Note || "";
            } else {
                return (isValue) ? 0 : "";
            }
        } else {
            return (isValue) ? 0 : "";
        }
    }

    public getCriteriasDetails(employeeId: number): any {
        const result = _.find(this.loadedCriteriasValues, {
            EmployeeId: employeeId
        });
        if (result) {
            return result.Details;
        } else {
            return [];
        }
    }

    public mapDataToPost(): any {
        return _.map(this.employees, (emp: any) => {
            return {
                EmployeeId: emp.employee.EmployeeNumber,
                EvaluationDate: dateHelper.getUTCDate(this.dispatchDate),
                Details: _.filter(_.map(emp.criterias, (criteria: any): any => {
                    if (criteria.value) {
                        return {
                            CriteriaId: criteria.id,
                            Value: criteria.value,
                            Note: criteria.note
                        };
                    }
                }), (item: any) => {
                    return item !== undefined;
                })
            };
        });
    }

    public async activate(params: any): Promise<any> {
        await dailyEntryService.logPositionIfNeeded(params.dispatchProjectCode, params.dailyEntryDate);

        const paramsQueryString = routerHelper.getQuerystring(params.q);
        this.isProjectResponsible = (paramsQueryString && paramsQueryString.isProjectResponsible === "true") || false;
        this.dispatchDate = params.dailyEntryDate;
        this.dispatchProjectCode = params.dispatchProjectCode;

        await this.loadData();
    }

    public checkNotEvaluated(id: number): boolean  {
        const entry = _.where(this.loadedCriteriasValues, { EmployeeId: id });
        if (entry.length === 0) {
            return true;
        } else {
            return false;
        }
    }

    public async save(): Promise<void> {
        if (this.isCriteriasValid()) {
            await evaluationService.addEmployeeEvaluation(this.dispatchProjectCode, this.dispatchDate, this.mapDataToPost());
            routerHelper.navigateBack();
        }
    }

    public checkAllCriteriaHasValue(employee: any): boolean {
        return  _.every(employee.criterias, (criteria: any) => criteria.value > 0);
    }

    public valueChanged(event: any): void {
        const employee = event.detail;
        employee.isAllCriteriaHasValue  = this.checkAllCriteriaHasValue(employee);
    }
}
