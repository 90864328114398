import { autoinject } from "aurelia-framework";
import documentService from "services/documentService";
import { SettingRepository } from "repositories/setting-repository";
import { DispatchTemplateService } from "services/dispatch-template-service";

@autoinject()
export class ProjectMaterialRequisitionEditDocumentAdd {
    public editId: number = 0;
    public readonly: boolean = false;
    public actions: any;
    public dispatchProjectCode: string = "";
    public isCategorySelectionAllowed: boolean = false;

    constructor(private readonly settingRepository: SettingRepository, private readonly dispatchTemplateService: DispatchTemplateService) {
    }

    public async activate(params: any): Promise<any> {
        this.editId = params.requisitionId;
        this.dispatchProjectCode = params.dispatchProjectCode;
        const dispatchTemplateId = this.settingRepository.getDispatchTemplate();

        const dispatchTemplate = await this.dispatchTemplateService.GetDispatchTemplatesByCode(dispatchTemplateId!);
        this.isCategorySelectionAllowed = dispatchTemplate!.AllowCategorySelection;

        this.actions = {
            save: documentService.saveMaterialRequisitionPicture.bind(this, params.requisitionId, dispatchTemplateId)
        };
    }
}
