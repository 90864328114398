import { bindable, bindingMode, autoinject, BindingEngine, TaskQueue } from "aurelia-framework";
import { I18N } from "aurelia-i18n";

import { AdditionalFieldType } from "api/enums/additional-field-type";

import routerHelper from "helpers/routerHelper";
import { AdditionalFieldsSourceType } from "enums/additional-fields-source-type";
import { AdditionalFieldValueGetter } from "./helpers/additional-field-value-getter";
import { AdditionalFieldMapper } from "./helpers/additional-field-mapper";
import { AdditionalFieldsValidator } from "./helpers/additional-field-validator";
import { CloneHelper } from "helpers/cloneHelper";
import { default as _ } from "underscore";
import { ViewModeHelper } from "helpers/view-mode-helper";

@autoinject()
export class AdditionalFields {
  @bindable
  public actions: any = null;
  @bindable
  public additionalFieldsSource: AdditionalFieldsSourceType | null = null;
  @bindable
  public readonly: boolean = false;
  @bindable
  public saveAdditionalFieldsButton: boolean = true;
  @bindable
  public items: any[] | null = [];
  @bindable
  public unModifiedFields: any = null;
  @bindable
  public currentFields: any = null;
  @bindable
  public twoLinesHeader: boolean = true;

  private additionalFieldMapper: AdditionalFieldMapper;
  private additionalFieldsValidator: AdditionalFieldsValidator;
  private taskQueue: TaskQueue;
  private i18n: I18N;
  private readonly bindingEngine: BindingEngine;
  private viewModeHelper: ViewModeHelper;
  private subscriptions: any = [];
  private unloading: boolean = false;

  private additionalFieldType: any = {
    Text: AdditionalFieldType.Text,
    Memo: AdditionalFieldType.Memo,
    Date: AdditionalFieldType.Date,
    Time: AdditionalFieldType.Time,
    NumberWithDecimals: AdditionalFieldType.NumberWithDecimals,
    Amount: AdditionalFieldType.Amount,
    ShortInteger: AdditionalFieldType.ShortInteger,
    LongInteger: AdditionalFieldType.LongInteger,
    CheckBox: AdditionalFieldType.CheckBox,
    Timestamp: AdditionalFieldType.Timestamp,
    Phone: AdditionalFieldType.Phone,
    ComboBox: AdditionalFieldType.ComboBox,
    LinkedComboBox: AdditionalFieldType.LinkedComboBox,
    Grid: AdditionalFieldType.Grid
    };

    constructor(additionalFieldMapper: AdditionalFieldMapper, additionalFieldValueGetter: AdditionalFieldValueGetter, i18n: I18N, additionalFieldsValidator: AdditionalFieldsValidator, bindingEngine: BindingEngine, taskQueue: TaskQueue, viewModeHelper: ViewModeHelper) {
    this.additionalFieldMapper = additionalFieldMapper;
    this.additionalFieldsValidator = additionalFieldsValidator;
    this.i18n = i18n;
    this.bindingEngine = bindingEngine;
    this.taskQueue = taskQueue;
    this.viewModeHelper = viewModeHelper;
  }

  public gridTypeDisplay(field: any): string {
    return `${field.description} - ${field.columnName ? field.columnName : this.i18n.tr("Column") + " " + field.columnNo} - ${field.lineName ? field.lineName : this.i18n.tr("Line") + " " + field.lineNo}`;
  }

 public collectionChanged(change: any): any {
    if (this.unloading) {
        return;
    }
    this.items = this.additionalFieldMapper.mapDataToSave(this.currentFields);
  }

  private async attached(): Promise<void> {
      await this.loadData();
  }

  private async detached(): Promise<void> {
      this.unloading = true;
      if (this.subscriptions) {
          if (this.subscriptions.length > 0) {
              _.forEach(this.subscriptions, (subscription: any) => { subscription.dispose(); subscription = undefined; });
          }
          this.subscriptions = [];
      }
  }

  private async loadData(): Promise<any> {
    if (this.actions !== null) {
        const data = await this.actions.getAll();
        this.currentFields = this.additionalFieldMapper.mapFields(data);
        this.taskQueue.queueMicroTask((): void => {
            this.unModifiedFields = this.additionalFieldMapper.mapDataToSave(this.currentFields);
        });
    } else {
        this.currentFields = this.additionalFieldMapper.mapFields(this.items);
        this.unloading = false;
        this.subscriptions = this.currentFields.map((item: any) => this.bindingEngine.propertyObserver(item, "value").subscribe((newValue: any, oldValue: any) => { this.collectionChanged(this); }));
    }
    if (this.viewModeHelper.getIsMobileMode()) {
        this.taskQueue.queueMicroTask((): void => {
            const element: HTMLElement | null = document.getElementById("btnSave");

            if (element) {
                this.twoLinesHeader ? element.classList.add("subsubheader-two-header-gap") : element.classList.add("subsubheader-single-header-gap");
            }
        });
    }
  }
 
  private async save(): Promise<void> {
      // Notice user but let it go
      await this.additionalFieldsValidator.validateAdditionalFields(this.currentFields, this.additionalFieldsSource);
  
      const mappedData = this.additionalFieldMapper.mapDataToSave(this.currentFields);
      this.unModifiedFields = null;
      this.items = null;
 
      await this.actions.setAll(mappedData);
      routerHelper.navigateBack();
  }
}
