import { autoinject, observable} from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import routerHelper from "helpers/routerHelper";
import { Viewbag } from "helpers/view-bag";
import { default as queryStringHelper } from "helpers/queryStringHelper";
import { default as templateService } from "services/templateService";
import { BonusService } from "services/bonus-service";
import projectService from "services/projectService";
import enumHelper from "helpers/enumHelper";
import _ from "underscore";
import notificationHelper from "helpers/notificationHelper";
import defaultService from "services/defaultService";

@autoinject()
export class DailyTimeEntryBonus {
    public PAUSE_ACTIVITY_ID: string = "PAUSE";

    public enumHelper: typeof enumHelper = enumHelper;

    public i18n: I18N;
    public dispatchProjectCode: string = "";
    public dispatchDate: string = "";
    public readonly: boolean = true;

    public bonusId: number = 0;
    public dispatchIds: any[] = [];
    public activities: any[] = [];
    public isProjectResponsible: boolean = false;
    public showSubProject: boolean = false;
    public unit: string = "";
    public message: string = "";
    public activityAvailable: boolean = false;
    public defaultLaborActivityCode: string = "";
    public quantity: number | null = null;

    // default bonus
    public isDefaultbonus: boolean = false;
    public isDefaultBonusInHour: boolean = false;
    public defaultQuantity: number = 0;
    public activity: any = null;
    public subProject: any = null;

    @observable
    public applicable: boolean = false;
    @observable
    public bonus: any = null;

    public pageSize: number = defaultService.getPageSize();
   
    constructor(i18n: I18N, private bonusService: BonusService, public viewbag: Viewbag) {
        this.i18n = i18n;
    }

    public get getProjects(): any {
        return {
            transport: async (params: any, success: any): Promise<any> => {
                await projectService.getSubProjects(this.dispatchProjectCode, params.data.filter, params.data.page)
                    .then((data: any) => {
                        return success(data);
                    });
            }
        };
    }

    public get getBonuses(): any {
        return {
            transport: async (params: any, success: any): Promise<any> => {
                await this.bonusService.GetBonusesForDispatchProject(this.dispatchProjectCode, params.data.filter, { page: params.data.page, pageSize: this.pageSize || 20})
                    .then((data: any) => {
                        return success(data);
                    });
            },
            mapResults: (item: any): any => {
                return { id: item.Id, text: `${item.Id} - ${item.Description}`, data: item };
            }
        };
    }

    public get getActivity(): any {
        return {
            transport: async (params: any, success: any): Promise<any> => {
                if (!this.showSubProject) {
                    await projectService.getActivitiesForDispatchProject(this.dispatchProjectCode, params.data.filter, params.data.page || 1, this.enumHelper.workOrderActivityType().LABOUR)
                        .then((data: any) => {
                            return success(data);
                        });
                } else if (this.subProject) {
                    await projectService.getActivitiesForProject(this.subProject.id, params.data.filter, params.data.page || 1, this.enumHelper.workOrderActivityType().LABOUR)
                        .then((data: any) => {
                            return success(data);
                        });
                }
            },
            mapResults: (item: any): any => {
                return { id: item.Id, text: `${item.Id} - ${item.Description}`, data: item };
            }
        };
    }

    public bonusChanged(): void {
        if (!this.bonus) { return; }

        this.quantity = 1;
        this.unit = this.bonus.data.data.Unit;

        if (this.activityAvailable) {
            let activityCode = this.bonus.data.data.DefaultActivityCode;
            if (!activityCode) {
                activityCode = this.defaultLaborActivityCode;
            }
            this.activity = this.createDefaultMaSelectElement(activityCode, this.activities);
        }
    }

    public setApplicable(): void {
        this.applicable = !this.applicable;
        if (this.applicable) {
            this.quantity = this.defaultQuantity;
        } else {
            this.quantity = 0;
        }
    }

    public createDefaultMaSelectElement(id: string, list: any[]): any {
        if (!id) { return null; }

        const item = list.find((itemlst: any) => itemlst.Id === id);
        if (!item) {
            return null;
        }

        return { id: item.Id, text: `${item.Id} - ${item.Description}` };
    }

    public async activate(params: any): Promise<void> {
        await this.bindViewModel(params.dispatchProjectCode, params.dailyEntryDate, params.id, params.q);
        await this.loadData();
    }

     public async bindViewModel(dispatchProjectCode: string, dispatchDate: string, bonusId: number , queryString: string): Promise<void> {
        this.viewbag.value = { isFromBonusEntry: true };
        this.activityAvailable = (!templateService.getCurrentTemplateConfigs().ActivityNotAllowedInMobility);

        this.isProjectResponsible = queryStringHelper.parseIsProjectResponsible(queryString);
        this.readonly = queryStringHelper.parseReadonly(queryString);

        this.dispatchIds = routerHelper.getQuerystring(queryString).dispatchIds.split(",");

        const name = this.dispatchIds.length === 1 ? routerHelper.getQuerystring(queryString).name : "";
        this.defaultLaborActivityCode = routerHelper.getQuerystring(queryString).defaultLaborActivityCode;
       
        this.dispatchDate = dispatchDate;
        this.dispatchProjectCode = dispatchProjectCode;
        this.bonusId = bonusId;
        this.message = name;
       
        if (this.activityAvailable) {
            await this.getActivities();
        }
    }

    public async loadData(): Promise<void> {
        const bonus: any = this.bonusId ? this.bonusService.GetBonus(this.bonusId) : null;

        const hasSubProjects = projectService.hasSubProjects(this.dispatchProjectCode);
        const lastProjectUsed = projectService.getLastProjectUsed(this.dispatchProjectCode, this.dispatchDate);

        await Promise.all([bonus, hasSubProjects, lastProjectUsed]).then(([bonuses, hasPrj, lastPrjUsed]: any): any => {
            this.initializeProjects(hasPrj, lastPrjUsed);
            if (bonuses) {
                this.loadBonus(bonuses);
            }
        });
    }

    public initializeProjects(hasPrj: boolean, lastPrjUsed: any): void {
        this.showSubProject = hasPrj;
        if (lastPrjUsed) {
            this.subProject = { id: lastPrjUsed.Id, text: `${lastPrjUsed.Id} - ${lastPrjUsed.Description}` };
        }
    }

    public loadBonus(data: any): void {
        this.activity = { id: data.ActivityCode, text: `${data.ActivityCode} - ${data.ActivityDescription}` };
        this.bonus = { id: data.BonusCode, text: `${data.BonusCode} - ${data.BonusDescription}`, data: { data: { Unit: data.BonusUnit, DefaultActivityCode: data.ActivityCode } } };
        this.quantity = data.Quantity;

        this.applicable = data.Quantity > 0;
        this.isDefaultbonus = data.DefaultBonusId && data.DefaultBonusId > 0;

        // In readonly mode, quantity in hour is show like a normal quantity
        if (!this.readonly) {
            this.isDefaultBonusInHour = this.isDefaultbonus && data.IsQuantityInHour;
            this.defaultQuantity = data.DefaultQuantity;
        }

        this.unit = data.BonusUnit;
        if (data.ProjectCode) {
            this.subProject = { id: data.ProjectCode, text: `${data.ProjectCode} - ${data.ProjectDescription}` };
        }
    }

    public async getActivities(): Promise<void> {
        const actlist = await projectService.getActivitiesForProject(!this.showSubProject ? this.dispatchProjectCode : this.subProject.id, undefined, undefined, this.enumHelper.workOrderActivityType().LABOUR);
        this.activities = _.reject(actlist, (item: any) => item.Id === this.PAUSE_ACTIVITY_ID);
    }

    public clear(): void {
        this.activity = null;
        this.bonus = null;
        this.quantity = null;
        this.subProject = null;
        this.showSubProject = false;
        this.unit = "";
    }

    public buildBonusDto(): any {
        return _.map(this.dispatchIds,
             (dispatchId: number) => {
                const data: any = {};

                if (this.bonusId) {
                    data.BonusId = this.bonusId;
                }
                if (this.subProject) {
                    //Set the project code if we have it
                    data.ProjectCode = this.subProject.id;
                }
                data.DispatchId = dispatchId;

                if (this.activity) {
                    data.ActivityCode = this.activity.id;
                }

                data.BonusCode = this.bonus.id;
                data.Quantity = this.quantity;

                return data;
            });
    }

    public isValid(): boolean {
        return this.isBonusSpecified() && this.isActivitySpecified() && this.isQuantitySpecifiedAndValid();
    }

    public isBonusSpecified(): boolean {
        if (!this.bonus) {
            notificationHelper.showError(this.i18n.tr("err_BonusRequired"));
            return false;
        }
        return true;
    }

    public isActivitySpecified(): boolean {
        if (this.activityAvailable && !this.activity) {
            notificationHelper.showError(this.i18n.tr("err_ActivityRequired"));
            return false;
        }
        return true;
    }

    public isQuantitySpecifiedAndValid(): boolean {
        if (this.isDefaultBonusInHour) {
            this.quantity = 0;
            return true;
        }

        if (!this.quantity) {
            notificationHelper.showError(this.i18n.tr("err_QuantityRequired"));
            return false;
        }
        if (this.quantity && this.quantity <= 0) {
            notificationHelper.showError(this.i18n.tr("err_MinQuantity"));
            return false;
        }
        return true;
    }

    public async save(): Promise<void> {
        if (this.isValid()) {
            await this.bonusService.CreateOrUpdateBonuses(this.buildBonusDto());
            routerHelper.navigateBack();
        }
    }
}
