// GENERATED FILE - DO NOT MODIFY
export enum ValidationError {
    InvalidCompanyTag = 1,
    UserNoAccessToCompany = 2,
    UserNotLinkedToEmployee = 4,
    ServiceCallNotFound = 5,
    DispatchModelMissing = 6,
    UserTypeAccessMaestroStarOnly = 7,
    DispatchModelNotFound = 8,
    AccessDenied = 9,
    PasswordValidationFailedTooShort = 496,
    PasswordValidationFailedTooLong = 497,
    PasswordValidationFailedNotComplexEnough = 498,
    DatabaseConnectionError = 499,
    CannotGoOverPreviousActivity = 500,
    StartTimeGreaterThanEndTime = 501,
    ActivityRequired = 505,
    CannotDeleteOtherUserRequisition = 508,
    CannotDeleteOtherUserRequisitionDetail = 509,
    InvalidStatusDeleteRequisition = 510,
    InvalidStatusDeleteRequisitionDetail = 511,
    MultipleEntriesProjectSameDay = 513,
    EntryCannotBeCompleted = 517,
    DispatchNotFound = 518,
    LaborEntryNotFound = 519,
    ReturnDateMustBeAfterCurrentAssignedDate = 520,
    InvalidNoteNumber = 521,
    InvalidFieldDefinition = 522,
    EntryCannotBeReactivatedProcessByResponsible = 523,
    DispatchStartDateAfterEndDate = 524,
    DispatchStartTimeAfterEndTime = 525,
    MultipleWorkOrdersForDispatch = 526,
    RefusalReasonRequired = 527,
    TransportEndDateMustBeGreaterOrEqualToStartDate = 528,
    MaxDispatchDurationExceeded = 529,
    DispatchNoStartDate = 530,
    EndDateBeforeStartDate = 531,
    StartTimeBeforeTransportStartTime = 532,
    InvalidDispatchProgression = 533,
    DispatchStartDateChangeTooLarge = 534,
    DispatchStartDateDifferenceFromNowTooLarge = 535,
    DispatchReadOnly = 536,
    RequisitionReadOnly = 537,
    MaxUserCountLicences = 538,
    ProjectNotFound = 539,
    TimeEntryNotFound = 540,
    DeleteError = 541,
    UpdateError = 542,
    ProjectConfigNotFound = 543,
    InsertError = 544,
    GapOrOvelapInTimeEntries = 545,
    BonusNotFound = 546,
    MultipleLunchesForDispatch = 547,
    EmployeeNotFound = 548,
    UnavailabilityExists = 549,
    DispatchTimeEntriesOver24Hours = 550,
    WorkOrderNotFound = 551,
    CustomerNotFound = 552,
    InvalidWorkOrderMaskOrCounter = 553,
    RecommendationNotFound = 554,
    RecommendationReadOnly = 555,
    MultipleDispatchesEnRoute = 556,
    TemporaryContractNotFound = 557,
    ConcurrencyError = 558,
    MaterialEntryNotFound = 559,
    DispatchCannotBeCompleted = 560,
    AdditionalFieldsTableNotFound = 561,
    AdditionalFieldsDdfOrDictionaryError = 562,
    InspectionTypeNotFound = 563,
    InstallationKeyLoadError = 564,
    InspectionContentDoesNotCorrespondWithInspectionType = 565,
    EquipmentPreventiveMaintenanceNoCategory = 566,
    EquipmentPreventiveMaintenanceCategoryNoCounter = 567,
    InvalidDefaultGroupForProjectProgress = 568,
    InvalidDefaultActivityForProjectProgress = 569,
    ActivityDoesNotCorrespondToDefaultActivityForProjectProgress = 570,
    ActivityProgressNotFoundOrNotAvailable = 571,
    CouldNotReadMimeTypeFromDocumentData = 572,
    DailyEntryNotFound = 573,
    InterruptionNotFound = 574,
    WorkflowReadError = 575,
    WorkflowChangeError = 576,
    DocumentNotFound = 577,
    PayPeriodNotFound = 578,
    TimesheetNotFound = 579,
    TimesheetEntryNotFound = 580,
    TimesheetEntryShouldBeInCurrentOrPreviousPayPeriod = 581,
    TimesheetExistsInMaestroForPayPeriod = 582,
    EmployeeIsNotPartOfATeam = 583,
    MultipleTimesheetsForPayPeriod = 584,
    PayPeriodMoreThan7Days = 585,
    TimesheetsEmployeeIsPartOfMultipleTeams = 586,
    TimesheetsTeamMemberIsPartOfMultipleTeams = 587,
    CatalogItemNotFound = 588,
    EstimateNotFound = 589,
    DispatchAppointmentConfirmationNotSent = 592,
    ContactNotFound = 593,
    ServiceCallQuotationNotFound = 594,
    ServiceCallQuotationItemNotFound = 595,
    ServiceCallQuotationPriceCalculationError = 596,
    ServiceCallQuotationStatusInvalidForOperation = 597,
    ServiceCallQuotationFormGenerationError = 598,
    ServiceCallQuotationNoFormRecipientForCustomer = 599,
    CorruptFile = 600,
    InvalidFileFormat = 601,
    InvalidCellValue = 602,
    DuplicateVariableName = 603,
    ServiceCallQuotationNumberExists = 604,
    ServiceCallContractNotFound = 605,
    ServiceCallQuotationPriceCalculationWithNoCustomerError = 606,
    PurchaseOrderCounterError = 607,
    ServiceCallQuotationPriceCalculationLumpSumWithNoItemsError = 608,
    TimesheetsTeamMemberHasNotSameFirstDayOfWeek = 609,
    CannotDeleteOtherUserPurchaseOrder = 610,
    PurchaseOrderReadOnly = 611,
    CannotDeleteOtherUserPurchaseOrderDetail = 612,
    PurchaseOrerNotFound = 613,
    PayPeriodTeamMemberNotFound = 614,
    DefaultBonusesError = 615,
    VisitNotFound = 616,
    CustomPrefixGroupDescriptionRequired = 617,
    CustomPrefixGroupDescriptionAlreadyExist = 618,
    CustomPrefixGroupCompaniesRequired = 619,
    CustomPrefixGroupCompaniesAlreadyExist = 620,
    ReceiptOfGoodsIsClose = 621,
    ReceiptOfGoodsInProgress = 622,
    PurchaseOrderNotActive = 623,
    ReceiptOfGoodsNotFound = 624,
    ReceiptOfGoodsItemMissingSerialNo = 625,
    ReceiptOfGoodsItemNotFound = 626,
    ReceiptOfGoodsAllItemsAreReceived = 627,
    TimeKeepingInNotFoundDate = 628,
    TimeKeepingInvalidDate = 629,
    PurchaseOrerIsClosed = 630,
    InventoryTransferItemNoQtySpecified = 631,
    InventoryTransferItemQtyInvalid = 632,
    InventoryTransferItemQtyCannotBeNegative = 633,
    InventoryTransferItemQtyInvalidForSerial = 634,
    InventoryTransferNotFound = 635,
    InventoryTransferItemNotFound = 636,
    ProcoreCreatePurchaseOrder = 637,
    UserNotFound = 638,
    CompanyNotFound = 639,
    MaestroBiSettingsNotFoundError = 640,
    ProcoreCreateContract = 641,
    InspectionMandatorySignatureNotSpecified = 642,
    InspectionFormGenerationError = 643,
    WorkOrderWorkTaskNotFound = 644,
    WorkOrderWorkTaskCannotBeDeleted = 645,
    MaestroBiExagoSessionInvalidError = 646,
    MaestroBiExagoInitializationFailedError = 647,
    DocumentCannotDeleteLinkedToPendingTransaction = 650,
    DocumentCannotDeleteUserInvalid = 651,
    ReceiptOfGoodsItemNotAddedByUser = 652,
    ReceiptOfGoodsItemAlreadyExist = 653,
    PurchaseOrderFormGenerationError = 654,
    PurchaseOrderTransferError = 655,
    PurchaseOrderCannotTranferError = 656,
    PurchaseOrderNoItemsTranferError = 657,
    PurchaseOrderNoDeliveryAddressSpecifiedError = 658,
    PurchaseOrderNoItemsSendError = 659,
    PurchaseOrderCannotSendError = 660,
    ProcoreCreateChangeOrder = 661,
    DispatchNoEndDate = 662,
    SMSProviderNotValid = 663,
    SMSCellPhoneNotValid = 664,
    SMSSendingMessageError = 665,
    SMSTextNotSendError = 667,
    PendingServiceCallContractCantCreateServiceCall = 668,
    WorkOrderTypeCodeForAdditionalFieldsError = 669,
    PurchaseOrderAdditionalFieldsError = 670,
    TransferTransactionError = 671,
    ReceiptOfGoodTransferError = 672,
    CustomerAlreadyExist = 673,
    ProjectIdNotSpecified = 674,
    ProjectAlreadyExist = 675,
    CustomerIdNotSpecified = 676,
    ServiceCallContractInvalidMaskOrNumber = 677,
    ProjectInvalidMaskOrNumber = 678,
    CreateProjectError = 679,
    DispatchContainActivities = 680,
    DispatchProjectNotFound = 681,
    LastShiftUsedError = 682,
    CCQReportGenerationError = 683,
    UnavailabilityOverlapsExists = 684,
    SignatureEmailMaxLengthExceeded = 685,
    ServiceQuotationModifiedByAnotherUser = 686,
    RequiredMaterialNotFound = 687,
    DispatchSpecifiedTimesExceedWorkSchedule = 688,
    ProjectDispatchHasTimeEntries = 689,
    ReceiptOfGoodsContainsErrors = 690,
    TransferInventorySiteContainsErrors = 691,
    TransferInventoryProjectContainsErrors = 692,
    RequisitionContainNoItems = 693,
    RequisitionAdditionalFieldsError = 694,
    MaestroServerError = 800,
    XmlSerializeRequestError = 810,
    OrisoApiError = 850,
    MobileVersionMismatch = 999,
    ProcoreUnauthorized = 20000,
    ProcoreLimitRate = 20001,
    ProcoreUnsupported = 20002,
    ProcoreErrorOccurred = 20003,
    ProcoreBadRequest = 20004,
    ProcoreNotFound = 20005,
    ProcoreNumberOfActiveProjects = 20006,
    ProcoreServiceUnavailable = 20007,
    ProcoreLoaderError = 20008,
    ProcoreInactiveProject = 20009,
    ProcoreSynchronizationFailed = 20011,
    ProcoreNoManager = 20012,
    ProcoreCodeIsMandatory = 20013,
    ProcoreUpdateOriginIdFailed = 20014,
    ProcoreUpdateSynchronizationDateTimeFailed = 20015,
    ProcoreParametersRequired = 20016,
    ProcoreRights = 20017,
    ProcoreParameterCastingError = 20018,
    ProcoreMasterProjectNotSynchronized = 20019,
    ProcoreActiveToolDisabled = 20020,
    ProcoreCostCodeNotFound = 20021,
    ProcoreOrderInvoiceSovEnabled = 20022,
    ProcoreSynchronizationFailedWithMessage = 20023,
    ProcoreBudgetsGroupCodeNotConfiguredInProcore = 20030,
    ProcoreBudgetAccountDisabled = 20031,
    ProcoreBudgetIsAtZero = 20032,
    ProcoreModifyBudgetFailed = 20033,
    ProcoreBudgetsGroupCodeNotConfiguredInMaestro = 20034,
    MaestroActivityNotFound = 20035,
    GroupIsRestricted = 20036,
    PccoClientDoesNotExist = 20040,
    PccoClientIsDifferentFromProject = 20041,
    PrimeContractDoesNotExistInProcore = 20042,
    PrimeContractExistsInMaestro = 20043,
    PrimeContractDoesNotHaveLines = 20044,
    PrimeContractDoesNotExistInMaestro = 20045,
    RecordLock = -4915,
}

export let nameof = "ValidationError";
