import { autoinject } from "aurelia-framework";
import routerHelper from "helpers/routerHelper";
import queryStringHelper from "helpers/queryStringHelper";
import documentService from "services/documentService";
import { SettingRepository } from "repositories/setting-repository";

@autoinject()
export class ProjectRecommendationEditDocumentAdd {
    public dispatchProjectCode: string = "";
    public editId: number = 0;
    public readonly: boolean = false;
    public actions: any;

    constructor(private readonly settingRepository: SettingRepository) {
    }

    public activate(params: any): any {
        const paramsQs = routerHelper.getQuerystring(params.q);
        if (paramsQs && paramsQs.readonly) {
            this.readonly = queryStringHelper.parseReadonly(params.q);
        }

        this.editId = params.recommendationId;
        this.dispatchProjectCode = params.dispatchProjectCode;

        const dispatchTemplateId = this.settingRepository.getDispatchTemplate();

        this.actions = {
            save: documentService.saveRecommendationPicture.bind(this, params.recommendationId, "project", params.dispatchProjectCode, dispatchTemplateId)
        };
    }
}
