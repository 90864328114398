import { bindable, autoinject, DOM } from "aurelia-framework";
import { FroalaLazyLoader } from "helpers/froala-lazy-loader";
import config from "config";
import { default as settings } from "repositories/settingRepository";
import { CustomEventHelper } from "helpers/custom-event-helper";

@autoinject
export class RichText {
  @bindable
  public value: string = "";
  @bindable
  public readonly: boolean = false;
  @bindable
  public id: string = "";

  private froalaLazyLoader: FroalaLazyLoader;
  private toolbarDefaultSetting: string[];

    constructor(froalaLazyLoader: FroalaLazyLoader, private readonly element: Element) {
    this.froalaLazyLoader = froalaLazyLoader;
    this.toolbarDefaultSetting = ["fullscreen", "fontFamily", "fontSize", "bold", "italic", "underline", "align", "formatOL", "formatUL"];
    }

    public valueChanged(): void {
        CustomEventHelper.dispatchEvent(this.element, "value-changed", this.value);
    }

  private loadData(): void {
    let $editor = jQuery("textarea") as any;

    if (!!this.id) {
        $editor = $editor.filter(`#${this.id}`);
    }

    $editor.froalaEditor({
      key: config.froalaEditorKey,
      toolbarButtons: this.toolbarDefaultSetting,
      toolbarButtonsMD: this.toolbarDefaultSetting,
      toolbarButtonsSM: this.toolbarDefaultSetting,
      toolbarButtonsXS: this.toolbarDefaultSetting,
      theme: "gray",
      language: settings.getLanguage().toLowerCase()
    }).on("froalaEditor.contentChanged", (e: any, editor: any): any => {
        this.value = editor.html.get();
    });

    if (this.readonly) {
      $editor.froalaEditor("edit.off");
      jQuery("div.fr-element.fr-view").css("background-color", "#e6e6e6");
    }
  }

  private async attached(): Promise<void> {
    await this.froalaLazyLoader.loadModule();
    this.loadData();
  }
}
