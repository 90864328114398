import { default as recommendationService } from "services/recommendationService";
import { inject } from "aurelia-framework";
import { DispatchProjectService } from "services/dispatch-project-service";
import notificationHelper from "helpers/notificationHelper";
import { I18N } from "aurelia-i18n";

@inject(I18N, DispatchProjectService, notificationHelper)
export default class Recommendation {
    dispatchProjectCode;
    fetchRecommendations;

    constructor(i18n, dispatchProjectService, notificationHelper) {
        this.dispatchProjectService = dispatchProjectService;
        this.notificationHelper = notificationHelper;
        this.i18n = i18n;
    }

    async canActivate(params) {
        this.dispatchProjectCode = params.dispatchProjectCode;

        try {
            await this.checkIsProjectActive();
        } catch (e) {
            if (e && e.response && e.response.data && e.response.data.Exception && e.response.data.Exception.ErrorId) {
                this.notificationHelper.showError(this.i18n.tr(`ApiError.${e.response.data.Exception.ErrorId}`), undefined, { timeOut: 0, extendedTimeOut: 0 });
            } else {
                this.notificationHelper.showError(e.response.data.Exception.Message, undefined, { timeOut: 0, extendedTimeOut: 0 });
            }
            return false;
        }
        return true;
    }

    activate(params) {
        this.bindViewModel(params.dispatchProjectCode);
    }

    bindViewModel(dispatchProjectCode) {
        this.dispatchProjectCode = dispatchProjectCode;
        this.fetchRecommendations = async (status, filter, page) => await recommendationService.getRecommendationsForProject(dispatchProjectCode, status, filter, page).promise();
    }

    async checkIsProjectActive() {
        await this.dispatchProjectService.GetProjectFromDispatchProjectCode(this.dispatchProjectCode);
    }
}
