// GENERATED FILE - DO NOT MODIFY
import { CatalogItemModel } from "api/models/company/catalog/catalog-item-model";
import { PagingInfo } from "api/paging-info";
import BaseProxy from "api/proxies/base-proxy";
import ModelDateTransformer from "api/util/model-date-transformer";
import { autoinject } from "aurelia-framework";
import { ApiHelper } from "helpers/api-helper";
import { HttpClientFactory } from "http-client/http-client-factory";
import { HttpClientRequestConfig } from "http-client/http-client-request-config";

@autoinject()
export class CatalogProxy extends BaseProxy {
    constructor(modelDateTransformer: ModelDateTransformer, httpClientFactory: HttpClientFactory, apiHelper: ApiHelper) {
        super(modelDateTransformer, httpClientFactory, apiHelper);
    }

    public async GetCatalogItem(catalogItemId: string | null, requestConfig?: HttpClientRequestConfig): Promise<CatalogItemModel | null> {
        const uri = this.buildUri(
            "/catalog/get-by-id",
            null,
            { catalogItemId: (catalogItemId === null || catalogItemId === undefined) ? undefined : catalogItemId }
        );

        const data: CatalogItemModel | null = await this.get<CatalogItemModel | null>(uri, requestConfig);
        this.transformModels(data, "Company.Catalog.CatalogItemModel");
        return data;
    }

    public async GetCatalogItemsForFieldServicesMaterialRequisitions(dispatchTemplateId: string | null, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<CatalogItemModel[] | null> {
        const uri = this.buildUri(
            "/catalog/fieldservices/materialrequisitions",
            null,
            { dispatchTemplateId: (dispatchTemplateId === null || dispatchTemplateId === undefined) ? undefined : dispatchTemplateId, filter: filter ? filter : undefined, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: CatalogItemModel[] | null = await this.get<CatalogItemModel[] | null>(uri, requestConfig);
        this.transformModels(data, "Company.Catalog.CatalogItemModel");
        return data;
    }

    public async GetCatalogItemsForFieldServicesMaterial(dispatchTemplateId: string | null, workorderId: string | null, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<CatalogItemModel[] | null> {
        const uri = this.buildUri(
            "/catalog/fieldservices/material",
            null,
            { dispatchTemplateId: (dispatchTemplateId === null || dispatchTemplateId === undefined) ? undefined : dispatchTemplateId, workorderId: (workorderId === null || workorderId === undefined) ? undefined : workorderId, filter: filter ? filter : undefined, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: CatalogItemModel[] | null = await this.get<CatalogItemModel[] | null>(uri, requestConfig);
        this.transformModels(data, "Company.Catalog.CatalogItemModel");
        return data;
    }

    public async GetCatalogItemsForFieldServicesMaterialBilling(dispatchTemplateId: string | null, workorderId: string | null, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<CatalogItemModel[] | null> {
        const uri = this.buildUri(
            "/catalog/fieldservices/materialbilling",
            null,
            { dispatchTemplateId: (dispatchTemplateId === null || dispatchTemplateId === undefined) ? undefined : dispatchTemplateId, workorderId: (workorderId === null || workorderId === undefined) ? undefined : workorderId, filter: filter ? filter : undefined, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: CatalogItemModel[] | null = await this.get<CatalogItemModel[] | null>(uri, requestConfig);
        this.transformModels(data, "Company.Catalog.CatalogItemModel");
        return data;
    }

    public async GetCatalogItemsForProjectMaterialRequisitions(dispatchTemplateId: string | null | undefined, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<CatalogItemModel[] | null> {
        const uri = this.buildUri(
            "/catalog/dispatch/projects/materialrequisitions",
            null,
            { dispatchTemplateId: (dispatchTemplateId === null || dispatchTemplateId === undefined) ? undefined : dispatchTemplateId, filter: filter ? filter : undefined, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: CatalogItemModel[] | null = await this.get<CatalogItemModel[] | null>(uri, requestConfig);
        this.transformModels(data, "Company.Catalog.CatalogItemModel");
        return data;
    }

    public async GetCatalogItemsForProjectMaterial(projectDispatchCode: string | null, dispatchDate: Date, dispatchTemplateId: string | null | undefined, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<CatalogItemModel[] | null> {
        const uri = this.buildUri(
            "/catalog/dispatch/projects/{projectDispatchCode}/dispatchdates/{dispatchDate}/material",
            { projectDispatchCode: (projectDispatchCode === null || projectDispatchCode === undefined) ? undefined : projectDispatchCode, dispatchDate: (dispatchDate === null || dispatchDate === undefined) ? undefined : dispatchDate.toString() },
            { dispatchTemplateId: (dispatchTemplateId === null || dispatchTemplateId === undefined) ? undefined : dispatchTemplateId, filter: filter ? filter : undefined, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: CatalogItemModel[] | null = await this.get<CatalogItemModel[] | null>(uri, requestConfig);
        this.transformModels(data, "Company.Catalog.CatalogItemModel");
        return data;
    }

    public async GetCatalogItemsForServiceCallMaterialRequisitions(dispatchId: number, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<CatalogItemModel[] | null> {
        const uri = this.buildUri(
            "/catalog/serviceCalls/dispatch/{dispatchId}/materialrequisitions",
            { dispatchId: (dispatchId === null || dispatchId === undefined) ? undefined : dispatchId.toString() },
            { filter: filter ? filter : undefined, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: CatalogItemModel[] | null = await this.get<CatalogItemModel[] | null>(uri, requestConfig);
        this.transformModels(data, "Company.Catalog.CatalogItemModel");
        return data;
    }

    public async GetCatalogItemsForServiceCallMaterial(dispatchId: number, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<CatalogItemModel[] | null> {
        const uri = this.buildUri(
            "/catalog/serviceCalls/dispatch/{dispatchId}/material",
            { dispatchId: (dispatchId === null || dispatchId === undefined) ? undefined : dispatchId.toString() },
            { filter: filter ? filter : undefined, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: CatalogItemModel[] | null = await this.get<CatalogItemModel[] | null>(uri, requestConfig);
        this.transformModels(data, "Company.Catalog.CatalogItemModel");
        return data;
    }

    public async GetCatalogItemsForServiceCallMaterialBilling(dispatchId: number, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<CatalogItemModel[] | null> {
        const uri = this.buildUri(
            "/catalog/serviceCalls/dispatch/{dispatchId}/materialbilling",
            { dispatchId: (dispatchId === null || dispatchId === undefined) ? undefined : dispatchId.toString() },
            { filter: filter ? filter : undefined, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: CatalogItemModel[] | null = await this.get<CatalogItemModel[] | null>(uri, requestConfig);
        this.transformModels(data, "Company.Catalog.CatalogItemModel");
        return data;
    }

    public async GetCatalogItemsForWorkOrderMaterialRequisitions(dispatchId: number, isForRequisitionWorkOrderType: boolean | undefined, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<CatalogItemModel[] | null> {
        const uri = this.buildUri(
            "/catalog/workOrders/dispatch/{dispatchId}/materialrequisitions",
            { dispatchId: (dispatchId === null || dispatchId === undefined) ? undefined : dispatchId.toString() },
            { isForRequisitionWorkOrderType: (isForRequisitionWorkOrderType === null || isForRequisitionWorkOrderType === undefined) ? undefined : isForRequisitionWorkOrderType.toString(), filter: filter ? filter : undefined, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: CatalogItemModel[] | null = await this.get<CatalogItemModel[] | null>(uri, requestConfig);
        this.transformModels(data, "Company.Catalog.CatalogItemModel");
        return data;
    }

    public async GetCatalogItemsForWorkOrderMaterial(dispatchId: number, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<CatalogItemModel[] | null> {
        const uri = this.buildUri(
            "/catalog/workOrders/dispatch/{dispatchId}/material",
            { dispatchId: (dispatchId === null || dispatchId === undefined) ? undefined : dispatchId.toString() },
            { filter: filter ? filter : undefined, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: CatalogItemModel[] | null = await this.get<CatalogItemModel[] | null>(uri, requestConfig);
        this.transformModels(data, "Company.Catalog.CatalogItemModel");
        return data;
    }

    public async GetCatalogItemsForWorkOrderMaterialBilling(dispatchId: number, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<CatalogItemModel[] | null> {
        const uri = this.buildUri(
            "/catalog/workOrders/dispatch/{dispatchId}/materialbilling",
            { dispatchId: (dispatchId === null || dispatchId === undefined) ? undefined : dispatchId.toString() },
            { filter: filter ? filter : undefined, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: CatalogItemModel[] | null = await this.get<CatalogItemModel[] | null>(uri, requestConfig);
        this.transformModels(data, "Company.Catalog.CatalogItemModel");
        return data;
    }

    public async GetCatalogItemsForQuotationMaterial(quotationId: number, dispatchTemplateId: string | null | undefined, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<CatalogItemModel[] | null> {
        const uri = this.buildUri(
            "/catalog/service-call/quotation/material",
            null,
            { quotationId: (quotationId === null || quotationId === undefined) ? undefined : quotationId.toString(), dispatchTemplateId: (dispatchTemplateId === null || dispatchTemplateId === undefined) ? undefined : dispatchTemplateId, filter: filter ? filter : undefined, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: CatalogItemModel[] | null = await this.get<CatalogItemModel[] | null>(uri, requestConfig);
        this.transformModels(data, "Company.Catalog.CatalogItemModel");
        return data;
    }

    public async GetCatalogItemsForQuotationMaterialBilling(quotationId: number, dispatchTemplateId: string | null | undefined, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<CatalogItemModel[] | null> {
        const uri = this.buildUri(
            "/catalog/service-call/quotation/material-billing",
            null,
            { quotationId: (quotationId === null || quotationId === undefined) ? undefined : quotationId.toString(), dispatchTemplateId: (dispatchTemplateId === null || dispatchTemplateId === undefined) ? undefined : dispatchTemplateId, filter: filter ? filter : undefined, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: CatalogItemModel[] | null = await this.get<CatalogItemModel[] | null>(uri, requestConfig);
        this.transformModels(data, "Company.Catalog.CatalogItemModel");
        return data;
    }

    public async GetCatalogItemsForInventoryTransfer(filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<CatalogItemModel[] | null> {
        const uri = this.buildUri(
            "/catalog/inventory-transfer",
            null,
            { filter: filter ? filter : undefined, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: CatalogItemModel[] | null = await this.get<CatalogItemModel[] | null>(uri, requestConfig);
        this.transformModels(data, "Company.Catalog.CatalogItemModel");
        return data;
    }
}
