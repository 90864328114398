import _ from "underscore";

export class StringHelper {
    public static keepAlphaNumericOnly: RegExp = /^[a-zA-Z0-9]*$/;
    public static keepNumericOnly: RegExp = /^[0-9]*$/;

    public static replaceAt(value: string, index: number, replacement: string): string {
        return value.substring(0, index) + replacement + value.substring(index + 1);
    }

    public static insertAt(value: string, index: number, valueToInsert: string): string {
        return value.slice(0, index) + valueToInsert + value.substring(index);
    }

    public static startsWith(value: string, searchString: string): boolean {
        return value.substring(0, searchString.length) === searchString;
    }

    public static endsWith(value: string, searchString: string): boolean {
        //minus sign in sustr function is for -1 * of the length
        return value.substring(-searchString.length) === searchString;
    }

    public static cleanString(value: string, regExp: RegExp, maxLength?: number): string {
        maxLength = maxLength ? maxLength : value.length;

        const cleanString = _.reject(value, (char: any) => {
            return !regExp.test(char);
        });

        return _.first(cleanString, maxLength!).join("");
    }
}
