import { MaterialRequisitionModel } from "api/models/company/material-requisition/material-requisition-model";
import { autoinject, bindable } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import dateHelper from "helpers/dateHelper";
import notificationHelper from "helpers/notificationHelper";
import routerHelper from "helpers/routerHelper";
import materialRequisitionService from "services/materialRequisitionService";
import { RequisitionTabs } from "enums/requisition-tabs";
import { RequisitionProcessStatus } from "api/enums/requisition-process-status";

@autoinject
export class MaterialRequisition {

    public dateHelper: typeof dateHelper = dateHelper;
    public routerHelper: typeof routerHelper = routerHelper;
    public requisitionTabs: typeof RequisitionTabs = RequisitionTabs;
    public currentTabShown: number = RequisitionTabs.Active;

    @bindable public actions: any = {};
    @bindable public isProjectResponsible: boolean = false;
    @bindable public readonly: boolean = false;

    @bindable public dispatchProjectCode: string = "";
    @bindable public dispatchId: string = "";
    @bindable public dispatchDate: string = "";
    @bindable public projectCode: string = "";

    public requisitions: MaterialRequisitionModel[] = [];
    public requisitionsCompleted: MaterialRequisitionModel[] = [];
    public readonly requisitionProcessStatus: typeof RequisitionProcessStatus = RequisitionProcessStatus;

    constructor(private readonly i18n: I18N) {
    }

    public async bind(): Promise<any> {
        await this.loadData();
    }

    public genUrl(rel: string, requisitionId: string): string {
        return routerHelper.getRelativeUrl(rel + encodeURIComponent(requisitionId)) + routerHelper.addQuerystring({ isProjectResponsible: this.isProjectResponsible, readonly: this.readonly || this.currentTabShown === RequisitionTabs.Completed, dispatchProjectCode: this.dispatchProjectCode, projectCode: this.projectCode, dispatchId: this.dispatchId });
    }

    public gotoPage(): void {
        routerHelper.navigate(routerHelper.getRelativeUrl("edit") + routerHelper.addQuerystring({ isProjectResponsible: this.isProjectResponsible, readonly: this.readonly || this.currentTabShown === RequisitionTabs.Completed, dispatchProjectCode: this.dispatchProjectCode, projectCode: this.projectCode, dispatchId: this.dispatchId }));
    }

    public deleteRequisition(requisition: MaterialRequisitionModel): void {
        notificationHelper.showDeleteConfirmation().then((success: boolean) => {
            if (success) {
                if (requisition.MobileStatus !== "0") {
                    notificationHelper.showError(this.i18n.tr("RequisitionCompletedText"));
                } else {
                    routerHelper.showLoading();
                    materialRequisitionService.delMaterialRequisition(requisition.Id!).then(() => {
                        this.loadData();
                    });
                    routerHelper.hideLoading();
                }
            }
        });
    }

    public async show(section: number): Promise<void> {
        this.currentTabShown = section;
        await this.loadData();
    }

    private async loadData(): Promise<void> {
        this.actions.getRequisitions(this.currentTabShown === RequisitionTabs.Active).then((data: MaterialRequisitionModel[]) => {
            if (this.currentTabShown === RequisitionTabs.Active) {
                this.requisitions = data;
            } else {
                this.requisitionsCompleted = data;
            }
        });
    }
}
