import { singleton, inject } from "aurelia-framework";

import { default as contactService } from "services/contactService";
import { default as dispatchService } from "services/dispatchService";
import { default as recommendationService } from "services/recommendationService";
import { default as serviceService } from "services/serviceService";
import { default as routerHelper } from "helpers/routerHelper";
import { default as queryStringHelper } from "helpers/queryStringHelper";

import { I18N } from "aurelia-i18n";
import { NotificationHelper } from "helpers/notification-helper";
import { Redirect, Router } from "aurelia-router";


@singleton()
@inject(I18N, Router, NotificationHelper)
export class RecommendationEdit {
    dispatchId = "";
    entityId;
    serviceType = "";
    recommendationId = "";
    equipmentId = "";
    readonly = false;

    fetchDefaultCustomer;
    fetchDefaultContact;
    fetchEquipments;
    fetchEquipmentDetails;
    saveRecommendation;
    isDirty = false;
    parentModel;

    constructor(i18n, router, notificationHelper) {
        this.i18n = i18n;
        this.router = router;
        this.notificationHelper = notificationHelper;
    }

    activate = function (params) {
        this.bindViewModel(params.serviceType, params.dispatchId, params.recommendationId, params.q);
    };

    canDeactivate = async function () {
        if (this.isDirty) {
            routerHelper.hideLoading(true);
            const msgWarning = this.i18n.tr("msg_UnsavedChangedWillBeLostConfirmation");
            const confirm = await this.notificationHelper.showDialogYesNo(msgWarning);
            if (!confirm) {
                return new Redirect((this.router.history).previousLocation, { trigger: false, replace: false });
            }
        }
        return true;
    };

    bindViewModel(serviceType, dispatchId, recommendationId, querystring) {
        var params = routerHelper.getQuerystring(querystring);
        this.entityId = params.entityId;
        this.serviceType = serviceType;
        this.dispatchId = dispatchId;
        this.recommendationId = recommendationId;
        this.equipmentId = params.equipmentId;

        if (params.readonly) {
            this.readonly = queryStringHelper.parseReadonly(querystring);
        }

        this.fetchDefaultCustomer = dispatchService.getClientLookupForDispatch.bind(null, dispatchId);
        this.fetchDefaultContact = contactService.getContactLookupForDispatch.bind(null, dispatchId);
        this.fetchEquipments = serviceService.getEquipments.bind(null, serviceType, dispatchId);
        this.fetchEquipmentDetails = serviceService.getEquipmentDetails.bind(null, serviceType, dispatchId);
        this.saveRecommendation = recommendationService.addRecommendationForDispatch.bind(null, dispatchId);
        this.parentModel = this;
    }
}

