// GENERATED FILE - DO NOT MODIFY
import { DocumentModel } from "api/models/company/document/document-model";
import { EmployeeModel } from "api/models/company/employee/employee-model";
import { EmployeeModelBase } from "api/models/company/employee/employee-model-base";
import { EvaluationModel } from "api/models/company/evaluation-model";
import { PayPeriodModel } from "api/models/company/pay-period-model";
import { PagingInfo } from "api/paging-info";
import BaseProxy from "api/proxies/base-proxy";
import ModelDateTransformer from "api/util/model-date-transformer";
import { autoinject } from "aurelia-framework";
import { ApiHelper } from "helpers/api-helper";
import { HttpClientFactory } from "http-client/http-client-factory";
import { HttpClientRequestConfig } from "http-client/http-client-request-config";

@autoinject()
export class EmployeeProxy extends BaseProxy {
    constructor(modelDateTransformer: ModelDateTransformer, httpClientFactory: HttpClientFactory, apiHelper: ApiHelper) {
        super(modelDateTransformer, httpClientFactory, apiHelper);
    }

    public async GetEmployeesLookup(templateCode: string | null | undefined, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<EmployeeModelBase[] | null> {
        const uri = this.buildUri(
            "/employees/lookup",
            null,
            { templateCode: (templateCode === null || templateCode === undefined) ? undefined : templateCode, filter: filter ? filter : undefined, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: EmployeeModelBase[] | null = await this.get<EmployeeModelBase[] | null>(uri, requestConfig);
        this.transformModels(data, "Company.Employee.EmployeeModelBase");
        return data;
    }

    public async GetEmployeeLookup(employeeId: number, requestConfig?: HttpClientRequestConfig): Promise<EmployeeModelBase | null> {
        const uri = this.buildUri(
            "/employees/{employeeId}/lookup",
            { employeeId: (employeeId === null || employeeId === undefined) ? undefined : employeeId.toString() },
            null
        );

        const data: EmployeeModelBase | null = await this.get<EmployeeModelBase | null>(uri, requestConfig);
        this.transformModels(data, "Company.Employee.EmployeeModelBase");
        return data;
    }

    public async GetEmployee(employeeId: number, requestConfig?: HttpClientRequestConfig): Promise<EmployeeModel | null> {
        const uri = this.buildUri(
            "/employees/{employeeId}",
            { employeeId: (employeeId === null || employeeId === undefined) ? undefined : employeeId.toString() },
            null
        );

        const data: EmployeeModel | null = await this.get<EmployeeModel | null>(uri, requestConfig);
        this.transformModels(data, "Company.Employee.EmployeeModel");
        return data;
    }

    public async GetCurrentEmployee(requestConfig?: HttpClientRequestConfig): Promise<number> {
        const uri = this.buildUri(
            "/employee/current",
            null,
            null
        );

        const data: number = await this.get<number>(uri, requestConfig);
        return data;
    }

    public async Post(employeeId: number, model: DocumentModel | null, requestConfig?: HttpClientRequestConfig): Promise<boolean> {
        const uri = this.buildUri(
            "/employees/{employeeId}/documents",
            { employeeId: (employeeId === null || employeeId === undefined) ? undefined : employeeId.toString() },
            null
        );

        const data: boolean = await this.post<DocumentModel | null, boolean>(uri, model, requestConfig);
        return data;
    }

    public async GetEmployeeEvaluations(evaluationDate: Date, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<EvaluationModel[] | null> {
        const uri = this.buildUri(
            "/employees/evaluation",
            null,
            { evaluationDate: (evaluationDate === null || evaluationDate === undefined) ? undefined : evaluationDate.toString(), filter: filter ? filter : undefined, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: EvaluationModel[] | null = await this.get<EvaluationModel[] | null>(uri, requestConfig);
        this.transformModels(data, "Company.EvaluationModel");
        return data;
    }

    public async SaveEmployeeEvaluations(dispatchProjectCode: string | null, dispatchDate: Date, evaluations: Array<EvaluationModel | null> | null, requestConfig?: HttpClientRequestConfig): Promise<void> {
        const uri = this.buildUri(
            "/employees/evaluation/projects/{dispatchProjectCode}/dispatchdates/{dispatchDate}",
            { dispatchProjectCode: (dispatchProjectCode === null || dispatchProjectCode === undefined) ? undefined : dispatchProjectCode, dispatchDate: (dispatchDate === null || dispatchDate === undefined) ? undefined : dispatchDate.toString() },
            null
        );

        await this.post<Array<EvaluationModel | null> | null, void>(uri, evaluations, requestConfig);
    }

    public async GetCurrentEmployeePayPeriods(maxStartDateTime: Date | null | undefined, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<PayPeriodModel[] | null> {
        const uri = this.buildUri(
            "/employees/current/payperiods",
            null,
            { maxStartDateTime: (maxStartDateTime === null || maxStartDateTime === undefined) ? undefined : maxStartDateTime.toString(), filter: filter ? filter : undefined, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: PayPeriodModel[] | null = await this.get<PayPeriodModel[] | null>(uri, requestConfig);
        this.transformModels(data, "Company.PayPeriodModel");
        return data;
    }

    public async GetTechnicians(filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<EmployeeModelBase[] | null> {
        const uri = this.buildUri(
            "/technicians/lookup",
            null,
            { filter: filter ? filter : undefined, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: EmployeeModelBase[] | null = await this.get<EmployeeModelBase[] | null>(uri, requestConfig);
        this.transformModels(data, "Company.Employee.EmployeeModelBase");
        return data;
    }

    public async IsHavingElectronicSignature(requestConfig?: HttpClientRequestConfig): Promise<boolean> {
        const uri = this.buildUri(
            "/employees/current/is-having-electronic-signature",
            null,
            null
        );

        const data: boolean = await this.get<boolean>(uri, requestConfig);
        return data;
    }
}
