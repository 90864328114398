import { ContractSearchAddressType } from "api/enums/contract-search-address-type";
import { ContractDetailModel } from "api/models/company/service/contract-detail-model";
import { ContractDetailSearchModel } from "api/models/company/service/contract-detail-search-model";
import { PagingInfo } from "api/paging-info";
import { ServiceCallContractProxy } from "api/proxies/service-call-contract-proxy";
import { autoinject } from "aurelia-framework";
import { HttpClientRequestConfig } from "http-client/http-client-request-config";

@autoinject()
export class ServiceCallContractService {
    constructor(private readonly serviceCallContractProxy: ServiceCallContractProxy) {
    }

    public async GetContracts(
        type: ContractSearchAddressType | undefined,
        statusToExclude: string[] | undefined,
        contractTypes: string[] | undefined,
        contractTypesToExclude: string[] | undefined,
        currentPrefixOnly: boolean,
        filter: string | null,
        pagingInfo: PagingInfo | null,
        httpClientRequestConfig: HttpClientRequestConfig
    ): Promise<ContractDetailSearchModel[]> {
        return (
            (await this.serviceCallContractProxy.Get(
                type,
                statusToExclude,
                contractTypes,
                contractTypesToExclude,
                currentPrefixOnly,
                filter ? filter : undefined,
                pagingInfo ? pagingInfo : undefined,
                httpClientRequestConfig
            )) || []
        );
    }

    public async GetContract(contractNumber: string | null): Promise<ContractDetailModel | null> {
        return await this.serviceCallContractProxy.GetServiceContract(contractNumber);
    }

    public async pendingContractCanCreateServiceCall(contractNumber: string | null): Promise<boolean> {
        return await this.serviceCallContractProxy.PendingContractCanCreateServiceCall(contractNumber);
    }
}
