import { inject, observable } from "aurelia-framework";
import { I18N } from "aurelia-i18n";

import _ from "underscore";

import { CompanyService } from "services/company-service";
import { default as templateService } from "services/templateService";

import routerHelper from "helpers/routerHelper";
import { NotificationHelper } from "helpers/notification-helper";
import { LocalizationHelper } from "helpers/localization-helper";
import LoggerHelper from "helpers/logger-helper";
import { SettingRepository } from "repositories/setting-repository";
import UserSettingsService from "services/userSettingsService";
import { Redirect } from "aurelia-router";
import UserOptionsManager from "services/user-options-manager";

@inject(SettingRepository, CompanyService, NotificationHelper, LocalizationHelper, I18N, UserSettingsService, UserOptionsManager)
export class Settings {
    companies = [];
    @observable selectedCompany = null;

    selectedLanguage = null;
    selectedUnitSystem = null;

    dispatchTemplates = [];
    selectedDispatchTemplate = null;
    companyUserOptions = null;

    constructor(settingRepository, companyService, notificationHelper, localizationHelper, i18n, userSettingsService, userOptionsManager) {
        this.settingRepository = settingRepository;
        this.companyService = companyService;
        this.notificationHelper = notificationHelper;
        this.localizationHelper = localizationHelper;
        this.i18n = i18n;
        this.userSettingsService = userSettingsService;
        this.userOptionsManager = userOptionsManager;
    }

    async canActivate() {
        if (this.settingRepository.getCompany() === null) {
            await this.initUserAccessType();

            var result = await this.initSavedMobileUserSettings();
            if (result) {
                return new Redirect("");
            }
        }
    }

    async activate() {
        this.initUnitSystems();
        this.initUsername();
        this.initVersionForDisplay();
        this.initLanguages();

        try {
            await this.initCompanies();
            await this.initDispatchTemplates();
            this.selectDispatchTempate();
        } catch (error) {
            // Activation successful even if the dropdown didn't load.
            // We should refactor this into something more elegant.
            LoggerHelper.throwOrLog(error);
        }
    }

    async saveSettings() {
        if (!this.validateSettings()) {
            return;
        }

        this.settingRepository.setCompany(this.selectedCompany.tag);
        this.settingRepository.setCompanyName(this.companies.find(company => company.tag === this.selectedCompany.tag).text);

        this.initPrefix(this.selectedCompany.id);

        this.settingRepository.setLanguage(this.selectedLanguage.id);

        if (this.selectedUnitSystem) {
            this.settingRepository.setUnitSystem(this.selectedUnitSystem.id);
        } else {
            this.settingRepository.setUnitSystem(null);
        }

        if (this.selectedDispatchTemplate) {
            this.settingRepository.setDispatchTemplate(this.selectedDispatchTemplate.id);
        } else {
            this.settingRepository.setDispatchTemplate(null);
        }

        await this.userSettingsService.setMobileUserSettings({
            CompanyId: this.selectedCompany.id,
            DispatchTemplateCode: this.selectedDispatchTemplate ? this.selectedDispatchTemplate.id : null,
            UnitId: this.selectedUnitSystem ? this.selectedUnitSystem.id : null,
            LanguageId: this.selectedLanguage.id,
        });

        this.localizationHelper.setLocale(this.settingRepository.getLanguage());
        this.refreshApp();
    }

    validateSettings() {
        if (!this.selectedCompany) {
            this.notificationHelper.showError(this.i18n.tr("err_CompanySelectionRequired"));
            return false;
        }
        return true;
    }

    refreshApp() {
        routerHelper.navigateToRoute("Home");
        window.location.reload(false);
    }

    async selectedCompanyChanged(newValue, oldValue) {
        await this.initDispatchTemplates();
        this.selectDispatchTempate();
        // this.companyUserOptions = await this.userOptionsService.getUserOptions(newValue.tag).promise();
        // this.initSelectedSectionsList();
    }

    initCompanies() {
        // TODO JL: Refaire cette methode
        return this.companyService.getAll().then(
            data => {
                let list = _.map(data, function(item) {
                    return { id: item.Id, tag: item.Tag, text: item.Name };
                });
                this.companies = list;
                let selectedCompanyTag = this.settingRepository.getCompany();
                if (selectedCompanyTag) {
                    this.selectedCompany = this.companies.find(company => company.tag === selectedCompanyTag);
                }
                return Promise.resolve();
            },
            reason => {
                this.companies = [];
                return Promise.reject(new Error(this.i18n.tr("err_CompanyNotFound")));
            }
        );
    }

    initLanguages() {
        this.languages = this.settingRepository.getLanguages();
        let selectedLanguage = this.settingRepository.getLanguage();
        if (selectedLanguage) {
            this.selectedLanguage = this.languages.find(language => language.id === selectedLanguage);
        }
    }

    initUnitSystems() {
        this.unitSystems = this.settingRepository.getUnitSystems();
        let selectedUnitSystem = this.settingRepository.getUnitSystem();
        if (selectedUnitSystem) {
            this.selectedUnitSystem = this.unitSystems.find(unitSystem => unitSystem.id === selectedUnitSystem);
        }
    }

    initDispatchTemplates() {
        // TODO JL: Refaire cette methode
        this.dispatchTemplates = [];
        if (this.selectedCompany) {
            return templateService.listByCompany(this.selectedCompany.tag).then(
                data => {
                    let list = _.map(data, function (item) {
                        return { id: item.Code, text: item.Code + (item.Description ? " - " + item.Description : "") };
                    });
                    this.dispatchTemplates = list;
                    return Promise.resolve();
                },
                reason => {
                    this.dispatchTemplates = [];
                    return Promise.reject(new Error(`${this.i18n.tr("err_DispatchNotFound")} ${this.selectedCompany.id}`));
                }
            );
        } else {

            return Promise.resolve();
        }
    }

    selectDispatchTempate() {
        let selectedDispatchTemplate = this.settingRepository.getDispatchTemplate();
        if (selectedDispatchTemplate !== null && selectedDispatchTemplate !== undefined) {
            this.selectedDispatchTemplate = this.dispatchTemplates.find(dispatchTemplate => dispatchTemplate.id === selectedDispatchTemplate);
        }
    }

    initUsername() {
        this.username = this.settingRepository.getUser();
    }

    initVersionForDisplay() {
        this.versionForDisplay = this.settingRepository.getVersionForDisplay();
    }

    async initUserAccessType() {
        var result = await this.userSettingsService.getUserAccessType(this.settingRepository.getUser());
        this.settingRepository.setUserAccessType(result);
    }

    async initPrefix(companyId) {
        var result = await this.userSettingsService.getPrefix(companyId);
        this.settingRepository.setPrefix(result);
    }

    async initSavedMobileUserSettings() {
        var result = await this.userSettingsService.getMobileUserSettings(this.settingRepository.getUser());
        if (result !== null) {
            this.settingRepository.setCompany(result.Company.Tag);
            this.settingRepository.setCompanyName(result.Company.Name);

            this.settingRepository.setPrefix(result.Company.Prefix);

            if (result.DispatchTemplate) {
                this.settingRepository.setDispatchTemplate(result.DispatchTemplate.Code);
            }

            this.settingRepository.setLanguage(result.LanguageId);
            this.settingRepository.setUnitSystem(result.UnitId);

            this.localizationHelper.setLocale(this.settingRepository.getLanguage());

            await this.userOptionsManager.refreshAppContext();

            return true;
        }

        return false;
    }

    saveMobileUserSettings() {
        this.userSettingsService.setMobileUserSettings();
    }
}
