define([], function () {
    "use strict";

    var exports = {
        endsWith: function (value, searchString) {
            //minus sign in sustr function is for -1 * of the length
            return value.substr(-searchString.length) === searchString;
        },

        startsWith: function (value, searchString) {
            return value.substr(0, searchString.length) === searchString;
        },

        pad: function(value) {
            return (value < 10) ? ("0" + value) : value;
        },

        trimLeft: function(value, charlist) {
            if (charlist === undefined)
                charlist = "\s";

            return value.replace(new RegExp("^[" + charlist + "]+"), "");
        },

        trimRight: function(value, charlist) {
            if (charlist === undefined)
                charlist = "\s";

            return value.replace(new RegExp("[" + charlist + "]+$"), "");
        },

        trim: function(value, charlist) {
            return this.trimRight(this.trimLeft(value, charlist), charlist);
        }
    };

    return exports;
});
