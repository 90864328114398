import { inject, bindable, noView, DOM } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import routerHelper from "helpers/routerHelper";
import RouteRepository from "repositories/routeRepository";
import employeeService from "services/employeeService";
import moment from "moment";

import { FullCalendarLazyLoader } from "helpers/full-calendar-lazy-loader";

import { SettingRepository } from "repositories/setting-repository";

import { default as AssignmentService } from "services/assignment-service";

@inject(Element, I18N, SettingRepository, FullCalendarLazyLoader, AssignmentService, RouteRepository)
export class CalendarCustomElement {
    // Si on place un espace après la virgule les bouttons n'apparaissent plus dans le même groupe.
    headerButtons = {
        left: "prev,today,next, addUnavailability",
        center: "title",
        right: "none"
    };
    editable = false;

    startTime = null;
    endTime = null;

    @bindable getEvents;

    constructor(element, i18n, settingRepository, fullCalendarLazyLoader, assignmentService, routeRepository) {
        this.element = element;
        this.i18n = i18n;
        this.settingRepository = settingRepository;
        this.fullCalendarLazyLoader = fullCalendarLazyLoader;
        this.assignmentService = assignmentService;
        this.routeRepository = routeRepository;
    }

    async attached() {
        await this.fullCalendarLazyLoader.loadModule();
        const defaultView = await this.assignmentService.getCalendarViewSetting();
        this.initCalendar(defaultView);
    }

    initCalendar(defaultView) {
        const headerHeight = 100;
        jQuery("#calendar").fullCalendar({
            height: () => window.innerHeight - headerHeight,
            locale: this.settingRepository.getFourCharacterCultureCode(),
            allDayHtml: " ",
            noEventsMessage: this.i18n.tr("lstNoData_Events"),
            defaultView: defaultView,
            selectable: true,

            firstDay: 0, // Sunday
            customButtons: {
                addUnavailability: {
                  text: this.i18n.tr('AddAUnavailability'),
                  click: async() => await this.addUnavailability()
                }
            },
            header: this.headerButtons,
            footer: false,
            displayEventTime: true,
            editable: this.editable,
            events: async(start, end, timezone, callback) => await this.getEvents({ start: start, end: end, timezone: timezone, callback: callback }),
            eventClick: (calEvent, jsEvent, view) => this.onEventClick(calEvent, jsEvent, view),
            eventLimit: true, // allow "more" link when too many events
            eventLimitClick: "agendaDay",
            dayClick: (date, jsEvent, view) => this.onDayClick(date, jsEvent, view),
            select: (startDate, endDate) => this.onSelect(startDate, endDate),
            views: {
                month: {
                    // Hide event time to save space in month view
                    displayEventTime: false,
                },
                agendaDay: {  },
                listMonth: {  displayEventTime: true },
                listDay: { },
            },
            columnHeaderFormat : "ddd",
            slotLabelFormat : "LT",
            timeFormat: "LT",

            themeSystem: 'bootstrap4',
            bootstrapFontAwesome : {
                prev: 'fa-chevron-left',
                next: 'fa-chevron-right',
                month: " fa-calendar",
                agendaDay: " fa-sun-o",
                listMonth: " fa-list-ul",
            }

        });
    }

    onSelect(startDate, endDate) {
        this.startTime = startDate;
        this.endTime = endDate;
    }

    changeView(view, date) {
        jQuery("#calendar").fullCalendar("changeView", view, date);
    }

    buttonViewEventClick(view) {
        this.changeView(view);
        this.assignmentService.saveCalendarViewSetting(view);
    }

    onEventClick(calEvent, jsEvent, view) {
        if (view.name === "month") {
            this.changeView("agendaDay", calEvent.start);
        } else {
            var select = DOM.createCustomEvent('event-clicked', { detail: calEvent, view: view});
            this.element.dispatchEvent(select);
        }
    }

    onDayClick(date, jsEvent, view) {
        this.changeView("agendaDay", date.format());
    }

    async addUnavailability() {
        const employeeId = await employeeService.getCurrentEmployeeId();

        const params = {
            employeeId: employeeId,
            startTime: this.startTime === null ? moment().format() : this.startTime.format(),
            endTime:  this.endTime === null ? moment().format() : this.endTime.format(),
        }
        routerHelper.navigateToRoute(this.routeRepository.routes.Calendar_AddUnavailability.name, params)
    }

    genUrl(route, param) {
        var relativeUrl = '';

        if (param !== undefined) {
            relativeUrl = routerHelper.getRelativeUrl(route, param);
        } else {
            relativeUrl = routerHelper.getRelativeUrl(route);
        }

        return relativeUrl;
    }
}