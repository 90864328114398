import { autoinject } from "aurelia-framework";
import { AdditionalFieldsSourceType } from "enums/additional-fields-source-type";

import additionalFieldsService from "services/additionalFieldsService";
import routerHelper from "helpers/routerHelper";

@autoinject()
export class ProjectDailyEntryMaterialOrderAdditonnalFields {
    public actions: any = {};
    public dispatchProjectCode: number = 0;
    public dispatchDate: string = "";
    public orderId: string = "";
    public readonly: boolean = true;
    public extension: string = "";
    public additionalFieldsSource: number = AdditionalFieldsSourceType.PurchaseOrder;
    public saveAdditionalFieldsButton: boolean = false;

    public activate(params: any): any {
        this.bindViewModel(params.dispatchProjectCode, params.dailyEntryDate, params.orderId, params.q);
        this.bindWidget();
    }

    private bindViewModel(dispatchProjectCode: number, dailyEntryDate: string, orderId: string, queryString: string): void {
        const params = routerHelper.getQuerystring(queryString);
        this.readonly = !(params && params.readonly === "false");
        this.extension = params.extension;

        this.saveAdditionalFieldsButton = !this.readonly;
        this.dispatchProjectCode = dispatchProjectCode;
        this.dispatchDate = dailyEntryDate;
        this.orderId = orderId;
    }

    private bindWidget(): void {
        this.actions = {
            getAll: additionalFieldsService.getAllForPurchaseOrder.bind(this, this.orderId, this.extension),
            setAll: additionalFieldsService.setAllForPurchaseOrder.bind(this, this.orderId, this.extension)
        };
    }
}
