import { EventAggregator } from "aurelia-event-aggregator";
import { inject } from "aurelia-framework";
import { Redirect } from "aurelia-router";
import { ValidationRules, ValidationControllerFactory, validateTrigger } from "aurelia-validation";
import { I18N } from "aurelia-i18n";

import { default as jQuery } from "jquery";
import { default as _ } from "underscore";
import { default as val } from "core/val";
import { default as settingHelper } from "helpers/settingHelper";
import { default as dateHelper } from "helpers/dateHelper";
import { default as queryStringHelper } from "helpers/queryStringHelper";
import { default as routerHelper } from "helpers/routerHelper";
import { default as labelHelper } from "helpers/labelHelper";
import { default as defaultValues } from "services/defaultService";
import { default as commonService } from "services/commonService";
import { default as bonusService } from "services/bonusService";
import { default as serviceService } from "services/serviceService";
import { default as workorderTimesheetService } from "services/workorderTimesheetService";
import { default as wageSettingHelper } from "helpers/wageSettingHelper";
import { default as templateService } from "services/templateService";
import { WorkOrderActivityType } from "api/enums/work-order-activity-type"

import { NotificationHelper } from "helpers/notification-helper";
import { Router } from "aurelia-router";
import { FormBase } from "pages/form-base";

@inject(EventAggregator, ValidationControllerFactory, I18N, NotificationHelper, Router)
export default class HourEdit extends FormBase {
    eventAggregator;
    validationController;
    i18n;
    readonly = false;
    workOrderId;
    editId = null;
    shiftList = [];
    shift = { id: null };
    wageList = [];
    wageType;
    bonusConfigs = [];
    selectedResources = [];
    activity;
    comment;
    commentMaxLength;
    workTime;
    timestamp = "";
    increment = -1;
    message = "";
    activities = [];
    unModifiedTimeSheet = null;
    subscription: { dispose: () => void };

    constructor(eventAggregator, validationControllerFactory, i18n, notificationHelper, router) {
        super(notificationHelper, i18n, router);
        this.eventAggregator = eventAggregator;
        this.validationController = validationControllerFactory.createForCurrentScope();
        this.validationController.validateTrigger = validateTrigger.manual;

        this.commentMaxLength = val.get("fieldService.hours.comments", "maxLength");

        this.lookupActivity = {
            transport: (params, success, failure) => {
                serviceService
                    .getWorkorderActivitiesByTypes(this.workOrderId, "Labour", params.data.filter, params.data.page || 1)
                    .done(success)
                    .fail(failure);
            },
        };
    }

    clear() {
        this.wageType = 0;
        this.comment = "";
        this.activity = null;
        this.shift = { id: +defaultValues.getFieldServiceLastShiftUsed() || (this.shiftList.length === 0 ? 0 : undefined) };
        this.workTime = this.emptyTime();
    }

    initValidation() {
        ValidationRules
        .ensure(m => m.workTime)
            .satisfies(() => this.validateWorkTime())
            .when(() => !this.hasBonusSelected() && this.selectedResourcesContainsEmployee())
            .withMessageKey("err_TimeSpentOrBonusRequired")
        .ensure(m => m.workTime)
            .satisfies(() => this.validateWorkTime())
            .when(() => !this.selectedResourcesContainsEmployee())
            .withMessageKey("err_TimeSpentRequired")
            .ensure(m => m.shift)
                .satisfies(v => this.validateShift(v))
                .withMessageKey("err_ShiftRequired")
            .ensure(m => m.activity)
                .satisfies(v => this.validateActivity(v))
                .withMessageKey("err_ActivityRequired")
        .on(this);
    }

    hasBonusSelected() {
        return _.some(_.pluck(this.bonusConfigs, "value"));
    }

    validateWorkTime() {
        if (!this.isWorkTime() && this.isEmployeeFieldsValue()) {
            return false;
        }
        return true;
    }

    validateShift(value) {
        if ((!value || value.id === undefined || value.id === null) && this.isWorkTime()) {
            // Shift.id can be 0;
            return false;
        }
        return true;
    }

    validateActivity(value) {
        if (!value && this.isWorkTime()) {
            return false;
        }
        return true;
    }

    validateEmployeeSelected(value) {
        if (!value.length && (this.isWorkTime() || this.activity || this.comment)) {
            return false;
        }
        return true;
    }

    validateTimeQuantityBonuses() {
        if (this.isWorkTime()) {
            return true;
        }
        return this.getTimeQuantityBonuses().length === 0;
    }

    getTimeQuantityBonuses() {
        return _.filter(this.bonusConfigs, bonus => bonus.type === 3 && bonus.value);
    }

    selectedResourcesContainsEmployee() {
        return _.filter(this.selectedResources, res => res.isEmployee).length > 0;
    }

    selectedResourcesContainsEquipment() {
        return _.filter(this.selectedResources, res => !res.isEmployee).length > 0;
    }

    isWorkTime() {
        return dateHelper.getDifference(this.workTime, this.emptyTime(), "minutes") > 0;
    }

    isEmployeeFieldsValue() {
        return this.activity || this.comment || this.selectedResources.length;
    }
    //#region Public Functions
    canActivate() {
        if (!settingHelper.hasDispatchModel()) {
            this.notificationHelper.showWarning(this.i18n.tr("DispatchModelRequired"),"");
            return new Redirect("Settings");
        }
        return true;
    }

    async activate(params) {
        this.subscription = this.eventAggregator.subscribe('router:navigation:complete', () => {
            this.unModifiedTimeSheet = this.getTimeSheetToSend();
            this.subscription.dispose();
        });

        this.increment = await templateService.getUserTemplateIncrement();

        this.bindViewModel(decodeURIComponent(params.workOrderId), params.id, params.q);
        this.clear();
        this.updateTitle();

        this.activities = await serviceService.getWorkorderActivitiesByTypes(this.workOrderId, WorkOrderActivityType[WorkOrderActivityType.Labour]);

        this.activity = this.getDefaultActivity();

        return await this.loadData().promise();
    }

    checkDirty() {
        if (this.readonly) {
            return false;
        }

        if (!this.unModifiedTimeSheet) {
            return false;
        }

        const stringifyUnmodified = JSON.stringify(this.unModifiedTimeSheet).replace(/[^0-9A-Z]+/gi, "");
        const stringifyCurrent = JSON.stringify(this.getTimeSheetToSend()).replace(/[^0-9A-Z]+/gi, "");

        return stringifyUnmodified !== stringifyCurrent;
    }

    emptyTime() {
        return dateHelper.getTime(dateHelper.getDate());
    }

    getDefaultActivity() {
        return this.allEmployeesSameDefaultLaborActivity(this.selectedResources);
    }

    allEmployeesSameDefaultLaborActivity(employees) {
        if (employees.length === 0) {
            return null;
        }

        const sameActivity = employees.every(function (entry) {
            return entry.DefaultLaborActivityCode === employees[0].DefaultLaborActivityCode;
        });

        if (!sameActivity) { return null; }

        return this.createDefaultMaSelectElement(employees[0].DefaultLaborActivityCode, this.activities);
    }

    createDefaultMaSelectElement (id, list) {
        if (!id) { return null; }

        const item = list.find((item) => item.Id === id);
        if (!item) {
            return null
        }

        return { id: item.Id, text: item.Id + " - " + item.Description };
    }

    getTimeSheetToSend() {
        return {
            Timesheets: this.getTimeSheets()
        };
    }

    save() {
        if (!this.validateEmployeeSelected(this.selectedResources)) {
            this.notificationHelper.showValidationError([this.i18n.tr("err_NoEmployeeSelected")]);
            return;
        }

        if (!this.validateTimeQuantityBonuses()) {
            var bonuses = _.map(this.getTimeQuantityBonuses(), b => {
                return this.i18n.tr("err_TimeSpentWithBonusHoursQty") + " " + b.description;
            });
            this.notificationHelper.showValidationError(bonuses);
            return;
        }

        this.validationController.validate().then(result => {
            if (!result.valid) {
                const error = _.pluck(_.filter(result.results, res => !res.valid), "message");
                this.notificationHelper.showValidationError(error);
                return;
            }
            
            routerHelper.showLoading();

            var data = this.getTimeSheetToSend();

            workorderTimesheetService
                .setWorkorderTimeEntry(this.workOrderId, data)
                .done(() => {
                    this.unModifiedTimeSheet = null;
                    defaultValues.setFieldServiceLastShiftUsed(this.shift.id);
                    routerHelper.navigateBack();
                })
                .concurrence(this.loadData)
                .always(() => {
                    routerHelper.hideLoading();
                });
        });
    }

    //#endregion Public Functions
    //#region Private Functions
    bindData(loadedData) {
        var date = dateHelper.getDate();

        if (loadedData.ActivityCode) {
            this.activity = {
                id: loadedData.ActivityCode,
                text: loadedData.ActivityCode + " - " + loadedData.ActivityDesc,
            };
        }
        if (loadedData.EquipmentActivityCode) {
            this.activity = {
                id: loadedData.EquipmentActivityCode,
                text: loadedData.EquipmentActivityCode + " - " + loadedData.EquipmentActivityDesc,
            };
        }

        this.comment = loadedData.Comment;
        this.shift = { id: loadedData.Shift };
        this.wageType = loadedData.WageType;
        this.timestamp = loadedData.Timestamp;

        var wTime = loadedData.ActivityCode ? loadedData.WorkTime : loadedData.EquipmentWorkTime;
        this.workTime = dateHelper.getTime(date.setMinutes(wTime * 60, 0, 0));

        this.initBonusValueWorkOrder(loadedData);
    }

    getKeyValueFromJson(json, key) {
        return JSON.parse(decodeURIComponent(json[key]));
    }

    getSelectedEmployeeFromJson(json) {
        var selectedResources = this.getKeyValueFromJson(json, "selectedResources");
        return _.sortBy(selectedResources, emp => emp.id);
    }

    getQuerystringData(querystring) {
        var json = routerHelper.getQuerystring(querystring);
        return this.getSelectedEmployeeFromJson(json);
    }

    bindViewModel(workOrderId, editId, querystring) {
        this.workOrderId = workOrderId;
        this.editId = editId;
        this.readonly = queryStringHelper.parseReadonly(querystring);
        wageSettingHelper.getDispatchTemplateConfigs("FieldService", null).done(templateConfigs => {
            var availableWages = wageSettingHelper.getWageFromTemplateconfigs("FieldService", templateConfigs);
            this.wageList = _.map(availableWages, value => {
                return { id: value, text: labelHelper.getShortWageTypeLabel(value) };
            });
        });
        this.selectedResources = this.getQuerystringData(querystring);

        if (this.selectedResources.length > 1) {
            this.message = this.i18n.tr("msg_DailyEntry_EnteringTimeForXEmployeesSelected").replace("{0}", this.selectedResources.length);
        } else {
            this.message = this.selectedResources[0].name;
        }
    }

    initBonusValueWorkOrder(data) {
        _.each(data.Bonus, b => {
            var bonusCfg = _.find(this.bonusConfigs, bCfg => bCfg.code === b.Code);
            if (bonusCfg.type === 1) {
                bonusCfg.value = b.Value;
            } else {
                bonusCfg.value = b.Value === 1;
            }
            bonusCfg.id = b.Id;
        });
    }

    loadEntry() {
        if (this.editId) {
            return workorderTimesheetService.getByWorkOrderIdAndLineNo(this.workOrderId, this.editId).done(loadedData => {
                this.bindData(loadedData);
                this.initValidation();
            });
        } else {
            this.initValidation();
            return new jQuery.Deferred().resolve();
        }
    }

    loadShifts() {
        if (!defaultValues.getFieldServiceLastShiftUsed()) {
            serviceService.getLastShiftUsed("W").done(value => {
                defaultValues.setFieldServiceLastShiftUsed(value);
            });
        }

        return commonService.getShifts().done(data => {
            var shifList = _.map(data, shift => {
                return { id: shift.Id, text: shift.Id + " - " + shift.Description };
            });
            this.shiftList = shifList;
        });
    }

    loadBonusConfig() {
        return bonusService.getBonusConfigs().done(value => {
            var list = _.map(value, data => {
                return {
                    id: 0,
                    value: null,
                    code: data.Code,
                    type: data.ColumnType,
                    description: data.Description,
                    unit: data.Unit,
                };
            });
            this.bonusConfigs = list;
        });
    }

    loadData() {
        return jQuery.when(this.loadShifts(), this.loadBonusConfig(), this.loadEntry());
    }

    createServerDto(resource, workTime, currentBonus) {
        if (resource.isEmployee) {
            return {
                Employee: { Id: resource.id },
                LineNo: this.editId || 0,
                Shift: this.shift.id + "",
                Bonus: currentBonus,
                Timestamp: this.editId ? this.timestamp : undefined,
                WageType: this.wageType || 0,
                WorkTime: workTime,
                Comment: this.comment,
                ActivityCode: (this.activity ? this.activity.id : "")
            };
        } else {
            return {
                Employee: null,
                LineNo: this.editId || 0,
                Shift: this.shift.id + "",
                Bonus: [],
                Timestamp: this.editId ? this.timestamp : undefined,
                EquipmentCode: resource.id,
                EquipmentActivityCode: this.activity ? this.activity.id : "",
                EquipmentWorkTime: workTime,
            };
        }
    }

    getCurrentBonus() {
        return _.map(this.bonusConfigs, data => {
            data.value = data.type === 1 ? data.value : data.value ? 1 : 0;
            return data;
        });
    }

    getTimeSheets() {
        var currentBonus = this.getCurrentBonus();
        var workTime = dateHelper.getDifference(this.workTime, this.emptyTime(), "minutes") / 60;
        var timeSheets = [];

        if (this.selectedResources.length) {
            
            timeSheets = _.map(this.selectedResources, resource => {
                return this.createServerDto(resource, workTime, currentBonus);
            });
        }

        return timeSheets;
    }

    updateTitle() {
        if (this.selectedResources.length > 1) {
            this.eventAggregator.publish("updateTitle", this.i18n.tr("pageTitle_FieldService_Hour_Edit_multipleSelected"));
        } else {
            this.eventAggregator.publish("updateTitle", this.i18n.tr("pageTitle_FieldService_Hour_Edit_singleSelected"));
        }
    }
    //#endregion Private Functions
}
