import { autoinject, Container, elementConfig } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import { AgGridHelper } from "helpers/ag-grid-helper";

import { ProcoreCompanyConfigurationModel } from "api/models/company/procore/procore-company-configuration-model";
import { ProcoreSynchronizationDirection } from "api/enums/procore-synchronization-direction";
import { ProcoreEntityToSynchronize } from "api/enums/procore-entity-to-synchronize";
import { ProcoreEntityToSynchronizeModel } from "api/models/company/procore/procore-entity-to-synchronize-model";
import { DateFormatValueConverter } from "converters/format/date-format";
import { ProcoreConfigurationService } from "services/procore/procore-configuration-service";
import { ProcoreOrderType } from "api/enums/procore-order-type";
import { ProcoreConfiguration } from "../configuration";
import { ProcoreEntityToSynchronizeContentModel } from "models/procore/procore-entities-to-synchronize-content-model";
import { ProcoreJobStatus } from "api/models/procore/job_-system/procore-job-status";

@autoinject()
export class ConfigurationJobSystem {
  public entitiesGrid: HTMLElement | null = null;
  public jobs: Array<ProcoreJobStatusWithDetails | null> | null = [];

  private self: ConfigurationJobSystem = this;

  private gridOptions: any;

  constructor(private readonly i18n: I18N) { }

  public Load(data: ProcoreJobStatus[] | null): void {
    if (data) {
      this.jobs = data.map((a: ProcoreJobStatus) => new ProcoreJobStatusWithDetails(a));
    }
  }

  public async toggleDetails(item: ProcoreJobStatusWithDetails, event: any): Promise<void> {
    item.DetailsOpen = !item.DetailsOpen;
  }

  public stringify(items: any[]): string {
      const element = new Array();
      for (let i = 0; i < items.length; i++) {
          element[i] = items[i];
      }
      return JSON.stringify(element);
  }
}

export class ProcoreJobStatusWithDetails {
    public DetailsOpen: boolean;
    public Payload: ProcoreJobStatus;

    constructor(payload: ProcoreJobStatus) {
      payload.ResultPayload = this.FormatPayloadResult(payload.ResultPayload);
      
      this.Payload = payload;
      this.DetailsOpen = false;
    }

    private FormatPayloadResult(result: string | null): string {
      if (!result) { return ""; }

      const x = JSON.parse(result);

      return JSON.stringify(x, undefined, "&#13;\t");
    }
}
