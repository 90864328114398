import { ShopDrawingService } from "services/shop-drawing-service";
import RouteRepository from "repositories/routeRepository";
import { I18N } from "aurelia-i18n";
import { autoinject } from "aurelia-framework";
import routerHelper from "helpers/routerHelper";
import { nameof as nameof_ShopDrawingStatus } from "api/enums/shop-drawing-status";
import { EnumFormatValueConverter } from "converters/enums/enum-format";
import { ShopDrawingLightModel } from "api/models/company/shop-drawing-light-model";
import { MaCardLineModel, MaCardOptionsModel, MaCardActionModel } from "components/layout/ma-card/ma-card-options.model";
import { DispatchProjectService } from "services/dispatch-project-service";
import notificationHelper from "helpers/notificationHelper";

@autoinject
export class ShopDrawings {
    public dispatchProjectCode: string = "";
    public shopDrawingCards: MaCardOptionsModel[] = [];
    public nameof_ShopDrawingStatus: string = nameof_ShopDrawingStatus;

    constructor(private readonly i18n: I18N, private readonly shopDrawingService: ShopDrawingService, private routeRepository: RouteRepository, private readonly enumFormatValueConverter: EnumFormatValueConverter, private readonly dispatchProjectService: DispatchProjectService) {
    }

    public async canActivate(params: any): Promise<any> {
        this.dispatchProjectCode = params.dispatchProjectCode;

        try {
            await this.checkIsProjectActive();
        } catch (e) {
            if (e && e.response && e.response.data && e.response.data.Exception && e.response.data.Exception.ErrorId) {
                notificationHelper.showError(this.i18n.tr(`ApiError.${e.response.data.Exception.ErrorId}`), undefined, { timeOut: 0, extendedTimeOut: 0 });
            } else {
                notificationHelper.showError(e.response.data.Exception.Message, undefined, { timeOut: 0, extendedTimeOut: 0 });
            }
            return false;
        }
        return true;
    }

    public activate(params: any): void {
        this.dispatchProjectCode = params.dispatchProjectCode;
        this.shopDrawingService.getShopDrawings(this.dispatchProjectCode).then((result: ShopDrawingLightModel[]): any => {
            result.forEach((item: ShopDrawingLightModel) => {
                this.shopDrawingCards.push(this.createCard(item));
            });
        });
    }

    public edit(item: ShopDrawingLightModel): void {
        routerHelper.navigateToRoute(this.routeRepository.routes.Common_Detail_ShopDrawings_Edit.name, { dispatchProjectCode: this.dispatchProjectCode, drawingId: item.Id });
    }

    public createCard(item: ShopDrawingLightModel): MaCardOptionsModel {
        const card = new MaCardOptionsModel({
            model: item,
            class: "ma-card-link",
            action: this.edit.bind(this, item),
            displayLines: [
                new MaCardLineModel({ display: this.getShopDrawingDisplay(item), class: "text-primary font-weight-bold" }),
                new MaCardLineModel({ display: this.getShopDrawingCounterDisplay(item) }),
                new MaCardLineModel({ display: this.getShopDrawingStatusDisplay(item), class: "font-weight - bold" }),
                new MaCardLineModel({ display: this.getShopDrawingContactDisplay(item) }),
                new MaCardLineModel({ display: this.getShopDrawingSupplierDisplay(item) }),
                new MaCardLineModel({ display: this.getShopDrawingOrderLinkDisplay(item) }),
                new MaCardLineModel({ display: this.getShopDrawingRequisitionLinkDisplay(item) }),
                new MaCardLineModel({ display: this.getShopDrawingResourceLinkDisplay(item) }),
            ],
            actionItems: [new MaCardActionModel({ icon: "fa fa-chevron-right", action: this.edit.bind(this, item) })],
        });

        return card;
    }

    public getShopDrawingDisplay(item: ShopDrawingLightModel): string {
        return `${item.No} - ${item.Title}`;
    }

    public getShopDrawingCounterDisplay(item: ShopDrawingLightModel): string {
        return `${this.i18n.tr("Counter")}: ${item.Id}`;
    }

    public getShopDrawingStatusDisplay(item: ShopDrawingLightModel): string {
        return `${this.enumFormatValueConverter.toView(item.Status, nameof_ShopDrawingStatus)}`;
    }

    public getShopDrawingContactDisplay(item: ShopDrawingLightModel): string {
        if (item.ContactNo === 0) {
            return "";
        }

        return `${this.i18n.tr("Contact")}: ${item.ContactNo} - ${item.ContactDescription}`;
    }

    public getShopDrawingSupplierDisplay(item: ShopDrawingLightModel): string {
        if (!item.SupplierId) {
            return "";
        }

        return `${this.i18n.tr("Supplier")}: ${item.SupplierId} - ${item.SupplierDescription}`;
    }

    public getShopDrawingOrderLinkDisplay(item: ShopDrawingLightModel): string {
        if (!item.OrderLink) {
            return "";
        }

        return `${this.i18n.tr("Order")}: ${item.OrderLink}`;
    }

    public getShopDrawingRequisitionLinkDisplay(item: ShopDrawingLightModel): string {
        if (!item.RequisitionLink) {
            return "";
        }

        return `${this.i18n.tr("Requisition")}: ${item.RequisitionLink}`;
    }

    public getShopDrawingResourceLinkDisplay(item: ShopDrawingLightModel): string {

        if (!item.ResourceLink) {
            return "";
        }

        return `${this.i18n.tr("Resource")}: ${item.ResourceLink}`;
    }

    private async checkIsProjectActive(): Promise<void> {
      await this.dispatchProjectService.GetProjectFromDispatchProjectCode(this.dispatchProjectCode);
    }
}
