import { bindable, autoinject } from "aurelia-framework";
import val from "core/val";

@autoinject
export class MaAddNote {
    public val: any = val;

    @bindable public showInput: boolean = false;
    @bindable public value: any;
    @bindable public option: string = "";
    @bindable public context: string = "";
    @bindable public id: string = "";
    @bindable public maxLength: any;
    @bindable public disable: boolean = false;
    @bindable public acceptCrL: boolean = true;

    //#region Public Functions
    public activate(): void {
        this.showInput = this.value !== "";
    }

    public toggleShowInput(): void {
        this.showInput = !this.showInput;
    }
}
