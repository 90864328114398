import { FrameworkConfiguration, PLATFORM } from "aurelia-framework";

export function configure(config: FrameworkConfiguration): void {
    config.globalResources(
        [
            PLATFORM.moduleName("./grids/documents-grid"),
            PLATFORM.moduleName("./grids/quotation-equipment-grid"),
            PLATFORM.moduleName("./grids/quotation-grid"),
            PLATFORM.moduleName("./grids/quotation-items-grid"),
            PLATFORM.moduleName("./grids/procore/entity-configuration-grid"),
            PLATFORM.moduleName("./grids/procore/synchronization-grid"),
            PLATFORM.moduleName("./grids/procore/synchronization-grid/budgets-synchronization-grid"),
            PLATFORM.moduleName("./grids/procore/synchronization-grid/purchase-orders-synchronization-grid"),
            PLATFORM.moduleName("./grids/procore/synchronization-grid/projects-synchronization-grid"),
            PLATFORM.moduleName("./grids/procore/synchronization-grid/costs-synchronization-grid"),
            PLATFORM.moduleName("./grids/procore/synchronization-grid/suppliers-synchronization-grid"),
            PLATFORM.moduleName("./grids/procore/synchronization-grid/order-invoices-synchronization-grid"),
            PLATFORM.moduleName("./grids/procore/synchronization-grid/contracts-change-orders-synchronization-grid"),
            PLATFORM.moduleName("./grids/procore/synchronization-grid/prime-contract-change-orders-synchronization-grid")
        ]
    );
}
