import defaultService from "services/defaultService";
import serviceService from "services/serviceService";
import dateHelper from "helpers/dateHelper";
import { autoinject } from "aurelia-framework";
import labelHelper from "helpers/labelHelper";
import routerHelper from "helpers/routerHelper";
import phoneHelper from "helpers/phoneHelper";
import enumHelper from "helpers/enumHelper";
import { I18N } from "aurelia-i18n";
import val from "core/val";
import _ from "underscore";
import queryStringHelper from "helpers/queryStringHelper";
import notificationHelper from "helpers/notificationHelper";
import { ServiceCallDispatchOrderService } from "services/service-call-dispatch-order-service";
import { CloneHelper } from "helpers/cloneHelper";
import { Redirect, Router } from "aurelia-router";

@autoinject
export class SericeInformation {

    public dateHelper: typeof dateHelper = dateHelper;
    public labelHelper: typeof labelHelper = labelHelper;
    public routerHelper: typeof routerHelper = routerHelper;
    public phoneHelper: typeof phoneHelper = phoneHelper;

    public dispatchId: number = 0;
   
    public notes: any[] = [];
    public serviceType: string = "";
    public statusDisabled: boolean = true;
    public statusList: any[] = [];
    
    public dispatchStatus: any;
    public readonly: boolean = false;
    public enumPresence: any = enumHelper.servicePresence();
    public detail: any;
    public fromHistory: boolean = false;

    public serviceTypeEnum: any = { serviceCall: "S", workOrder: "W" };

    public informations: any;
    public unModifiedinformations: any;

    public selectedStatus: any;
    public unModifiedselectedStatus: any;

    public get statusPlaceHolder(): string {
        if (this.dispatchStatus.CustomStatus !== "") {
            return this.dispatchStatus.CustomStatus + " = " + this.dispatchStatus.CustomStatusDescription;
        } else {
            return this.statusDisabled ? this.i18n.tr("NoStatusAvailable") : this.i18n.tr("SelectOoo");
        }
    }

    public orderNoMaxLength: number = val.get("service.serviceCall.OrderNo", "maxLength");

    constructor(private readonly i18n: I18N, private readonly serviceCallDispatchOrderService: ServiceCallDispatchOrderService, private readonly router: Router) {
    }

    public async activate(params: any): Promise<void> {
        this.readonly = queryStringHelper.parseReadonly(params.q);

        this.fromHistory = queryStringHelper.parse(params.q, "fromHistory") === "true";

        this.bindViewModel(params.serviceType, params.dispatchId);
        await this.loadData();
    }

    public async canDeactivate(): Promise<any> {
        if (this.isDirty()) {
            routerHelper.hideLoading(true);
            const msgWarning = this.i18n.tr("msg_UnsavedChangedWillBeLostConfirmation");
            const confirm = await notificationHelper.showDialogYesNo(msgWarning);
            if (!confirm) {
                return new Redirect((this.router.history as any).previousLocation, { trigger: false, replace: false });
            }
        }
        return true;
    }

    public attached(): void {
        this.initDropDownList();
    }

    public bindViewModel(serviceType: string, dispatchId: number): void {
        this.dispatchId = dispatchId;
        this.serviceType = serviceType;
    }

    public async loadData(): Promise<void>  {

        const [dfdStatus, dfdDispatchStatus, dfdInfos, dfdNotes, details]: any = await Promise.all([
            serviceService.getCustomStatus(this.serviceType),
            serviceService.getStatus(this.serviceType, this.dispatchId),
            serviceService.getInformation(this.serviceType, this.dispatchId),
            serviceService.getNotes(this.serviceType, this.dispatchId),
            serviceService.getDetail(this.serviceType, this.dispatchId, this.fromHistory)
        ]);

        this.detail = details;
        this.informations = dfdInfos;
        this.notes = dfdNotes;

        if (!this.readonly) {
            this.readonly = this.detail.IsClosed || this.detail.Presence === this.enumPresence.COMPLETED.id;
        }

        const status = _.map(dfdStatus, (tmpData: any) =>  {
            return {
                id: tmpData.Id,
                text: tmpData.Id + " - " + tmpData.Description
            };
        });

        this.statusList = status;
        this.statusDisabled = this.statusList.length === 0 || this.readonly;
        this.dispatchStatus = dfdDispatchStatus;

        if (this.dispatchStatus.CustomStatus !== "" && _.find(this.statusList, (item: any) => item.id === this.dispatchStatus.CustomStatus)) {
            this.selectedStatus = this.dispatchStatus.CustomStatus;
        }

        this.unModifiedinformations = CloneHelper.deepClone(this.informations);
        this.unModifiedselectedStatus = this.selectedStatus;
    }

    public initDropDownList(): void {
        const dropdownStatus: any = jQuery("#ddlStatus");
        dropdownStatus.select2({
            minimumResultsForSearch: defaultService.getMinimumResultsForSearch(),
            placeholder: this.statusPlaceHolder,
            width: "100%",
            language: labelHelper.getDefaultSelect2Labels(),
            data: this.statusList

        }).on("change", (e: any) => {
            this.selectedStatus = e.target.value;
        }).val(this.selectedStatus).trigger("change");
    }

    public isDirty(): boolean {
        if (this.readonly || !this.unModifiedinformations) { return false; }

        const stringifyUnmodifiedStatus = this.unModifiedselectedStatus ? JSON.stringify(this.unModifiedselectedStatus).replace(/[^0-9A-Z]+/gi, "") : null;
        const stringifyCurrentStatus = this.selectedStatus ? JSON.stringify(this.selectedStatus).replace(/[^0-9A-Z]+/gi, "") : null;

        const stringifyUnmodifiedInformations = JSON.stringify(this.unModifiedinformations).replace(/[^0-9A-Z]+/gi, "");
        const stringifyCurrentInformations = JSON.stringify(this.informations).replace(/[^0-9A-Z]+/gi, "");

        return stringifyUnmodifiedStatus !== stringifyCurrentStatus || stringifyUnmodifiedInformations !== stringifyCurrentInformations;
    }

    public async save(): Promise<void> {

        try {
            if (!this.statusDisabled && this.selectedStatus === "") {
                notificationHelper.showWarning(this.i18n.tr("err_StatusRequired"));
            } else {
                await serviceService.setStatus(this.serviceType, this.dispatchId, this.selectedStatus);
            }

            if (this.serviceType === this.serviceTypeEnum.serviceCall) {
                await this.serviceCallDispatchOrderService.UpdateOrderNo(this.dispatchId, this.informations.OrderNumber);
            }

            this.unModifiedselectedStatus = null;
            this.unModifiedinformations = null;

            routerHelper.navigateBack();
        } catch (e) {
            // do nothing, handle by error messaging
        }
       
     }

}
