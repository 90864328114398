import { CreationSource } from "api/enums/creation-source";
import { ServiceCallQuotationStatus, nameof as nameof_ServiceCallQuotationStatus } from "api/enums/service-call-quotation-status";
import { ServiceCallWorkOrderQuotationStatus, nameof as nameof_ServiceCallWorkOrderQuotationStatus } from "api/enums/service-call-work-order-quotation-status";
import { ServiceQuotationSecuritySettingsModel } from "api/models/company/security/service-quotation-security-settings-model";
import { ServiceCallQuotationDetailsModel } from "api/models/company/service-call/service-call-quotation-details-model";
import { autoinject, computedFrom } from "aurelia-framework";
import { ValidationController, ValidationRules, validateTrigger } from "aurelia-validation";
import { EnumFormatWithIdValueConverter } from "converters/enums/enum-format-with-id";
import { ValidationAttributeValueConverter } from "converters/misc/validation-attribute-value-converter";
import { NotificationHelper } from "helpers/notification-helper";
import { RouterHelper } from "helpers/router-helper";
import { ValidationHelper } from "helpers/validation-helper";
import { QuotationEditBase } from "pages/services/quotations/edit-base";
import { QuotationLightParameters } from "pages/services/quotations/quotation-light";
import RouteRepository, { NavigationIdOrNew, NavigationNew } from "repositories/routeRepository";
import { CustomerContactService } from "services/customer-contact-service";
import { CustomerService } from "services/customer-service";
import { EmployeeService } from "services/employee-service";
import { ServiceCallContractService } from "services/service-call-contract-service";
import { ServiceCallEmergencyTypeService } from "services/service-call-emergency-type-service";
import { ServiceCallQuotationSecurityService } from "services/service-call-quotation-security-service";
import { ServiceCallQuotationService } from "services/service-call-quotation-service";
import { ServiceCallQuotationTypeService } from "services/service-call-quotation-type-service";
import { SkillService } from "services/skill-service";
import { SalesPersonService } from "services/sales-person-service";
import { ServiceCallService } from "services/service-call-service";
import { SettingRepository } from "repositories/setting-repository";
import { TimeListHelper, TimeListItem } from "helpers/time-list-helper";
import { default as templateService } from "services/templateService";
import { ViewModeHelper } from "helpers/view-mode-helper";
import { ServiceCallQuotationEquipmentService } from "services/service-call-quotation-equipment-service";
import { ServiceCallQuotationPriceService } from "services/service-call-quotation-price-service";
import { DispatchTemplateModel } from "api/models/company/template/dispatch-template-model";
import { DispatchTemplateService } from "services/dispatch-template-service";
import { default as settingHelper } from "helpers/settingHelper";
import { Redirect, Router } from "aurelia-router";
import { I18N } from "aurelia-i18n";
import { LocationService } from "services/location-service";
import { ContactService } from "services/contact-service";
import { WorkOrderPriorityService } from "services/work-order-priority-service";
import { ServiceWorkOrderProjectService } from "services/service-work-order-project-service";
import { ServiceWorkOrderService } from "services/service-work-order-service";
import { ServiceCallContractEquipmentService } from "services/service-call-contract-equipment-service";
import { ServiceCallEmergencyTypeModel } from "api/models/company/service-call/service-call-emergency-type-model";
import moment from "moment";
import { ServiceCallRequiredDateType } from "api/enums/service-call-required-date-type";
import { LocationModel } from "api/models/company/location-model";
import { StringHelper } from "helpers/string-helper";
import { CloneHelper } from "helpers/cloneHelper";

export interface QuotationDetailsParameters {
    quotationId: NavigationIdOrNew<number>;
    isWorkOrder: boolean;
    sourceServiceCallId?: number;
    contractEquipmentId?: number;
    dispatchId?: number;
    sourceWorkOrderId?: string;
}

@autoinject()
export class QuotationDetails extends QuotationEditBase {
    public quotation!: ServiceCallQuotationDetailsModel;
    public securitySettings: ServiceQuotationSecuritySettingsModel | null = null;
    public readonly nameof_ServiceCallQuotationStatus: string = nameof_ServiceCallQuotationStatus;
    public readonly nameof_ServiceCallWorkOrderQuotationStatus: string = nameof_ServiceCallWorkOrderQuotationStatus;
    public timeOptions: TimeListItem[] = [];
    public timeIncrement: number = 30;
    public showRichTextBox: boolean = true;
    public params!: QuotationDetailsParameters;

    @computedFrom("unmodifiedQuotation", "unmodifiedQuotation.Status", "securitySettings")
    public get readonly(): boolean {
        return this.serviceCallQuotationSecurityService.isQuotationReadOnlyInMobile(this.unmodifiedQuotation, this.securitySettings);
    }

    protected get initialQuotationStatus(): string {
        // In mobile the quotation is "In progress" initially. In desktop it is "Entered".
        return ServiceCallQuotationStatus.InProgressByTheTechnician;
    }

    constructor(
        serviceCallQuotationService: ServiceCallQuotationService,
        serviceCallQuotationSecurityService: ServiceCallQuotationSecurityService,
        routerHelper: RouterHelper,
        notificationHelper: NotificationHelper,
        customerService: CustomerService,
        customerContactService: CustomerContactService,
        serviceCallContractService: ServiceCallContractService,
        serviceWorkOrderProjectService: ServiceWorkOrderProjectService,
        skillService: SkillService,
        serviceCallEmergencyTypeService: ServiceCallEmergencyTypeService,
        serviceCallQuotationTypeService: ServiceCallQuotationTypeService,
        employeeService: EmployeeService,
        validationAttributeValueConverter: ValidationAttributeValueConverter,
        validationHelper: ValidationHelper,
        validationController: ValidationController,
        salesPersonService: SalesPersonService,
        serviceCallService: ServiceCallService,
        settingRepository: SettingRepository,
        viewModeHelper: ViewModeHelper,
        serviceWorkOrderService: ServiceWorkOrderService,
        i18N: I18N,
        workOrderPriorityService: WorkOrderPriorityService,
        serviceCallQuotationEquipmentService: ServiceCallQuotationEquipmentService,
        serviceCallQuotationPriceService: ServiceCallQuotationPriceService,
        locationService: LocationService,
        contactService: ContactService,
        serviceCallContractEquipmentService: ServiceCallContractEquipmentService,
        public readonly enumFormatWithIdValueConverter: EnumFormatWithIdValueConverter,
        private readonly routeRepository: RouteRepository,
        private readonly dispatchTemplateService: DispatchTemplateService,
        private readonly router: Router
    ) {
        super(serviceCallQuotationService, serviceCallQuotationSecurityService, routerHelper, notificationHelper, customerService, customerContactService, serviceCallContractService, serviceWorkOrderProjectService, skillService, serviceCallEmergencyTypeService, serviceCallQuotationTypeService, employeeService, validationAttributeValueConverter, validationHelper, validationController, salesPersonService, serviceCallService, settingRepository, viewModeHelper, serviceCallQuotationEquipmentService, serviceCallQuotationPriceService, locationService, contactService, serviceWorkOrderService, i18N, workOrderPriorityService, serviceCallContractEquipmentService);
    }

    // #region Lifecycle
    public async activate(params: QuotationDetailsParameters): Promise<void> {
        this.params = params;
        await Promise.all([
            this.initQuotation(this.params.quotationId, CreationSource.Mobile, this.params.isWorkOrder, this.params.sourceServiceCallId, this.params.contractEquipmentId, this.params.dispatchId, undefined, this.params.sourceWorkOrderId),
            this.initSecuritySettings()
        ]);

        await this.initTimeIncrement();

        this.initTimeOptions();
        this.initValidation();
    }

    public async deactivate(): Promise<void> {
        this.showRichTextBox = false;
    }

    public async canActivate(params: QuotationDetailsParameters): Promise<any> {
        let sourceDispatchId = 0;
        if (params.quotationId !== NavigationNew) {
            sourceDispatchId = await this.getSourceDispatchId(params.quotationId);
        }

        if (sourceDispatchId === 0 && !settingHelper.hasDispatchModel()) {
            this.notificationHelper.showWarningKey("DispatchModelRequired", "");
            return new Redirect("Settings");
        }

        if (params.quotationId === NavigationNew) {

            if (!params.isWorkOrder) {
                const isCreatedFromTempContract = await this.createdFromTemporaryContract();

                if (isCreatedFromTempContract) {
                    this.notificationHelper.showWarningKey("msg_Cant_Create_Quote_With_Temporary_Contract", "");
                    this.routerHelper.navigateBack();
                    return false;
                }
            }

            if (params.isWorkOrder && params.sourceWorkOrderId) {
                const isProjectInvoicing = await this.isProjectInvoicing(params.sourceWorkOrderId);

                if (!isProjectInvoicing) {
                    this.notificationHelper.showWarningKey("msg_Cant_Create_Quote_With_Not_Invoicing_Project", "", { timeOut: 0 });
                    this.routerHelper.navigateBack();
                    return false;
                }
            }

        }

        return true;
    }

    public async canDeactivate(): Promise<any> {
        if (this.isDirty()) {
            this.routerHelper.hideLoading(true);
            const msgWarning = this.i18N.tr("msg_UnsavedChangedWillBeLostConfirmation");
            const confirm = await this.notificationHelper.showDialogYesNo(msgWarning);
            if (!confirm) {
                return new Redirect((this.router.history as any).previousLocation, { trigger: false, replace: false });
            }
        }

        return true;
    }

    // #endregion
    // #region EventHandlers
    public async save(): Promise<void> {
        const isValid = await this.validationHelper.validateControllerAndNotifyUserIfNecessary(this.validationController);

        if (isValid !== true) {
            return;
        }

        if (this.displayAlternativeAddressFields) {
            const result = await this.locationService.AddAlternativeLocation(this.cleanAddressStrings(this.alternativeAddress));
            if (result !== null) {
                this.quotation.Address = result;
                this.quotation.Address_AlternativeAddressId = result.NumericId!;
            }
        }

        const quotationId = await this.serviceCallQuotationService.saveQuotation(this.quotation, true, true);
        
        await this.redirectAfterSave(quotationId);
    }

    public async onSelectedEmergencyChanged(event: CustomEvent<ServiceCallEmergencyTypeModel>): Promise<void> {
        if (!this.quotation) { return; }

        if (this.unmodifiedQuotation && this.unmodifiedQuotation.RequiredDate && event.detail && event.detail.NbHour) {
            this.quotation.RequiredDate = moment(this.unmodifiedQuotation.RequiredDate).add(event.detail.NbHour, "h").toDate();
            this.quotation.RequiredDateType = ServiceCallRequiredDateType.Limited.toString();
        } else if (this.unmodifiedQuotation && this.unmodifiedQuotation.RequiredDate) {
            this.quotation.RequiredDate = moment(this.unmodifiedQuotation.RequiredDate).toDate();
        }
    }

    // #endregion
    // #region Privates
    private initValidation(): void {
        this.validationController.validateTrigger = validateTrigger.manual;

        ValidationRules
            .ensure((x: QuotationEditBase) => x.selectedContractId).required().when((x: QuotationEditBase) => !this.quotation.IsWorkOrder).withMessageKey("err_ContractRequired")
            .ensure((x: QuotationEditBase) => x.selectedProjectId).required().when((x: QuotationEditBase) => this.quotation.IsWorkOrder).withMessageKey("err_ProjectRequired")
            .on(this);

        ValidationRules
            .ensure((x: ServiceCallQuotationDetailsModel) => x.CustomerId).required().when(() => !this.isResidential && !this.quotation.IsWorkOrder).withMessageKey("err_CustomerRequired")
            .ensure((x: ServiceCallQuotationDetailsModel) => x.Description).required().withMessageKey("err_BriefDescriptionRequired")
            .ensure((x: ServiceCallQuotationDetailsModel) => x.Address).required().when(() => !this.isResidential && !this.displayAlternativeAddressFields).withMessageKey("err_WorkLocationRequired")
            .ensure((x: ServiceCallQuotationDetailsModel) => x.ContactName).required().withMessageKey("err_ContactNameRequired")
            .ensure((x: ServiceCallQuotationDetailsModel) => x.ContactEmail).required().withMessageKey("err_ContactEmailRequired")
            .ensure((x: ServiceCallQuotationDetailsModel) => x.ContactEmail).email().withMessageKey("err_InvalidEmail")
            .on(this.quotation);

        ValidationRules
            .ensure((x: LocationModel) => x.Name).required().when(() => this.displayAlternativeAddressFields).withMessageKey("err_NameRequired")
            .ensure((x: LocationModel) => x.Address).required().when(() => this.displayAlternativeAddressFields).withMessageKey("err_AddressRequired")
            .ensure((x: LocationModel) => x.City).required().when(() => this.displayAlternativeAddressFields).withMessageKey("err_CityRequired")
            .on(this.alternativeAddress);
    }

    private async redirectAfterSave(quotationId: number): Promise<void> {
        const editMode = this.quotation.Id > 0;

        await this.initQuotation((this.quotation.Id > 0 ? this.quotation.Id : quotationId), CreationSource.Mobile, this.params.isWorkOrder, this.params.sourceServiceCallId, this.params.contractEquipmentId, this.params.dispatchId, undefined, this.params.sourceWorkOrderId);

        if (editMode) {
            // Go back to the detail page.
            return this.routerHelper.navigateBack();
        }
        // Redirect to the detail page

        const route = this.routeRepository.routes.Service_Quotation_Mobile.name;
        const routeParameters: QuotationLightParameters = { quotationId: quotationId };
        this.routerHelper.navigateToRoute(route, routeParameters, { replace: true });
    }

    private async initSecuritySettings(): Promise<void> {
        this.securitySettings = await this.serviceCallQuotationSecurityService.getSecuritySettings();
    }

    private initTimeOptions(): void {
        this.timeOptions = TimeListHelper.loadTimeList(this.timeIncrement);
    }

    private async initTimeIncrement(): Promise<void> {
        if (!this.quotation) { return; }

        let increment = await templateService.getUserTemplateIncrement();
        if (this.quotation.SourceDispatchId !== 0) {
            const dispatchTemplate = await this.dispatchTemplateService.getDispatchTemplatesByDispatchId(this.quotation.SourceDispatchId);
            increment = dispatchTemplate.TimeIncrement;
        }

        this.timeIncrement = increment > 0 ? increment : this.timeIncrement;
    }

    private async createdFromTemporaryContract(): Promise<boolean> {
        if (!this.quotation) { return false; }

        let dispatchTemplate = null;

        //current dispatch template
        const dispatchTemplateId = this.settingRepository.getDispatchTemplate();
        if (dispatchTemplateId !== null && dispatchTemplateId !== undefined) {
            dispatchTemplate = await this.dispatchTemplateService.GetDispatchTemplatesByCode(dispatchTemplateId);
        }

        if (this.quotation.SourceDispatchId !== 0) {
            //template from the dispatch
            dispatchTemplate = await this.dispatchTemplateService.getDispatchTemplatesByDispatchId(this.quotation.SourceDispatchId);
        }

        if (dispatchTemplate) {
            if (dispatchTemplate.TemporaryContract !== "" && this.quotation.ContractId !== null && dispatchTemplate.TemporaryContract === this.quotation.ContractId) {
                return true;
            }
        }

        return false;
    }

    private async isProjectInvoicing(workOrderId: string): Promise<boolean> {
        return this.serviceCallQuotationService.isProjectInvoicing(workOrderId);
    }

    private cleanAddressStrings(address: LocationModel): LocationModel {
        address.PostalCode = StringHelper.cleanString(address.PostalCode || "", StringHelper.keepAlphaNumericOnly);
        address.Telephone1 = StringHelper.cleanString(address.Telephone1 || "", StringHelper.keepNumericOnly);
        return address;
    }
    // #endregion
}
