import { PurchaseOrderService } from "services/purchase-order-service";
import { autoinject, bindable } from "aurelia-framework";

import { default as enumHelper } from "helpers/enumHelper";
import { default as routerHelper } from "helpers/routerHelper";
import { default as notificationHelper } from "helpers/notificationHelper";
import dateHelper from "helpers/dateHelper";

import userSettingService from "services/userSettingsService";
import { I18N } from "aurelia-i18n";
import { PurchaseOrderTabs } from "enums/purchase-order-tabs";

@autoinject()
export class MaterialOrder {

    public displayAllPurchaseOrders: boolean = false;
    public purchaseOrderTabs: typeof PurchaseOrderTabs = PurchaseOrderTabs;
    public dateHelper: typeof dateHelper = dateHelper;

    @bindable public actions: any;
    @bindable public readonly: boolean = false;
    @bindable public dispatchProjectCode: string = "";
    @bindable public dispatchId: string = "";
    @bindable public dispatchDate: string = "";
    @bindable public projectCode: string = "";
    @bindable public showClosedSection: boolean = false;
    @bindable public isProjectResponsible: boolean = false;

    public currentTabShown: number = PurchaseOrderTabs.Active;
    public filterLabels: any[] = [];

    public orders: any;
    public closedOrders: any;
    public fullyReceivedOrders: any;

    constructor(private readonly i18n: I18N, private readonly purchaseOrderService: PurchaseOrderService) {
    }

    public bind(): any {
        this.loadData();
    }

    public attached(): any {
        this.formatToggleLabels(this.displayAllPurchaseOrders);
    }

    public async loadData(): Promise<void> {

        if (this.showClosedSection) {
            const usersetting = await userSettingService.getPurchaseOrdersForCurrentUserSetting();
            if (usersetting) {
                this.displayAllPurchaseOrders = usersetting.Value === "true";
            }
        }

        const result = await this.actions.getOrders(this.currentTabShown === this.purchaseOrderTabs.Closed, this.currentTabShown === this.purchaseOrderTabs.FullyReceived, this.displayAllPurchaseOrders);
        if (this.currentTabShown === this.purchaseOrderTabs.Closed) {
            this.closedOrders = result;
        } else if (this.currentTabShown === this.purchaseOrderTabs.FullyReceived) {
            this.fullyReceivedOrders = result;
        } else {
            this.orders = result;
        }
    }

    public async deletePurchaseOrder(purchaseOrder: any): Promise<void> {
        if (await notificationHelper.showDeleteConfirmation()) {
            if (purchaseOrder.Status !== enumHelper.purchaseOrderStatus.RESERVED) {
                notificationHelper.showError(this.i18n.tr("611"));
            } else {
                await this.purchaseOrderService.DeletePurchaseOrder(purchaseOrder.Id, purchaseOrder.Extension).then(() => {
                    routerHelper.navigateSelf();
                });
            }
        }
    }

    public async show(section: number): Promise<void> {
        this.currentTabShown = section;
        await this.loadData();
    }

    public async toggle(section: number): Promise<void> {
        this.displayAllPurchaseOrders = !this.displayAllPurchaseOrders;

        await userSettingService.setPurchaseOrdersForCurrentUserSetting(this.displayAllPurchaseOrders);

        this.formatToggleLabels(this.displayAllPurchaseOrders);

        this.currentTabShown = section;

        await this.loadData();
    }

    public genUrl(rel: string, extension: any): string {
        return routerHelper.getRelativeUrl(rel) + routerHelper.addQuerystring({ isProjectResponsible: this.isProjectResponsible, readonly: this.readonly, dispatchProjectCode: this.dispatchProjectCode, dispatchDate: this.dispatchDate, projectCode: this.projectCode, dispatchId: this.dispatchId, extension: extension });
    }

    public gotoPage(): void {
        routerHelper.navigate(routerHelper.getRelativeUrl("edit") + routerHelper.addQuerystring({ isProjectResponsible: this.isProjectResponsible, readonly: this.readonly, dispatchProjectCode: this.dispatchProjectCode, dispatchDate: this.dispatchDate, projectCode: this.projectCode, dispatchId: this.dispatchId }));
    }

    public formatToggleLabels(checked: boolean): void {
        if (!this.filterLabels  || this.filterLabels.length <= 0) {
            return;
        }

        const indexToActivate = checked ? 1 : 0;

        // Disable everything
        this.filterLabels.forEach((label: HTMLElement) => {
            label.classList.remove("bold");
            label.classList.add("text-muted");
        });

        // Enable the index received
        this.filterLabels[indexToActivate].classList.add("bold");
        this.filterLabels[indexToActivate].classList.remove("text-muted");
    }

    public goToEdit(id: string, extension: string): void {
        routerHelper.navigate(this.genUrl("edit/" + encodeURIComponent(id), encodeURIComponent(extension)));
    }
}
