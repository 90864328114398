import { autoinject, observable } from "aurelia-framework";
import { I18N } from "aurelia-i18n";

import { default as _ } from "underscore";
import { default as phoneHelper } from "helpers/phoneHelper";
import { default as enumHelper, addressSources } from "helpers/enumHelper";
import { default as queryStringHelper } from "helpers/queryStringHelper";
import { default as routerHelper } from "helpers/routerHelper";

import { StringHelper } from "helpers/string-helper";

import { DeliveryAddressProxy } from "api/proxies/delivery-address-proxy";
import { WorkOrderAddressProxy } from "api/proxies/work-order-address-proxy";
import { WorkOrderLocationModel } from "api/models/company/work-order-location-model";
import { DeliveryAddressModel } from "api/models/company/delivery-address-model";
import { FormBase } from "pages/form-base";
import { NotificationHelper } from "helpers/notification-helper";
import { Router } from "aurelia-router";

@autoinject()
export class Address extends FormBase {
    public phoneHelper: any = phoneHelper;
    public addressSourceEnum: addressSources = enumHelper.addressSources();
    public addressSourceData: any[] = new Array();

    @observable public currentAddressSource: string = "";
    @observable public specificationItem: any;

    public currentAddress?: WorkOrderLocationModel;
    public unmodifiedcurrentAddress?: WorkOrderLocationModel | null = null;
    public specificationId?: number;
    public workOrderId: any;

    public isReadonly: boolean = false;
    public isAlternativeAddress: boolean = false;

    public getSpecifications: any = {
        transport: (params: any, success: any, failure: any): any => {
            this.deliveryAddressProxy.GetDeliveryAddresses(params.data.filter, { page: params.data.page, pageSize: 20 }).then(
                (result: any) => {
                    return success(result);
                },
                (fail: any) => {
                    return failure(fail);
                }
            );
        },
        mapResults: (item: DeliveryAddressModel): any => {
            return { id: item.Id, text: item.Id + " - " + item.Name, data: this.formatDeliveryAddressModel(item) };
        },
    };

    constructor(i18n: I18N, notificationHelper: NotificationHelper, router: Router, private readonly workOrderAddressProxy: WorkOrderAddressProxy, private readonly deliveryAddressProxy: DeliveryAddressProxy) {
        super(notificationHelper, i18n, router);
    }

    public checkDirty(): boolean {
        if (this.isReadonly) {
            return false;
        }

        if (!this.unmodifiedcurrentAddress) {
            return false;
        }

        if (!this.currentAddress) {
            return false;
        }

        const stringifyUnmodified = JSON.stringify(this.unmodifiedcurrentAddress).replace(/[^0-9A-Z]+/gi, "");
        const stringifyCurrent = JSON.stringify(this.getAddressDto()).replace(/[^0-9A-Z]+/gi, "");

        return stringifyUnmodified !== stringifyCurrent;
    }

    public bind(): void {
        this.initializeControls();
        this.initialize();
    }

    public activate(params: any): void {
        const queryString = params.q;
        this.isReadonly = queryStringHelper.parseReadonly(queryString);
        this.workOrderId = decodeURIComponent(params.workOrderId);

        this.clearAddressInCache();
    }

    public currentAddressSourceChanged(newValue: string, oldValue: any): void {
        if (newValue === oldValue) {
            return;
        }
        if (oldValue !== null) {
            this.saveAddressToCache(oldValue);
        }

        switch (newValue) {
            case this.addressSourceEnum.CLIENT.value:
                this.loadClientAddress();
                this.isAlternativeAddress = false;
                break;
            case this.addressSourceEnum.PROJECT.value:
                this.loadProjectAddress();
                this.isAlternativeAddress = false;
                break;
            case this.addressSourceEnum.ALTERNATIVE.value:
                this.loadAlternativeAddress();
                this.isAlternativeAddress = true;
                break;
            default:
                break;
        }
    }

    public specificationItemChanged(): void {
        if (this.specificationItem === null) {
            this.clearCurrentAddress();
            return;
        }

        this.currentAddress = this.specificationItem.data.data;
    }

    public clearCurrentAddress(): void {
        this.currentAddress = undefined;
    }

    public openAddressMap(): void {
        window.open(routerHelper.mapAddress(this.getAddressString(this.currentAddress!)));
    }

    public getAddressString(currentAddress: WorkOrderLocationModel): string {
        let addressString: string = "";

        if (!currentAddress || !currentAddress.Address) {
            return "";
        }

        addressString += currentAddress.Address;
        if (currentAddress.City || currentAddress.Province || currentAddress.PostalCode) {
            addressString += ", ";
            addressString += currentAddress.City ? currentAddress.City : "";
            addressString += currentAddress.Province ? " (" + currentAddress.Province + ") " : " ";
            addressString += currentAddress.PostalCode ? currentAddress.PostalCode : "";
        }

        return addressString;
    }

    public save(): void {
        if (this.isReadonly) {
            return;
        }
        routerHelper.showLoading();
        if (!this.isAlternativeAddress || this.isSaveAlternativeAddressAllowed()) {
            this.workOrderAddressProxy.UpdateWorkOrderAddress(this.workOrderId, this.getAddressDto()).then(() => {
                this.unmodifiedcurrentAddress = null;
                routerHelper.navigateBack();
                routerHelper.hideLoading();
            });
        } else {
            routerHelper.hideLoading();
            this.notificationHelper.showWarning(this.i18n.tr("msg_OneFieldRequired"), "");
        }
    }

    private isSaveAlternativeAddressAllowed(): boolean {
        let notEmptyFieldCount: number = 0;

        const address: any = this.getAddressDto();

        for (const key in this.currentAddress!) {
            if (!!address[key] && address[key] !== "") {
                if (key !== "SpecificationId" && key !== "AddressType" && key !== "Id") {
                    notEmptyFieldCount++;
                    break;
                }
            }
        }

        return notEmptyFieldCount > 0;
    }

    private saveAddressToCache(addressSource: string): void {
        const key = this.workOrderId + addressSource;
        if (this.currentAddress !== undefined && JSON.stringify(this.currentAddress) !== "{}" && addressSource !== "") {
            sessionStorage.setItem(key, JSON.stringify(this.currentAddress));
        }
    }

    private getAddressFromCache(): WorkOrderLocationModel | undefined {
        const key = this.workOrderId + this.currentAddressSource;
        if (sessionStorage.getItem(key) !== null) {
            return JSON.parse(sessionStorage.getItem(key)!) as WorkOrderLocationModel;
        }
        return undefined;
    }

    private clearAddressInCache(): void {
        if (sessionStorage.length > 0) {
            sessionStorage.removeItem(this.workOrderId + this.addressSourceEnum.ALTERNATIVE.value);
            sessionStorage.removeItem(this.workOrderId + this.addressSourceEnum.CLIENT.value);
            sessionStorage.removeItem(this.workOrderId + this.addressSourceEnum.PROJECT.value);
        }
    }

    private getAddressDto(): WorkOrderLocationModel {
        if (this.isAlternativeAddress && !!this.specificationItem) {
            this.currentAddress!.SpecificationId = this.specificationItem.id;
        }

        if (!!this.currentAddress) {
            this.currentAddress.AddressType = this.currentAddressSource;
            this.currentAddress.SpecificationId = this.currentAddress.SpecificationId || "";

            this.currentAddress.Name = this.currentAddress.Name || "";
            this.currentAddress.Address = this.currentAddress.Address || "";
            this.currentAddress.City = this.currentAddress.City || "";
            this.currentAddress.Country = this.currentAddress.Country || "";
            this.currentAddress.Province = this.currentAddress.Province || "";

            this.currentAddress.PhoneNumber = StringHelper.cleanString(this.currentAddress.PhoneNumber || "", StringHelper.keepAlphaNumericOnly);
            this.currentAddress.PostalCode = StringHelper.cleanString(this.currentAddress.PostalCode || "", StringHelper.keepAlphaNumericOnly);

            this.currentAddress.ZipCode = this.currentAddress.ZipCode || "";
        }

        return this.currentAddress!;
    }

    private formatWorkOrderLocationModel(data: WorkOrderLocationModel): WorkOrderLocationModel {
        data.AddressType = this.currentAddressSource;
        data.PhoneNumber = phoneHelper.getDefaultFormat(data.PhoneNumber!);
        return data;
    }

    private formatDeliveryAddressModel(data: DeliveryAddressModel): DeliveryAddressModel {
        data.PhoneNumber = phoneHelper.getDefaultFormat(data.PhoneNumber!);
        return data;
    }

    private loadClientAddress(): any {
        if (this.getAddressFromCache() !== undefined) {
            this.currentAddress = this.getAddressFromCache();
        } else {
            this.workOrderAddressProxy.GetWorkOrderAddress(this.workOrderId).then((result: any) => {
                if (result) {
                    this.currentAddress = this.formatWorkOrderLocationModel(result);
                }
            });
        }
    }

    private loadProjectAddress(): any {
        if (this.getAddressFromCache() !== undefined) {
            this.currentAddress = this.getAddressFromCache();
        } else {
            this.workOrderAddressProxy.GetWorkOrderProjectAddress(this.workOrderId).then((result: any) => {
                if (result) {
                    this.currentAddress = this.formatWorkOrderLocationModel(result);
                }
            });
        }
    }

    private loadAlternativeAddress(): any {
        if (this.getAddressFromCache() !== undefined) {
            this.currentAddress = this.getAddressFromCache();
        } else {
            this.clearCurrentAddress();
        }
    }

    private initializeControls(): void {
        this.addressSourceData = _.map<any, any>(this.addressSourceEnum, (item: any) => {
            item.text = item.label;
            item.value = item.value;
            return item;
        });
    }

    private initialize(): void {
        this.workOrderAddressProxy.GetWorkOrderAddress(this.workOrderId).then((result: WorkOrderLocationModel | null) => {
            if (result !== null) {
                if (result.AddressType === "A") {
                    this.specificationId = Number.parseInt(result.SpecificationId!);
                    this.specificationItem = {
                        id: result.SpecificationId,
                        text: result.SpecificationId + " - " + result.Name,
                        data: result!,
                    };
                }

                this.currentAddressSource = result.AddressType!;
                this.currentAddress = this.formatWorkOrderLocationModel(result);
                this.unmodifiedcurrentAddress = this.formatWorkOrderLocationModel(result);
            }
        });
    }
}
