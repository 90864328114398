import { inject, bindable } from "aurelia-framework";
import { EventAggregator } from "aurelia-event-aggregator";

import { default as _ } from "underscore";
import { default as resx } from "core/resx";

import { notificationCenterHelper } from "helpers/notificationCenterHelper";

@inject(EventAggregator, notificationCenterHelper)
export class NotificationButton {
    resx = resx;
    isOpen = false;
    subscription = [];

    @bindable readonly = false;

    constructor(eventAggregator, notificationCenterHelper) {
        this.notificationCenterHelper = notificationCenterHelper;
        this.eventAggregator = eventAggregator;
        this.registerEventsHandlers();
    }

    registerEventsHandlers() {
        this.subscription.push(this.eventAggregator.subscribe('notification-center:set', (params) => this.notificationCenterHelper.addMessageToNotificationCenter(...params)));
        this.subscription.push(this.eventAggregator.subscribe('notification-center:remove', (params) => this.notificationCenterHelper.removeMessageToNotificationCenter(...params)));
    }

    attached() {
        this.notificationCenterHelper.validateUnCompleteFileTransfer();
    }

    detached() {
        _.forEach(this.subscription, subscription => subscription.dispose());
    }

    toggleNotificationMenu() {
        this.isOpen = !this.isOpen;
        jQuery(".notification-curtain").scrollTop(0);
    }

    removeMessage(key) {
        this.notificationCenterHelper.removeMessageToNotificationCenter(key);
    }
}
