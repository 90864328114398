import { DocumentProxy } from "api/proxies/document-proxy";
import routerHelper from "helpers/routerHelper";
import documentService from "services/documentService";
import { TemplateService } from "services/template-service";
import { autoinject } from "aurelia-framework";
import { DocumentCategoryModel } from "api/models/company/document/document-category-model";
import { SettingRepository } from "repositories/setting-repository";

@autoinject
export class DailyEntryDocumentAdd {
    public readonly: boolean = false;
    public dispatchProjectCode: string | null = null;
    public dailyEntryDate: string | null = null;
    public actions: any;
    public isCategorySelectionAllowed: boolean = false;
    public getCategoryProxy?: () => Promise<DocumentCategoryModel[] | null>;

    constructor(
        private readonly templateService: TemplateService,
        private readonly documentProxy: DocumentProxy,
        private readonly settingRepository: SettingRepository
    ) {}

    public activate(params: any): void {
        const paramsQueryString = routerHelper.getQuerystring(params.q);
        this.readonly = !(paramsQueryString && paramsQueryString.readonly === "false");
        this.dispatchProjectCode = params.dispatchProjectCode;
        this.dailyEntryDate = params.dailyEntryDate;
        this.actions = { save: documentService.saveMultipleProjectPictures.bind(self, this.dispatchProjectCode, this.dailyEntryDate) };

        this.isCategorySelectionAllowed = this.templateService.getCurrentTemplateConfigs().AllowCategorySelection;
        this.getCategoryProxy = this.documentProxy.GetCategories.bind(this.documentProxy, this.settingRepository.getDispatchTemplate());
    }
}
