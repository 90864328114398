import { transient } from "aurelia-framework";

import { default as queryStringHelper } from "helpers/queryStringHelper";
import { default as routerHelper } from "helpers/routerHelper";
import { default as equipmentCounterService } from "services/equipmentCounterService";

@transient()
export class EquipmentCounters {
    readonly = false;
    dispatchProjectCode = null;    
    dailyEntryDate = null;    

    constructor() {
    }

    activate(params) {
        this.bindViewModel(params.dispatchProjectCode, params.dailyEntryDate, params.q);
        return this.loadData();
    }

    bindViewModel(dispatchProjectCode, dailyEntryDate, qs) {
        this.readonly = queryStringHelper.parseReadonly(qs);
        this.dispatchProjectCode = dispatchProjectCode;    
        this.dailyEntryDate = dailyEntryDate;    
    }

    async loadData(){
        this.equipmentList = await equipmentCounterService.getForDailyEntry(this.dispatchProjectCode, this.dailyEntryDate);
    }

    async save(){
        await equipmentCounterService.saveForDailyEntry(this.dispatchProjectCode, this.dailyEntryDate, this.equipmentList);
        routerHelper.navigateBack();
    }
}
