import { MaterialRequisitionProjectResourceModel } from "api/models/company/material-requisition/material-requisition-project-resource-model";
import { autoinject, observable } from "aurelia-framework";
import { I18N } from "aurelia-i18n";

import { default as dateHelper } from "helpers/dateHelper";
import { default as settingHelper } from "helpers/settingHelper";
import { default as notificationHelper } from "helpers/notificationHelper";

import { DispatchProjectMaterialRequisitionProxy } from "api/proxies/dispatch-project-material-requisition-proxy";
import { navigateBack } from "helpers/routerHelper";
import { MaterialRequisitionItemModel } from "api/models/company/material-requisition/material-requisition-item-model";
import _ from "underscore";

@autoinject()
export default class MaterialRequisitionProjectResource {
    public workOrderId: any | undefined;
    public lineNo: any = "";
    public requisitionDate: Date | null = null;
    public requisitionId: string = "";
    public isResourceSelected: boolean = true;

    public dispatchProjectCode: any;

    public resourceItems: MaterialRequisitionItemModel[] = [];

    @observable public selectedResource: any | undefined;

    public getProjectResources: any = {
        transport: (params: any, success: any, failure: any): any => {
            this.dispatchProjectMaterialRequisitionProxy
                .GetMaterialRequisitionProjectResources(this.dispatchProjectCode, settingHelper.getSelectedDispatchModel())
                .then(
                    (result: any) => {
                        return success(result);
                    },
                    (fail: any) => {
                        return failure(fail);
                    }
                );
        },
        mapResults: (item: MaterialRequisitionProjectResourceModel): any => {
            return { id: item.Number, text: `${item.Number} - ${item.Description}`, data: item };
        },
    };

    constructor(private readonly i18n: I18N, private readonly dispatchProjectMaterialRequisitionProxy: DispatchProjectMaterialRequisitionProxy) {}

    public selectedResourceChanged(newValue: any): void {
        this.dispatchProjectMaterialRequisitionProxy
            .GetMaterialRequisitionProjectResourceItems(this.dispatchProjectCode, newValue.id, settingHelper.getSelectedDispatchModel())
            .then((result: MaterialRequisitionItemModel[]) => {
                this.resourceItems = result;
                this.isResourceSelected = false;
            });
    }

    public saveResourceItems(): void {
        const validItemsForSave = this.getValidResourceItems();

        if (!this.isResourceSelected && this.isSaveAllowed(validItemsForSave)) {
            const resourceItems: MaterialRequisitionItemModel[] = this.updateResourceItemsInfo(validItemsForSave);
            this.dispatchProjectMaterialRequisitionProxy
                .SaveMaterialRequisitionResourceItemsForDispatchProject(this.dispatchProjectCode, this.requisitionId, resourceItems)
                .then(() => {
                    navigateBack();
                });
        }
    }

    public resetQuantityResourceItems(): void {
       this.resourceItems.forEach((item: MaterialRequisitionItemModel) => {
           item.QuantityRequired = 0;
       });
    }

    public activate(params: any): void {
        this.dispatchProjectCode = params.dispatchProjectCode;
        this.requisitionId = params.requisitionId;
        this.requisitionDate = dateHelper.dateFromNavigationParameter(params.date);
        this.lineNo = params.lineNo;
    }

    private isSaveAllowed(validItemsForSave: MaterialRequisitionItemModel[]): boolean {
        if (validItemsForSave.length < 1) {
            notificationHelper.showWarning(this.i18n.tr("WarningMustHaveOne"));
            return false;
        }
        if (!this.requisitionDate) {
            notificationHelper.showWarning(this.i18n.tr("err_RequiredDateRequired"));
            return false;
        }
        return true;
    }

    private updateResourceItemsInfo(resourceItems: MaterialRequisitionItemModel[]): MaterialRequisitionItemModel[] {
        resourceItems.forEach((item: MaterialRequisitionItemModel) => {
            item.Id = this.requisitionId;
            item.RequiredDate = this.requisitionDate;
            item.Line = 0;
            // The saved Qauntity is the Required Quantity
            item.Quantity = item.QuantityRequired;
        });
        return resourceItems;
    }

    private getValidResourceItems(): MaterialRequisitionItemModel[] {
        return _.filter(
            this.resourceItems,
            (item: MaterialRequisitionItemModel): boolean => {
                return item.QuantityRequired > 0;
            }
        );
    }
}
