export enum AdditionalFieldsSourceType {
    DailyEntry,
    ServiceCall,
    FieldService,
    PurchaseOrder,
    ServiceQuotation,
    Requisition,
    CallReturn,
    ServiceCallSpecific,
}
