import { autoinject } from "aurelia-framework";
import moment from "moment";
import templateService from "services/templateService";
import { I18N } from "aurelia-i18n";
import { EventAggregator } from "aurelia-event-aggregator";

@autoinject
export class AddUnavailability {

    public dispatchProjectCode: string = "";
    public dispatchDate!: Date;
    public employeeId: number = 0;

    public startDate: Date = new Date();
    public endDate: Date = new Date();
    public increment: number = 0;
    public unAvailabilityId: number = 0;

    constructor(private readonly i18n: I18N, private readonly eventAggregator: EventAggregator) {
    }

    public async activate(params: any): Promise<any> {

        this.startDate = moment(params.startTime).toDate();
        this.endDate =  moment(params.endTime).toDate();
        this.employeeId = params.employeeId;

        if (params.unAvailabilityId) {
            this.unAvailabilityId = params.unAvailabilityId;
            this.eventAggregator.publish("updateTitle", this.i18n.tr("pageTitle_Calendar_Employee_EditNonAvailability"));
        }
       
        this.increment = await templateService.getUserTemplateIncrement();
    }
}
