import { default as recommendationService } from "services/recommendationService";
import "widgets/pages/recommendation_edit";

import { DispatchProjectService } from "services/dispatch-project-service";
import notificationHelper from "helpers/notificationHelper";
import { I18N } from "aurelia-i18n";
import { inject } from "aurelia-framework";

@inject(I18N, DispatchProjectService, notificationHelper)
export default class RecommendationEdit {
    dispatchProjectCode;
    dispatchDate;
    recommendationId = "";

    saveRecommendation;

    constructor(i18n, dispatchProjectService, notificationHelper) {
        this.dispatchProjectService = dispatchProjectService;
        this.notificationHelper = notificationHelper;
        this.i18n = i18n;
    }

    async canActivate(params) {
        this.dispatchProjectCode = params.dispatchProjectCode;

        try {
            await this.checkIsProjectActive();
        } catch (e) {
            if (e && e.response && e.response.data && e.response.data.Exception && e.response.data.Exception.ErrorId) {
                this.notificationHelper.showError(this.i18n.tr(`ApiError.${e.response.data.Exception.ErrorId}`), undefined, { timeOut: 0, extendedTimeOut: 0 });
            } else {
                this.notificationHelper.showError(e.response.data.Exception.Message, undefined, { timeOut: 0, extendedTimeOut: 0 });
            }
            return false;
        }
        return true;
    }

    activate = function (params) {
        this.bindViewModel(params.dispatchProjectCode, params.recommendationId);
    };

    bindViewModel(dispatchProjectCode, recommendationId) {
        this.recommendationId = recommendationId;
        this.dispatchProjectCode = dispatchProjectCode;

        this.saveRecommendation = recommendationService.addRecommendationForProject.bind(null, dispatchProjectCode);
    }

    async checkIsProjectActive() {
        await this.dispatchProjectService.GetProjectFromDispatchProjectCode(this.dispatchProjectCode);
    }
}
