import { inject, bindable } from "aurelia-framework";
import { I18N } from "aurelia-i18n";

import { default as _ } from "underscore";
import { default as routerHelper } from "helpers/routerHelper";
import { default as queryStringHelper } from "helpers/queryStringHelper";
import { default as ProductionBidItemService } from "services/production-bid-item-service";
import { default as notificationHelper } from "helpers/notificationHelper";
import { ProgressionType } from "api/enums/progression-type"

@inject(I18N, ProductionBidItemService, ProgressionType)
export default class ProductionBidItem {
    i18n;
    productionBidItemService;
    bidItems = [];
    changeNotices = [];
    dispatchProjectCode = null;
    dispatchDate = null;
    isProjectResponsible = false;
    readonly = true;

    @bindable commentOptions = {};

    constructor(i18n, productionBidItemService, progressionType) {
        this.i18n = i18n;
        this.productionBidItemService = productionBidItemService;
        this.progressionType = progressionType;

        this.commentOptions = {
            label: this.i18n.tr("Comment"),
            maxLength: 100,
        };
    }

    bindViewModel(dispatchProjectCode, dispatchDate, queryString) {
        this.isProjectResponsible = queryStringHelper.parseIsProjectResponsible(queryString);

        this.dispatchDate = dispatchDate;
        this.dispatchProjectCode = dispatchProjectCode;
    }

    async loadData() {
        const result = await this.productionBidItemService.getProductionBidItem(this.dispatchProjectCode, this.dispatchDate);

        this.loaditem(result);
    }

    loaditem(data) {
        this.readonly = data.IsReadOnly;
        this.bidItems = data.BidItems;
        this.changeNotices = data.ChangeOrders;
    }

    validateData() {
        let isValid = true;

        _.each(this.bidItems, item => {
            if (item.Quantity < 0) {
                isValid = false;
            }
        });

        _.each(this.changeNotices, item => {
            if (item.Quantity < 0) {
                isValid = false;
            }
        });

        if (!isValid) {
            notificationHelper.showValidationError(() => {
                return [this.i18n.tr("err_MinQuantity")];
            });
        }

        return isValid;
    }

    activate(params) {
        this.bindViewModel(params.dispatchProjectCode, params.dailyEntryDate, params.q);

        return this.loadData();
    }

    change(item) {
        if (item.Quantity === "") {
            item.Quantity = 0
        }
    }

    async save() {
        if (!this.validateData()) {
            return;
        }

        const data = this.bidItems.concat(this.changeNotices);

        routerHelper.showLoading();

        try {
            await this.productionBidItemService.saveProductionBidItem(this.dispatchProjectCode, this.dispatchDate, data);

            routerHelper.navigateBack();
        } finally {
            routerHelper.hideLoading();
        }
    }
}
