import { DispatchProjectService } from "services/dispatch-project-service";
import notificationHelper from "helpers/notificationHelper";
import { I18N } from "aurelia-i18n";

define([
    "helpers/queryStringHelper",
    "services/materialRequisitionService"
], function (queryStringHelper, materialRequisitionService) {
    "use strict";

    var viewModel = (function () {
        var self = null;

        ctor.inject = [I18N, DispatchProjectService, notificationHelper];

        function ctor(i18n, dispatchProjectService, notificationHelper) {
            self = this;
            //#region Properties
            self.actions = {};
            self.dispatchProjectCode = "";
            self.isProjectResponsible = false;
            self.i18n = i18n;
            self.dispatchProjectService = dispatchProjectService;
            self.notificationHelper = notificationHelper;
            //#endregion
        }

        //#region Private Function
        function bindViewModel(dispatchProjectCode, queryString) {
            self.isProjectResponsible = queryStringHelper.parseIsProjectResponsible(queryString);

            self.dispatchProjectCode = dispatchProjectCode;
        }

        function bindWidget() {
            self.actions = {
                getRequisitions: materialRequisitionService.getMaterialRequisitionsForProject.bind(self, self.dispatchProjectCode)
            };
        }
        //#endregion

        //#region Public Functions
        ctor.prototype.activate = function (params) {
            bindViewModel(params.dispatchProjectCode, params.q);
            bindWidget();
        };

        ctor.prototype.canActivate = async function (params) {
            self.dispatchProjectCode = params.dispatchProjectCode;

            try {
                await self.checkIsProjectActive();
            } catch (e) {
                if (e && e.response && e.response.data && e.response.data.Exception && e.response.data.Exception.ErrorId) {
                    self.notificationHelper.showError(self.i18n.tr(`ApiError.${e.response.data.Exception.ErrorId}`), undefined, { timeOut: 0, extendedTimeOut: 0 });
                } else {
                    self.notificationHelper.showError(e.response.data.Exception.Message, undefined, { timeOut: 0, extendedTimeOut: 0 });
                }
                return false;
            }

        };

        ctor.prototype.checkIsProjectActive = async function() {
            await self.dispatchProjectService.GetProjectFromDispatchProjectCode(this.dispatchProjectCode);
        }

        //#endregion
        return ctor;
    })();

    return viewModel;
});
