import { EventAggregator } from "aurelia-event-aggregator";
import { inject, transient } from "aurelia-dependency-injection";
import { bindable, observable, PLATFORM, computedFrom } from "aurelia-framework";
import { ValidationRules, ValidationControllerFactory, validateTrigger } from "aurelia-validation";
import { default as _ } from "underscore";
import { default as moment } from "moment";

import { NavigationContext } from "core/navigation-context";
import { default as resx } from "core/resx";
import { default as dateHelper } from "helpers/dateHelper";
import { default as enumHelper } from "helpers/enumHelper";
import { default as labelHelper } from "helpers/labelHelper";
import { default as notificationHelper } from "helpers/notificationHelper";
import { default as routerHelper } from "helpers/routerHelper";
import { default as stringHelper } from "helpers/stringHelper";
import { default as contactService } from "services/contactService";
import { default as defaultService } from "services/defaultService";
import { default as recommendationService } from "services/recommendationService";
import { default as settings } from "repositories/settingRepository";
import { default as serviceService } from "services/serviceService";
import documentService from "services/documentService";
import { CloneHelper } from "helpers/cloneHelper";

const contextPrefix = {
    recommendation: "recommendation_",
    project_recommendation: "project_recommendation_"
}

const predefinedSentenceOptionName = {
    projects: "projects",
    service_calls: "service_calls",
    fieldservices: "fieldservices",
    recommendations: "recommendations"
}

const contexts = {
    recommendations: "recommendations",
    project: "project",
    service_calls: "service_calls_S",
    service_workorder: "service_calls_W",
    fieldservices: "fieldservices"
}

@transient()
@inject(EventAggregator, ValidationControllerFactory, NavigationContext)
export class Recommendation_Edit {
    eventAggregator;
    recommendationStatusEnum;
    resx;

    context;
    dateTitle;
    fetchDefaultCustomer;
    fetchDefaultContact;
    fetchEquipments;
    fetchEquipmentDetails;
    saveRecommendation;
    updatePageSubtitle;

    predefinedSentencesEntityId = null;
    equipmentId = null;
    recommendationId = null;

    _parentReadonly = false;
    _statusLocked = false;

    today = dateHelper.getDate();
    typeList = [];
    topicList = [];
    pictures = [];
    equipmentDetail = "";
    templateResult = PLATFORM.moduleName("pages/templates/maSelectTemplates/recommendation_edit_result.html");
    templateSelection;
    serviceType = "S";

    client;
    equipment;
    @observable contact;
    contactName;
    description;
    followupComment;
    @observable followupContact;
    followupContactName;
    followupDate;
    nextFollowupDate;
    type;
    topic = enumHelper.recommendationTopics().OTHER.id;
    contactNameHR;
    project;
    recommendation;

    parentModel;
    unModifiedRecommendation = null;

    @computedFrom('context', 'topic', 'type', 'contact', 'contactName', 'equipment', 'contactHR', 'description', 'followupDate', 'followupContact', 'followupContactName', 'followupComment', '_statusLocked', 'unModifiedRecommendation')
    get isDirtyCheck() {
        if (!this.parentModel) {
            return false;
        }

        if (this._parentReadonly || this._statusLocked) {
            this.parentModel.isDirty = false;
            return this.parentModel.isDirty;
        }

        if (!this.unModifiedRecommendation) {
            this.parentModel.isDirty = false;
            return this.parentModel.isDirty;
        }

        const stringifyUnmodified = JSON.stringify(this.unModifiedRecommendation).replace(/[^0-9A-Z]+/gi, "");

        const current = this.buildRecommendationDto();
        const stringifyCurrent = JSON.stringify(current).replace(/[^0-9A-Z]+/gi, "");

        this.parentModel.isDirty = stringifyUnmodified !== stringifyCurrent;

        return this.parentModel.isDirty;
    }

    @computedFrom('recommendationId')
    get isAddMode() {
        return !this.recommendationId;
    }

    @computedFrom('topic')
    get isCurrentTopicGlobal(){
        if (!this.topic) return true;

        const topic = _.find(this.recommendationTopicsEnum, x => x.id === this.topic.id);
        return topic.global;
    }

    @computedFrom('context', 'topic')
    get isGlobalRecommendation(){
        return this.context === contexts.recommendations && this.isCurrentTopicGlobal;
    }

    @computedFrom('context', 'topic')
    get isProjectRecommendation(){
        return this.context === contexts.project || (this.topic && this.topic.id === this.recommendationTopicsEnum.PROJECT.id);
    }

    @computedFrom('context', 'topic', 'project')
    get isServiceCallRecommendation(){
        return this.context === contexts.service_calls || (this.topic && this.topic.id === this.recommendationTopicsEnum.SERVICECALL.id) || (this.topic && this.topic.id === this.recommendationTopicsEnum.WORKORDER.id && this.project !== "");
    }

    @computedFrom('context', 'topic', 'project')
    get isFieldServiceCallRecommendation() {
        return this.context === contexts.fieldservices || (this.topic && this.topic.id === this.recommendationTopicsEnum.WORKORDER.id && this.project === "");
    }

    @computedFrom('context', 'topic')
    get hideTopic() {
        return !this.isGlobalRecommendation;
    }

    @computedFrom('context', 'topic')
    get hideCustomer() {
        return this.isProjectRecommendation;
    }

    @computedFrom('context', 'topic')
    get hideContact() {
        return this.isProjectRecommendation;
    }

    @computedFrom('context', 'topic')
    get hideEquipment() {
        if (this.isServiceCallRecommendation && this.topic.id !== this.recommendationTopicsEnum.WORKORDER.id) return false;

        return (this.topic && this.topic.id !== this.recommendationTopicsEnum.EQUIPMENT.id);
    }

    @computedFrom('context', 'topic')
    get hideContactHR() {
        return (this.topic && this.topic.id !== this.recommendationTopicsEnum.HR.id);
    }

    @computedFrom('recommendationId')
    get hideFollowUp() {
        return this.isAddMode;
    }

    @computedFrom('client')
    get isContactListDisabled() {
        return !this.client;
    }

    @computedFrom('context', 'topic')
    get recommendationInOriginalContext(){
        return true;
    }

    @computedFrom('context', 'topic')
    get readonly(){
        if (this._parentReadonly || this._statusLocked){
            return true;
        }

        // Cannot edit project/service/fieldservice recommendation in the global section.
        return !this.recommendationInOriginalContext;
    }

    @computedFrom("context", "isProjectRecommendation", "isServiceCallRecommendation", "isFieldServiceCallRecommendation")
    get predefinedSentencesOption() {
        if (this.context === contexts.project || this.isProjectRecommendation) {
            this.context = contexts.project
            if (!this.predefinedSentencesEntityId) {
                this.predefinedSentencesEntityId = this.recommendation.Project;
            }
            return predefinedSentenceOptionName.projects;
        } else if (this.context === contexts.service_calls || this.context === contexts.service_workorder || this.isServiceCallRecommendation) {

            if (this.topic.id === this.recommendationTopicsEnum.SERVICECALL.id) {
                this.context = contexts.service_calls;
            }

            if (!this.predefinedSentencesEntityId) {
                if (this.recommendation.ServiceCallDispatchId) {
                    this.predefinedSentencesEntityId = this.recommendation.ServiceCallDispatchId;
                } else if (this.recommendation.WorkOrderDispatchId) {
                    this.predefinedSentencesEntityId = this.recommendation.WorkOrderDispatchId;
                }
            }
            return predefinedSentenceOptionName.service_calls;
        } else if (this.context === contexts.fieldservices || this.isFieldServiceCallRecommendation) {
            if (!this.predefinedSentencesEntityId) {
                this.predefinedSentencesEntityId = this.recommendation.WorkOrderId;
            }
            return predefinedSentenceOptionName.fieldservices;
        }

        return predefinedSentenceOptionName.recommendations;
    }

    clientChanged(newValue) {
        if (!newValue) {
            this.contact(null);
            this.contactName("");
        }
    }

    contactChanged(newValue) {
        this.contactName = newValue ? newValue.text : "";
    }

    followupContactChanged(newValue) {
        this.followupContactName = newValue ? newValue.text : "";
    }

    constructor(eventAggregator, validationControllerFactory, navigationContext, i18n, router) {
        this.eventAggregator = eventAggregator;
        this.resx = resx;
        this.recommendationStatusEnum = enumHelper.recommendationStatus();
        this.recommendationTopicsEnum = enumHelper.recommendationTopics();
        this.validationController = validationControllerFactory.createForCurrentScope();
        this.validationController.validateTrigger = validateTrigger.manual;
        this.i18n = i18n;
        this.router = router;

        this.navigationContext = navigationContext;

        this.templateSelection = (item) => this.tSelection(item);

        // Validation
        ValidationRules
            .ensure(x => x.description).required().withMessageKey("err_DescriptionRequired")
            .on(this);

        //Mapping functions
        this.map = {
            contact: (item) => {
                return {
                    id: item.Id,
                    text: item.Id + " - " + item.FullName
                };
            },
            client: (item) => {
                return {
                    id: item.Id,
                    // Id is already in description
                    text: item.Description
                };
            },
            types: (item) => {
                return {
                    id: item.Id,
                    text: item.Id + " - " + item.Name
                };
            },
            equipment: (item) => {
                if (this.context === "recommendations") {
                    return {
                        id: item.Code,
                        text: (!stringHelper.startsWith(item.Code, item.Description) ? item.Code + " - " : "") +
                            item.Description
                    };
                } else {
                    return {
                        id: item.Id,
                        Code: item.Code,
                        Description: item.Description,
                        text: item.Code + " - " + item.Description,
                        Serial: item.Serial,
                        Brand: item.Brand,
                        Model: item.Model,
                        Location: item.Location,
                        CustomerReference: item.CustomerReference,
                        Quantity: item.Quantity
                    };
                }
            },
            contactHR: (item) => {
                return {
                    id: item.Id,
                    text: item.Id + " - " + item.FullName
                };
            },
            topic: item => {return { id: item.id, text: item.label };}
        };

        //AJAX
        this.lookupContact = {
            transport: (params, success, failure) => {
                contactService.getLookupByCustomerCode(this.client.id,
                        params.data.filter,
                        params.data.page || 1)
                    .done(success)
                    .fail(failure);
            },
            mapResults: this.map.contact
        };

        this.lookupContactHR = {
            transport: (params, success, failure) => {
                contactService.getContactsLookup(params.data.filter, params.data.page || 1, true)
                    .done(success)
                    .fail(failure);
            },
            mapResults: this.map.contactHR
        };

        this.lookupEquipment = {
            transport: (params, success, failure) => {
                this.fetchEquipments(params.data.filter, params.data.page || 1)
                    .done(success)
                    .fail(failure);
            },
            mapResults: this.map.equipment
        };
    }

    activate(widgetOptions) {
        this.clear();
        this.bindViewModel(widgetOptions);

        this.subscription = this.eventAggregator.subscribe("document-upload-finish", () => this.reloadDocuments());

        return this.loadData();
    }

    attached() {

    }

    detached() {
        if (this.subscription) {
            this.subscription.dispose();
        }
    }

    tSelection(item) {
        this.equipmentDetail = item;
        return item.text;
    }

    bindViewModel(activationData) {
        this.context = activationData.context;

        this.fetchDefaultCustomer = activationData.fetchDefaultCustomer;
        this.fetchDefaultContact = activationData.fetchDefaultContact;
        this.fetchEquipments = activationData.fetchEquipments;
        this.fetchEquipmentDetails = activationData.fetchEquipmentDetails;
        this.saveRecommendation = activationData.saveRecommendation;
        this.updatePageSubtitle = activationData.updatePageSubtitle;
        this.recommendationId = activationData.recommendationId;
        this._parentReadonly = activationData.readonly;
        this.parentModel = activationData.parentModel;

        this.predefinedSentencesEntityId = activationData.predefinedSentencesEntityId;
        this.equipmentId = activationData.equipmentId;

        this.deleteAction = documentService.deleteDocument.bind(documentService);
    }

    reloadDocuments() {
        if (this.recommendationId === undefined) {
            return;
        }
        recommendationService.getRecommendation(this.recommendationId).done((data) => {
            this.pictures = data.Pictures;
        });
    }

    async loadData() {
        const typesPromise = recommendationService.getRecommendationTypes().promise();
        const customerPromise = this.fetchDefaultCustomer ? this.fetchDefaultCustomer().promise() : null;
        const contactPromise = this.fetchDefaultContact ? this.fetchDefaultContact().promise() : null;
        const recommendationPromise = this.recommendationId ? recommendationService.getRecommendation(this.recommendationId).promise() : null;
        // Note: Si on ouvre une recommandation d'équipement dans la section globale, pour le moment fetchEquipmentDetails ne sera pas défini et les détails de l'équipement ne s'afficheront pas.
        const equipmentPromise = this.equipmentId && this.fetchEquipmentDetails ? this.fetchEquipmentDetails(this.equipmentId).promise() : null;

        const [types, customer, contact, recommendation, equipment] =
        await Promise.all([typesPromise, customerPromise, contactPromise, recommendationPromise, equipmentPromise]);

        this.recommendation = recommendation;
        this.initializeDefaultCustomer(customer);
        this.initializeDefaultContact(contact);
        this.initializeTypes(types, recommendation);
        this.initializeData(recommendation);
        this.initializeTopic(recommendation);

        if (this.recommendation && this.recommendation.ServiceCallDispatchId) {
            this.fetchEquipments = serviceService.getEquipments.bind(null, this.serviceType, this.recommendation.ServiceCallDispatchId);
            this.fetchEquipmentDetails = serviceService.getEquipmentDetails.bind(null, this.serviceType, this.recommendation.ServiceCallDispatchId);
        }

        await this.initializeEquipmentDetail(equipment, recommendation);
        this.project = recommendation ? recommendation.Project : null;

        if (this.recommendation) {
            this.unModifiedRecommendation = this.buildRecommendationDto();
        }
    }

    initializeData(data) {
        if (data) {
            this._statusLocked = +data.Status === this.recommendationStatusEnum.ONGOING.id || +data.Status === this.recommendationStatusEnum.COMPLETE.id;
            this.dateTitle = dateHelper.getFullTextDate(data.DateEntered);
            if (this.updatePageSubtitle) {
                this.updatePageSubtitle(this.dateTitle);
            }

            this.bindRecommendationData(data);

            this.pictures = data.Pictures;
        } else {
            this._statusLocked = false;
            this.dateTitle = dateHelper.getFullTextDate(moment());
            if (this.updatePageSubtitle) {
                this.updatePageSubtitle(this.dateTitle);
            }
            this.pictures = [];
        }
    }

    initializeTypes(types, recommendation) {
        const typeId = recommendation ? recommendation.Type : null;

        this.typeList = _.map(types, this.map.types);
        this.type = _.find(this.typeList, x => x.id === typeId);
    }

    initializeTopic(recommendation) {
        const topicId = recommendation ? recommendation.Topic : null;

        this.topicList = _.filter(enumHelper.recommendationTopics(), item => item.global || item.id === topicId)
            .map(this.map.topic);

        if (topicId) {
            this.topic = _.find(this.topicList, x => x.id === topicId);
        }
    }

    initializeDefaultCustomer(customer) {
        this.client = customer ? this.map.client(customer) : null;
    }

    async initializeEquipmentDetail(equipment, recommendation) {
        // Note: Si on ouvre une recommandation d'équipement dans la section globale, pour le moment fetchEquipmentDetails ne sera pas défini et les détails de l'équipement ne s'afficheront pas.
        if (!equipment && recommendation && recommendation.EquipmentId && this.fetchEquipmentDetails) {
            equipment = await this.fetchEquipmentDetails(recommendation.EquipmentId).promise();
        }

        if (equipment){
            this.equipment = { id: equipment.Id, code: equipment.Code, text: equipment.Code + " - " + equipment.Description };
            this.equipmentDetail = equipment;
        } else if (recommendation && recommendation.EquipmentCode) {
            this.equipment = { id: recommendation.EquipmentCode, text: recommendation.EquipmentCode + " - " + recommendation.EquipmentDescription };
        }
    }

    initializeDefaultContact(defaultContact) {
        if (defaultContact) {
            this.contact = this.map.contact(defaultContact);
        } else {
            this.contact = null;
        }
    }

    bindRecommendationData(data) {
        if (data.CustomerCode) {
            this.client = { id: data.CustomerCode, text: data.CustomerName };
        }

        if (data.ContactNo) {
            this.contact = { id: data.ContactNo, text: data.ContactName };
        } else {
            this.contactName = data.ContactName;
        }

        this.description = data.Description;
        this.followupDate = data.FollowUpDate ? new Date(data.FollowUpDate) : null;
        this.nextFollowupDate = data.NextFollowUpDate ? new Date(data.NextFollowUpDate) : null;

        if (data.FollowUpContactNo) {
            this.followupContact = { id: data.FollowUpContactNo, text: data.FollowUpContactName };
        } else {
            this.followupContactName = data.FollowUpContactName;
        }

        if (data.ContactHR) {
            this.contactHR = { id: data.ContactHR, text: data.ContactHR + " - " + data.ContactNameHR };
        } else {
            this.contactNameHR = data.ContactNameHR;
        }

        this.followupComment = data.FollowUpComment;
    }

    clearRecommendationData() {
        this.client = null;
        this.contact = null;
        this.contactName = "";
        this.description = "";
        this.followupDate = null;
        this.nextFollowupDate = null;
        this.followupContact = null;
        this.followupContactName = "";
        this.followupComment = "";
        this.equipment = null;
        this.contactHR = null;
        this.contactNameHR = "";
        this.equipmentDetail = "";
        this.type = null;
        this.topic = enumHelper.recommendationTopics().OTHER;
        this.project = null;
        this.unModifiedRecommendation = this.buildRecommendationDto();
    }

    buildRecommendationDto() {
        const data = {
            ContactName: this.contactName || "",
            CustomerCode: this.client ? this.client.id : "",
            Description: this.description,
            FollowUpComment: this.followupComment || "",
            FollowUpContactName: this.followupContactName || "",
            Type: (this.type && this.type.id) ? this.type.id : "",
            Topic: this.topic ? this.topic.id : 0,
            Project: this.project
        };

        if (this.contact) {
            data.ContactNo = this.contact.id;
        }

        if (this.followupContact) {
            data.FollowUpContactNo = this.followupContact.id;
        }

        if (this.followupDate) {
            data.FollowUpDate = this.followupDate;
        }

        if (this.nextFollowupDate) {
            data.NextFollowUpDate = this.nextFollowupDate;
        }

        if (this.recommendationId) {
            data.RecommendationId = this.recommendationId;
        }

        if (!this.hideEquipment && this.equipment) {
            if (this.context !== "recommendations") {
                data.EquipmentId = this.equipment.id;
                data.EquipmentCode = this.equipmentDetail.Code;
            } else {
                data.EquipmentCode = this.equipment.id;
            }
        }

        if (this.contactHR) {
            data.ContactHR = this.contactHR.id;
        }

        return data;
    }

    goToPictureAdd(recommendationId) {
        this.checkIfCanLeavePage().then((confirm) => {
            if (confirm) {
                const route = this.navigationContext.getCurrentRouteName() + '_Documents_Add';
                routerHelper.navigateToRoute(route, this.buildNavigationParameters(recommendationId, { subTitle: this.dateTitle }));
            }
        });
    }

    async checkIfCanLeavePage() {
        if (this.isDirtyCheck) {
            const msgWarning = this.resx.localize("msg_UnsavedChangedWillBeLostConfirmation");
            const confirm = await notificationHelper.showDialogYesNo(msgWarning);
            if (!confirm) {
                return false;
            }
            this.parentModel.isDirty = false;
        }
        return true;
    }

    async save(addPicture) {
        const result = await this.validationController.validate();

        if (result.valid) {
           this.executeSave(addPicture);
        } else {
            const error = _.pluck(result.results, "message");
            notificationHelper.showValidationError(error);
        }
    }

    executeSave(addPicture) {
        routerHelper.showLoading();

        this.saveRecommendation(this.buildRecommendationDto())
            .done((recommendationId) => {
                this.unModifiedRecommendation = null;
                if (addPicture) {
                    const route = this.navigationContext.getCurrentRouteName();
                    const navigationParams = this.buildNavigationParameters(recommendationId);
                    routerHelper.navigateToRoute(route, navigationParams, { replace: true, trigger: false });

                    this.goToPictureAdd(recommendationId);
                } else {
                    routerHelper.navigateBack();
                }
            })
            .always(routerHelper.hideLoading);
    }

    clear() {
        this.clearRecommendationData();
        this.recommendationId = "";
    }

    addPicture() {
        if (!this.recommendationId) {
            this.save(true);
        } else {
           this.goToPictureAdd(this.recommendationId);
        }
    }

    buildNavigationParameters(recommendationId, additionnalQueryStringParameters) {
        const navigationParams = this.navigationContext.getParameters();
        let queryParams = this.navigationContext.getQueryParameters();

        queryParams = routerHelper.getQuerystring(queryParams);
        queryParams = _.extend(queryParams, additionnalQueryStringParameters);
        queryParams = { q: routerHelper.buildQueryString(queryParams)};

        const navigationParameters = _.extend({},navigationParams, queryParams);

        navigationParameters.recommendationId = recommendationId;
        navigationParameters.option = this.predefinedSentencesOption;
        navigationParameters.contextPrefix = this.context === contexts.project ? contextPrefix.project_recommendation : contextPrefix.recommendation;
        navigationParameters.editId = this.predefinedSentencesEntityId ? this.predefinedSentencesEntityId : recommendationId;

        return navigationParameters;
    }
}
