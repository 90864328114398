import routerHelper from "helpers/routerHelper";
import { autoinject } from "aurelia-framework";
import dispatchService from "services/dispatchService";
import enumHelper from "helpers/enumHelper";

@autoinject()
export class AttendancesAbsence {

    public dispatchId: string = "";
    public dispatchProjectCode: string = "";
    public dispatchDate: string = "";
    public reason: string = "";

    public activate(params: any): any {
        this.clear();
        this.bindViewModel(params);
    }

    public clear(): void {
        this.reason = "";
    }

    public setReason(): any {
        dispatchService.setAttendanceStatus(this.dispatchId, { Presence: enumHelper.attendance().ABSENT, AbsenceReason: this.reason })
            .done(routerHelper.navigateBack);
    }

    private bindViewModel(params: any): any {
        this.dispatchDate = params.dailyEntryDate;
        this.dispatchProjectCode = params.dispatchProjectCode;
        this.dispatchId = params.dispatchId;
    }
}
