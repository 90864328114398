import { ProjectActivityProgressModel } from "api/models/company/project/project-activity-progress-model";
import { autoinject } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import notificationHelper from "helpers/notificationHelper";
import queryStringHelper from "helpers/queryStringHelper";
import routerHelper from "helpers/routerHelper";
import activityService from "services/activityService";

@autoinject
export class DailyEntryProjectProgressEdit {

    public dispatchDate: Date = new Date();
    public dispatchProjectCode: string = "";
    public activity!: ProjectActivityProgressModel;
    public readonly: boolean = false;
    public rate: number = 0;
    public isProjectResponsible: boolean = false;

    constructor(private readonly i18n: I18N) {
    }

    public activate(params: any): any {

        this.bindViewModel(params.dispatchProjectCode, params.dailyEntryDate, params.q);
        this.loadData(params.id);
    }

    public async loadData(activityId: string): Promise<any> {
        this.activity = await activityService.getActivityProgressForDailyEntry(this.dispatchProjectCode, this.dispatchDate, activityId);
        this.rate = this.activity.Rate ? this.activity.Rate : 0;
    }

    public bindViewModel(dispatchProjectCode: string, dispatchDate: Date, querystring: string): void {
        this.readonly = queryStringHelper.parseReadonly(querystring);
        this.isProjectResponsible = queryStringHelper.parseIsProjectResponsible(querystring);

        this.dispatchProjectCode = dispatchProjectCode;
        this.dispatchDate = dispatchDate;
    }

    public isRateValid(): boolean {
        if (this.rate < 0 || this.rate > 100) {
            notificationHelper.showError(this.i18n.tr("err_RateBetween0And100"));
            return false;
        }
        return true;
    }

    public async save(): Promise<void> {
        if (this.isRateValid()) {
            await activityService.setActivityProgressForDailyEntry(this.dispatchProjectCode, this.dispatchDate, this.activity.Id!, this.rate);
            routerHelper.navigateBack();
        }
    }
}
