// GENERATED FILE - DO NOT MODIFY
import { ShopDrawingLightModel } from "api/models/company/shop-drawing-light-model";
import { ShopDrawingModel } from "api/models/company/shop-drawing-model";
import { PagingInfo } from "api/paging-info";
import BaseProxy from "api/proxies/base-proxy";
import ModelDateTransformer from "api/util/model-date-transformer";
import { autoinject } from "aurelia-framework";
import { ApiHelper } from "helpers/api-helper";
import { HttpClientFactory } from "http-client/http-client-factory";
import { HttpClientRequestConfig } from "http-client/http-client-request-config";

@autoinject()
export class ShowDrawingProxy extends BaseProxy {
    constructor(modelDateTransformer: ModelDateTransformer, httpClientFactory: HttpClientFactory, apiHelper: ApiHelper) {
        super(modelDateTransformer, httpClientFactory, apiHelper);
    }

    public async GetShopDrawings(projectDispatchCode: string | null, filter?: string, pagingInfo?: PagingInfo, requestConfig?: HttpClientRequestConfig): Promise<ShopDrawingLightModel[] | null> {
        const uri = this.buildUri(
            "/shop-drawings/{projectDispatchCode}",
            { projectDispatchCode: (projectDispatchCode === null || projectDispatchCode === undefined) ? undefined : projectDispatchCode },
            { filter: filter ? filter : undefined, page: (pagingInfo && pagingInfo.page ? pagingInfo.page.toString() : undefined), pagesize: (pagingInfo && pagingInfo.pageSize ? pagingInfo.pageSize.toString() : undefined) }
        );

        const data: ShopDrawingLightModel[] | null = await this.get<ShopDrawingLightModel[] | null>(uri, requestConfig);
        this.transformModels(data, "Company.ShopDrawingLightModel");
        return data;
    }

    public async GetShopDrawing(drawingId: number, requestConfig?: HttpClientRequestConfig): Promise<ShopDrawingModel | null> {
        const uri = this.buildUri(
            "/shop-drawing/{drawingId}",
            { drawingId: (drawingId === null || drawingId === undefined) ? undefined : drawingId.toString() },
            null
        );

        const data: ShopDrawingModel | null = await this.get<ShopDrawingModel | null>(uri, requestConfig);
        this.transformModels(data, "Company.ShopDrawingModel");
        return data;
    }
}
