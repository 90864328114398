import { Viewbag } from "helpers/view-bag";
import notificationHelper from "helpers/notificationHelper";
import { autoinject, computedFrom } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import routerHelper from "helpers/routerHelper";
import applicationInsightsService from "services/applicationInsightsService";
import { GeolocationService } from "services/geolocation-service";
import weatherService from "services/weatherService";
import { StringHelper } from "helpers/string-helper";
import dailyEntryService from "services/dailyEntryService";

@autoinject
export class DailyEntryWeatherEdit {

    public enumType: any = {
        WEATHER: this.i18n.tr("msg_Weather_TypeRequired"),
        WIND: this.i18n.tr("msg_Weather_WindForceRequired")
    };

    public enumPeriod: any = {
        DAY: this.i18n.tr("msg_Weather_fortheday"),
        EVENING: this.i18n.tr("msg_Weather_fortheevening"),
        NIGHT: this.i18n.tr("msg_Weather_forthenight")
    };

    public id: string = "";
    public weatherButtonDisable: boolean = false;
    public entityId: any = null;
    public dispatchProjectCode: string = "";
    public dispatchDate: string = "";
    public periodSelected: string = "day";
    public isProjectResponsible: any;
    public readonly: boolean = true;

    @computedFrom("periodSelected")
    public get daySelected(): boolean {
        return this.periodSelected === "day";
    }

    @computedFrom("periodSelected")
    public get eveningSelected(): boolean {
        return this.periodSelected === "evening";
    }

    @computedFrom("periodSelected")
    public get nightSelected(): boolean {
        return this.periodSelected === "night";
    }

    public dayWeatherReload: any;
    public eveningWeatherReload: any;
    public nightWeatherReload: any;

    public dayWeather: { weatherType: number, windForce: number, degree: number } = { weatherType: 0, windForce: 0, degree: 0 };
    public eveningWeather: { weatherType: number, windForce: number, degree: number } = { weatherType: 0, windForce: 0, degree: 0 };
    public nightWeather: { weatherType: number, windForce: number, degree: number } = { weatherType: 0, windForce: 0, degree: 0 };

    constructor(private readonly i18n: I18N, public viewbag: Viewbag, public geolocationService: GeolocationService) {
        this.i18n = i18n;
    }

    public activate(params: any): any {
        const paramsQueryString = routerHelper.getQuerystring(params.q);
        const vBag = this.viewbag.value;

        this.isProjectResponsible = (paramsQueryString && paramsQueryString.isProjectResponsible === "true") || false;
        this.readonly = !(paramsQueryString && paramsQueryString.readonly === "false");
        this.dispatchDate = params.dailyEntryDate;
        this.dispatchProjectCode = params.dispatchProjectCode;
        this.weatherButtonDisable = false;

        if (!vBag) {
            routerHelper.navigateBack();
            return false;
        }

        this.mapDataFromViewBag(vBag);
    }

    public isValid(): boolean {
        if (this.isWeatherValid(this.dayWeather, this.enumPeriod.DAY)
            && this.isWeatherValid(this.eveningWeather, this.enumPeriod.EVENING)
            && this.isWeatherValid(this.nightWeather, this.enumPeriod.NIGHT)) {
            return true;
        }
        return false;
    }

    public isWeatherValid(weather: any, period: string): boolean {
        let message = "";
        if (weather.windForce > 0 && weather.weatherType === 0) {
            message = this.enumType.WEATHER + " " + period;
            notificationHelper.showError(message);
            return false;
        }
        if (weather.weatherType > 0 && weather.windForce === 0) {
            message = this.enumType.WIND + " " + period;
            notificationHelper.showError(message);
            return false;
        }
        return true;
    }
    public setPeriodSelected(period: string): void {
        this.periodSelected = period;
    }

    public clear(): void {
        this.dayWeather = { weatherType: 0, windForce: 0, degree: 0 };
        this.eveningWeather = { weatherType: 0, windForce: 0, degree: 0 };
        this.nightWeather = { weatherType: 0, windForce: 0, degree: 0 };
    }

    public async updateWeather(): Promise<void> {
        if (this.daySelected || this.eveningSelected || this.nightSelected) {
            applicationInsightsService.trackEvent("Get Weather From External Api");

            await this.geolocationService.getCurrentLocation().then(async (position: any) => {
                await weatherService.getCurrentTemperature(position).then((data: any) => {
                    this.mapDataFromWeatherApi(data);
                });
            }).catch((ex: any) => {
                this.weatherButtonDisable = true;
                if (StringHelper.startsWith(ex.message, "Only secure origins are allowed") || ex.message.indexOf("Access to geolocation was blocked over insecure connection") > -1) {
                    notificationHelper.showWarning(this.i18n.tr("LocationRequireSSL"));
                } else {
                    notificationHelper.showWarning(this.i18n.tr("LocationDisabledUnableUpdateWeather"));
                }
            });
        }
    }

    public async saveWeather(): Promise<void> {
        if (this.isValid()) {
            const data = this.mapDataToPost();

            if (data) {
                await dailyEntryService.setWeather(this.dispatchProjectCode, this.dispatchDate, data).then(() => routerHelper.navigateBack());
            } else {
                notificationHelper.showWarning(this.i18n.tr("msg_WeatherRequired"));
            }

        }
    }

    public mapDataToPost(): any {
        const isTempDay = this.dayWeather.weatherType > 0 || this.dayWeather.windForce > 0;
        const isTempEvening = this.eveningWeather.weatherType > 0 || this.eveningWeather.windForce > 0;
        const isTempNight = this.nightWeather.weatherType > 0 || this.nightWeather.windForce > 0;

        if (!isTempDay && !isTempEvening && !isTempNight) {
            return undefined;
        }

        return {
            TemperatureTypeAm: isTempDay ? this.dayWeather.weatherType : 0,
            TemperatureWindAm: isTempDay ? -this.dayWeather.windForce : 0,
            TemperatureDegreeAm: isTempDay ? this.dayWeather.degree : null,

            TemperatureTypeEvening: isTempEvening ? this.eveningWeather.weatherType : 0,
            TemperatureWindEvening: isTempEvening ? -this.eveningWeather.windForce : 0,
            TemperatureDegreeEvening: isTempEvening ? this.eveningWeather.degree : null,

            TemperatureTypeNight: isTempNight ? this.nightWeather.weatherType : 0,
            TemperatureWindNight: isTempNight ? -this.nightWeather.windForce : 0,
            TemperatureDegreeNight: isTempNight ? this.nightWeather.degree : null
        };
    }

    public mapDataFromViewBag(data: any): void {
        this.periodSelected = data.periodSelected;

        this.dayWeather = data.value.dayWeather;
        this.eveningWeather = data.value.eveningWeather;
        this.nightWeather = data.value.nightWeather;
    }

    public mapDataFromWeatherApi(data: any): void {
        if (this.daySelected) {
            this.dayWeather = data;
            this.dayWeatherReload = data;
        }
        if (this.eveningSelected) {
            this.eveningWeather = data;
            this.eveningWeatherReload = data;
        }
        if (this.nightSelected) {
            this.nightWeather = data;
            this.nightWeatherReload = data;
        }
    }

}
