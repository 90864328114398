import { transient } from "aurelia-framework";

import { default as _ } from "underscore";
import { default as jQuery } from "jquery";
import { default as resx } from "core/resx";
import { default as dateHelper } from "helpers/dateHelper";
import { default as labelHelper } from "helpers/labelHelper";
import { default as notificationHelper } from "helpers/notificationHelper";
import { default as enumHelper } from "helpers/enumHelper";
import { default as routerHelper } from "helpers/routerHelper";
import { default as defaultService } from "services/defaultService";

@transient()
export class Recommendation {
    RECOMMENDATION_STATUS = enumHelper.recommendationStatus();
    resx = resx;
    dateHelper = dateHelper;
    enumHelper = enumHelper;
    dateGroups = [];
    dateGroupsByStatus = {};
    sectionShown;
    recommendationStatus = [];
    recommendationStatusValues = [];
    masterEntityId = 0;
    fetchRecommendations;    // : Promise<>;
    showTopics = false;
    readonly = false;
    filter = "";

    scrollMessageText = resx.localize("ScrollForMore");
    isScrollMessageVisible = false;
    loadingStatus = {}; // True if the end of the infinite scrolling is reached for current tab

    constructor() {
        this.sectionShown = this.RECOMMENDATION_STATUS.ENTERED.id;
    }

    activate(widgetOptions) {
        this.bindViewModel(widgetOptions);

        return this.loadData(this.sectionShown);
    }

    detached() {
        this.disposeAll();
    }

    tabClicked(status) {
        this.sectionShown = status.id;

        const cachedList = this.getCachedList(this.sectionShown);

        if (!cachedList) {
            this.loadData(status.id);
        } else {
            this.dateGroups = cachedList;

            if (this.loadingStatus[status.id]) {
                this.disposeScroll();
            } else {
                this.initScroll();
            }
        }
    }

    getStatusLabel(status) {
        switch (+status) {
            case this.RECOMMENDATION_STATUS.COMPLETE.id:
                return resx.localize("Completed_F");

            case this.RECOMMENDATION_STATUS.ONGOING.id:
                return this.RECOMMENDATION_STATUS.ONGOING.label;

            default:
                return this.RECOMMENDATION_STATUS.ENTERED.label;
        }
    }

    getStatusIcon(status) {
        switch (+status) {
            case this.RECOMMENDATION_STATUS.COMPLETE.id:
                return "fa fa-check";

            case this.RECOMMENDATION_STATUS.ONGOING.id:
                return "ma ma-ongoing";

            default:
                return "fa fa-hourglass-start";
        }
    }

    getTypeTitle(recommendation) {
        const refId = (recommendation.WorkOrderId
            ? recommendation.WorkOrderId
            : (recommendation.ServiceCallId ? recommendation.ServiceCallId : ""));

        return refId + (refId && recommendation.Type ? " - " : "") + recommendation.Type;
    }

    getTopicTitle(recommendation) {
        if (!recommendation.Topic) {
            return "";
        }

        const topic = _.find(enumHelper.recommendationTopics(), (x) => x.id === recommendation.Topic);

        if (!topic || !topic.label) {
            return "";
        }

        return topic.label;
    }

    gotoPage(recommendation) {
        let url;

        if (recommendation) {
            url = routerHelper.getRelativeUrl("edit", recommendation.RecommendationId) + routerHelper.addQuerystring({ entityId: this.masterEntityId, readonly: this.readonly });
        } else {
            url = routerHelper.getRelativeUrl("edit") + routerHelper.addQuerystring({ entityId: this.masterEntityId });
        }

        routerHelper.navigate(url);
    }

    bindViewModel(widgetOptions) {
        this.readonly = widgetOptions.readonly || false;
        this.showTopics = widgetOptions.showTopics || false;
        this.recommendationStatus = this.RECOMMENDATION_STATUS;
        this.recommendationStatusValues = _.values(this.RECOMMENDATION_STATUS);

        this.masterEntityId = widgetOptions.masterEntityId || 0;

        this.fetchRecommendations = widgetOptions.fetchRecommendations;

        this.dateGroupsByStatus = {};

        this.sectionShown = this.RECOMMENDATION_STATUS.ENTERED.id;
    }

    loadData(status) {
        this.page = 1;
        return this.getData(status, this.page).done((ls) => {
            this.setCachedList(this.sectionShown, ls);
            this.dateGroups = ls;
        });
    }

    getCachedList(statusId) {
        return this.dateGroupsByStatus[statusId];
    }

    setCachedList(statusId, ls) {
        this.dateGroupsByStatus[statusId] = ls;
    }

    async getData(status, page) {
        routerHelper.showLoading();

        const filter = this.filter ? this.filter : "";
        try {
            const result = await this.fetchRecommendations(status, filter, page);
            return this.getDataDone(result);
        } catch (exception) {
            this.disposeAll();
            throw exception;
        } finally {
            routerHelper.hideLoading();
        }
    }

    getDataDone(data) {
        data = this.filterRecommendationsByStatusId(data, this.sectionShown);
        const itemCount = _.reduce(data,
            (aggregate, cur) => {
                return cur.Recommendations.length + aggregate;
            },
            0);

        if (itemCount >= defaultService.getPageSize()) {
            this.loadingStatus[this.sectionShown] = false;
            this.initScroll();
        } else {
            this.loadingStatus[this.sectionShown] = true;
            this.disposeScroll();
        }

        _.each(data,
            (item) => {
                item.Recommendations;
            });

        return data;
    }

    filterRecommendationsByStatusId(data, statusId) {
        _.each(data,
            (datum) => {
                datum.Recommendations = _.filter(datum.Recommendations,
                    (recommendation) => {
                        return recommendation.Status == statusId;
                    });
            });

        return _.filter(data, (datum) => { return datum.Recommendations.length > 0; });
    }

    initScroll() {
        jQuery(document).scroll(this.scrollHandler);
        this.showScrollMessage("scroll");
    }

    disposeScroll() {
        jQuery(document).off("scroll", this.scrollHandler);
        this.hideScrollMessage();
    }

    joinList(baseList, list) {
        _.each(list,
            (item) => {
                var baseItemToMerge = _.find(baseList,
                    (baseItem) => {
                        return baseItem.Date === item.Date;
                    });

                if (baseItemToMerge) {
                    baseItemToMerge.Recommendations = _.union(baseItemToMerge.Recommendations, item.Recommendations);
                } else {
                    baseList.push(item);
                }
            });

        return baseList;
    }

    scrollHandler = async () => {
        if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
            this.disposeScroll();
            this.showScrollMessage("loading");
            this.page += 1;
            try {
                var ls = await this.getData(this.sectionShown, this.page);

                var actualLs = this.joinList(this.dateGroups, ls);
                this.dateGroups = actualLs;

                this.setCachedList(this.sectionShown, actualLs);
            } catch (error) {
                this.dateGroups = [];
            }
        }
    }

    showScrollMessage(msgId) {
        if (msgId === "loading") {
            this.scrollMessageText = resx.localize("LoadingMoreResults");
        } else {
            this.scrollMessageText = resx.localize("ScrollForMore");
        }

        this.isScrollMessageVisible = true;
    }

    hideScrollMessage() {
        this.isScrollMessageVisible = false;
    }

    disposeAll() {
        this.dateGroupsByStatus = {};
        this.dateGroups = [];
        this.disposeScroll();
    }

    searchRecommendation = async (filter) => {
        this.filter = filter;
        this.page = 1;
        this.disposeAll();
        return this.getData(this.sectionShown, this.page).done((ls) => {
            this.setCachedList(this.sectionShown, ls);
            this.dateGroups = ls;
        });
    }
}