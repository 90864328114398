import { autoinject } from "aurelia-framework";

import { default as notificationHelper } from "helpers/notificationHelper";

import { ProcoreConfigurationService } from "services/procore/procore-configuration-service";
import { ProcoreCompanyConfigurationModel } from "api/models/company/procore/procore-company-configuration-model";

import { ConfigurationAuthentification } from "./composition/authentification";
import { ConfigurationEntities } from "./composition/entities";
import { I18N } from "aurelia-i18n";
import { ProcoreEntityConfigurationProjectFilters } from "enums/project-filters";
import { SideBar } from "./composition/side-bar";
import { ConfigurationActivities } from "./composition/activities";
import { ConfigurationOrdersSynchronization } from "./composition/orders";
import { CanCloseWindow } from "interfaces/can-close-window";
import HostContext from "core/host-context";
import { ConfigurationJobSystem } from "./composition/jobsystem";

@autoinject()
export class ProcoreConfiguration implements CanCloseWindow {
  private self: ProcoreConfiguration = this;
  private saveModel!: ProcoreCompanyConfigurationModel;

  constructor(private readonly i18n: I18N,

              private readonly authentification: ConfigurationAuthentification,
              private readonly entities: ConfigurationEntities,
              private readonly sidebar: SideBar,
              private readonly activities: ConfigurationActivities,
              private readonly jobsystem: ConfigurationJobSystem,
              private readonly orders: ConfigurationOrdersSynchronization,

              private readonly procoreConfigurationService: ProcoreConfigurationService,

              private readonly hostContext: HostContext) {

    this.sidebar.configurations = this;
    this.authentification.configurations = this;
    this.activities.configurations = this;

    this.sidebar.Items.push({ code: "authentification", label: this.i18n.tr("Procore.Authentication"), isSelected: true});
    this.sidebar.selectedItem = this.sidebar.Items[0];
    this.sidebar.Items.push({ code: "entities", label: this.i18n.tr("Procore.Config_display_settings") });
    this.sidebar.Items.push({ code: "projects", label: this.i18n.tr("Projects") });
    this.sidebar.Items.push({ code: "orders", label: this.i18n.tr("Orders") });
    this.sidebar.Items.push({ code: "jobsystem", label: this.i18n.tr("Procore.JobSystem") });
  }

  public async canClose(): Promise<void> {
    const wasDirty = await this.ifIsDirtyShowPopup(true);
    if (!wasDirty) {
      (this.hostContext as any).viewModel.closeWindow();
    }
  }

  public async attached(): Promise<void> {
    (window as any).CurrentViewModel = this;

    await this.Load();
  }

  public async Load(): Promise<void> {
    await this.LoadLogic();
    this.saveModel = this.getSaveModel();
  }

  public async save(): Promise<boolean> {
    try {
      const errorMessage = this.entities.validate();
      if (errorMessage) {
          notificationHelper.showError(errorMessage, "");
          return false;
      }

      const newModel = this.getSaveModel();
      await this.procoreConfigurationService.SaveProcoreConfiguration(newModel);
      this.saveModel = newModel;
      notificationHelper.showSuccess(this.i18n.tr(`Procore.Status_Success`), "");

      return true;
    } catch {
      notificationHelper.showError(this.i18n.tr(`Procore.Config_Save_Error`), "");
      return false;
    }
  }

  public getSaveModel(): ProcoreCompanyConfigurationModel {
    return {
        // Authentification
        ProcoreApiUrl: this.authentification.isCustomUrl ? this.authentification.urlCustom
                                                         : this.authentification.urlSelected.text,
        ProcoreCompanyId: this.authentification.procoreCompanyId,
        ProcoreCompanyName: this.authentification.procoreCompanyName,

        // Entities
        EntitiesToSynchronize: this.entities.entities,

        ConcatenationModeEnabled: this.activities.concatModeEnabled,
        ConcatenationSeparator: this.activities.defaultSeparator.id,

        // Orders
        OrderSynchronizationOrderType: this.orders.SelectedOrderType

      } as ProcoreCompanyConfigurationModel;
  }

  public async resetFields(): Promise<void> {
    if (this.calculateIsDirty()) {
      await notificationHelper.showDialogResetFields(this.i18n.tr("Procore.ConfigurationsResetFields"))
      .then(async (success: any) => {
        if (success) {
          await this.Load();
        }
      });
    }
  }

  public calculateIsDirty(): boolean {
    return JSON.stringify(this.saveModel) !== JSON.stringify(this.getSaveModel());
  }

  public async ifIsDirtyShowPopup(closeWindow: boolean = false): Promise<boolean> {
      if (this.calculateIsDirty()) {
        let isSaveSuccessful = true;
        await notificationHelper.showDialogSaveCancel(this.i18n.tr("Procore.ConfigurationsDirty"))
        .then(async (success: any) => {
          if (success) {
            isSaveSuccessful = await this.save();
          }
        })
        .then(() => {
          if (!isSaveSuccessful) {
              return;
          }
          if (closeWindow) {
            (this.hostContext as any).viewModel.closeWindow();
          } else {
            this.Load();
          }
        });
        return true;
      }
      return false;
    }

    public async signIn(): Promise<void> {
        if (await this.save()) {
            await this.authentification.signIn();
        }
    }

  private async LoadLogic(): Promise<void> {
    try {
      const data = await this.procoreConfigurationService.GetProcoreConfiguration();
      const jobs = await this.procoreConfigurationService.GetAllJobStatuses();

      if (!data) {
        throw new Error();
      }

      await this.authentification.Load(data);
      this.entities.Load(data);
      this.activities.Load(data);
      this.orders.Load(data);
      this.jobsystem.Load(jobs);

    } catch {
      notificationHelper.showError(this.i18n.tr(`Procore.Config_Load_Error`), "");
    }
  }
}
