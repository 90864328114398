import { inject } from "aurelia-framework";
import { Redirect } from "aurelia-router";
import { I18N } from "aurelia-i18n";

import { default as apiHelper } from "helpers/apiHelper";
import { default as dateHelper } from "helpers/dateHelper";
import { default as notificationHelper } from "helpers/notificationHelper";
import { default as settingHelper } from "helpers/settingHelper";
import { default as queryStringHelper } from "helpers/queryStringHelper";

import { default as templateService } from "services/templateService";
import { default as purchaseOrderService } from "services/purchaseOrderService";
import { default as routerHelper } from "helpers/routerHelper";

@inject(I18N)
export default class MaterialOrderItem {

    dispatchId;
    lineNo = '';
    orderDate = null;
    orderId = '';
    extension = '';
    showNotInCatalog = false;
    readonly = false;
    dispatchProjectCode ="";
    dispatchDate = "";
    projectCode = "";

    constructor(i18n) {
        this.i18n = i18n;
    }

    setSpecificFields(item) {
        item.DispatchId = this.dispatchId;
        return item;
    }

    bindComponentActions() {
        this.actions = {
            setSpecificFields: item => this.setSpecificFields(item),
            savePurchaseOrderItem: (orderId, lineNo, item) => purchaseOrderService.setPurchaseOrderItemForProject(orderId, lineNo, item)
        };
    }

    canActivate() {
        if (!settingHelper.hasDispatchModel()) {
            notificationHelper.showWarning(this.i18n.tr("DispatchModelRequired"));
            return new Redirect("Settings");
        }
        return true;
    }

    activate(params) {
        this.dispatchId = 0;
        this.requisitionDate = dateHelper.dateFromNavigationParameter(params.orderDate);
        this.orderId = params.orderId;

        this.catalogBaseUrl = apiHelper.getBaseUrl() +
            "/catalog/dispatch/projects/materialrequisitions?dispatchTemplateId=" +
            settingHelper.getSelectedDispatchModel();

        this.lineNo = params.lineNo;

        if (params.q) {
            this.readonly = queryStringHelper.parseReadonly(params.q);

            const extension = queryStringHelper.parse(params.q, "extension");
            this.extension = extension ? extension : "";

            const qsParams = routerHelper.getQuerystring(params.q);
            this.dispatchProjectCode = qsParams.dispatchProjectCode;
            this.dispatchDate = qsParams.dispatchDate;
            this.projectCode = qsParams.projectCode;

            if (qsParams && qsParams.workOrderId) {
                this.workOrderId = qsParams.workOrderId && qsParams.workOrderId !== "undefined" ? qsParams.workOrderId : "";
            }

        }
        this.bindComponentActions();

        return templateService.getUserTemplateConfigs().done((configs) => {
            this.showNotInCatalog = configs.ShowNotInCatalogMaterialProject;
        });
    }
}