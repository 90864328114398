// GENERATED FILE - DO NOT MODIFY
export enum ProcoreEntityToSynchronize {
    Projects = 1,
    Suppliers = 2,
    PurchaseOrders = 3,
    ProjectCosts = 4,
    ProjectBudgets = 5,
    DirectCostItems = 6,
    OrderInvoices = 7,
    Contracts = 8,
    PrimeContractChangeOrders = 9,
    ChangeOrders = 10,
    ProjectBudgetsQuantities = 11,
    Employees = 12,
    DirectCostItemsLabor = 13,
    DirectCostItemsLaborName = 14,
    DirectCostItemsLaborTrade = 15,
    DirectCostItemsLaborBonusHours = 16,
    DirectCostItemsLaborBonusCost = 17,
    DirectCostItemsLaborTotal = 18,
    DirectCostItemsLaborComments = 19,
    DeleteDirectCostItems = 20,
    PccoClients = 21,
    BudgetModifications = 22,
    PrimeContracts = 23,
    PccoChangeOrders = 24,
}

export let nameof = "ProcoreEntityToSynchronize";
