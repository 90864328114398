define([
    "services/requests",
    "helpers/apiHelper"
], function (requests, apiHelper) {
    "use strict";

    var exports = {
        getEmployeesForDispatchForProject: function (dispatchProjectCode, dispatchDate) {
            return requests.getJson(apiHelper.getProjectDispatchEmployeesBaseUrl(dispatchProjectCode, dispatchDate));
        },

        getEmployeesForDispatch: function (dispatchId) {
            return requests.getJson(apiHelper.getDispatchBaseUrl(dispatchId) + "/employees");
        },

        getEmployeeFile: function(employeeId) {
            return requests.getJson(apiHelper.getBaseUrl() + "/employees/" + employeeId);
        },

        getNonAvailabilityTypes: function(filter, page) {
            var url = apiHelper.getBaseUrl() + "/unavailabilitytypes";

            if (filter) {
                url += "?filter=" + filter;
            }
            if (page) {
                url += (filter ? "&" : "?") + "page=" + page;
            }

            return requests.getJson(url);
        },

        setNonAvailabilityForEmployee: function(employeeId, data) {
            return requests.post(apiHelper.getBaseUrl() + "/employees/" + employeeId + "/unavailabilities", data);
        },

        deleteNonAvailabilityForEmployee: function (unAvailabilityId) {
            return requests.remove(apiHelper.getBaseUrl() + "/employees/" + unAvailabilityId + "/unavailability");
        },

        getNonAvailability: function (unAvailabilityId) {
            return requests.getJson(apiHelper.getBaseUrl() + "/employees/" + unAvailabilityId + "/unavailability");
        },

        getEmployeesForTemplate: function (templateId, filter, page) {
            var url = apiHelper.getBaseUrl() + "/employees/lookup";
            url = apiHelper.appendQueryStringParameters(url, { 'templateCode': templateId, 'filter': filter, 'page': page });

            return requests.getJson(url);
        },

        getListForTeam: function (teamId) {
            return requests.getJson(apiHelper.getBaseUrl() + "/teams/"+ teamId + "/employees");
        },

        isInCharge: function (employeeId) {
            return requests.getJson(apiHelper.getBaseUrl() + "/employee/" + employeeId + "/isincharge");
        },

        getHoursSummary: function (startDate, endDate) {
            return requests.getJson(
                apiHelper.appendQueryStringParameters(
                    apiHelper.getBaseUrl() + "/employees/current/hourssummary",
                    {
                        startDate: startDate,
                        endDate:endDate
                    })
            );
        },

        getAvailableEmployeeForTimesheet: function (serviceType, dispatchId, templateCode, filter, page) {
            var url = apiHelper.getServiceBaseUrl(serviceType) + "dispatch/" + dispatchId + "/timesheets/availableEmployees";
            url = apiHelper.appendQueryStringParameters(url, { 'templateCode': templateCode, 'filter': filter, 'page': page });
            return requests.getJson(url);
        },

        getCurrentEmployeeId: function() {
            return requests.getJson(apiHelper.getBaseUrl() + "/employee/current");
        }
    };

    return exports;
});
