import { inject } from "aurelia-framework";
import { I18N } from "aurelia-i18n";

import routerHelper from "helpers/routerHelper";
import dailyEntryService from "services/dailyEntryService";

@inject(I18N)
export class DailyEntryWorkEdit {
    i18n;
    entityId;
    dispatchProjectCode;
    dispatchDate;
    readonly = false;
    isProjectResponsible;

    descriptions;

    constructor(i18n) {
        this.i18n = i18n;
        this.entityId = null;
        this.dispatchProjectCode = "";
        this.dispatchDate = "";
        this.readonly = false;
        this.isProjectResponsible = false;

        this.descriptions = [];
    }

    mapDataToPost() {
        return { WorkDescription: document.querySelector(`textarea`).value };
    }

    activate(params) {
        var paramsQueryString = routerHelper.getQuerystring(params.q);
        this.isProjectResponsible = (paramsQueryString && paramsQueryString.isProjectResponsible === "true") || false;
        this.readonly = !(paramsQueryString && paramsQueryString.readonly === "false");
        this.dispatchDate = params.dailyEntryDate;
        this.dispatchProjectCode = params.dispatchProjectCode;
        this.entityId = params.dispatchProjectCode + "-" + params.dailyEntryDate;

        return this.loadData();
    }

    loadData() {
        dailyEntryService.getWorkDescription(this.dispatchProjectCode, this.dispatchDate)
            .done((data) => {
                if (data) {
                    this.descriptions.push({value: data.WorkDescription, identifier: 'richText'});
                }
            });
    }

    saveDescription() {
        if(this.readonly) {
            return;
        }
        routerHelper.showLoading();
        dailyEntryService.setWorkDescription(this.dispatchProjectCode, this.dispatchDate, this.mapDataToPost())
            .done((data) => {
                if (data) {
                    routerHelper.navigateBack();
                    routerHelper.hideLoading();
                }
            })
            .always(routerHelper.hideLoading);
    }
}
